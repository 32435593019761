import { Grid } from "semantic-ui-react";
import "react-lazy-load-image-component/src/effects/blur.css";

import styles from "./collections.module.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { CreatorCollections } from "app/core/common";
import NftDrops from "app/components/nft_drops_/NftDrops";
import { InView, useInView } from "react-intersection-observer";
export default function CollectionsView({ containerRef }) {
  const [collections, setCollections] = useState<CreatorCollections[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const handleInViewChange = (inView) => {
    if (inView) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    async function fetchCollectibles() {
      setLoading((prev) => !prev);
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/collections?limit=16&page=${page}`,
          headers: {
            "Content-Type": "application/json",
            //query: `featured: { _eq: false }, collection_type: { _eq: "collectible" }`,
            query: `featured: { _eq: false }`,
            sortQuery: "-date_created",
          },
        });
        const data = await response.data;
        setCollections((prev) => [...prev, ...data]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading((prev) => !prev);
      }
    }

    fetchCollectibles();
  }, [page]);

  return (
    <div className={styles.discover}>
      <div className={styles.container}>
        <Grid columns="one" divided>
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.column}>
              <h3>Collections</h3>
              <p className={styles.subHeading}>
                Explore collections and support creators, more collection types coming soon.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className={styles.artist_collectibles}>
          <div className={styles.leader_board_latest}>
            {collections?.length > 0 ? (
              collections?.map((collection, index) => (
                <NftDrops collection={collection} key={index} />
              ))
            ) : (
              !loading && collections?.length === 0 && (
              <div className="card">
                <h4>No Collections Found</h4>
              </div>
              )
            )}
            {loading && <div className="loader" />}
            {
              <InView as="div" onChange={handleInViewChange}>
                <div ref={ref} />
              </InView>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
