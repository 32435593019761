import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import transConfigs from "./translations/transalations";

const language = localStorage.getItem("i18nextLng");

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: { ...transConfigs },
    fallbackLng: language ? language : "en-US",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: ".",
    returnObjects: true,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
