import { DefaultAvatar, DirectusImage } from "app/core/common";
import styles from "./ModalProfile.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import { useUser } from "../../../../app/context/userInfo";
import PulseLoader from "react-spinners/PulseLoader";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import InputProfile from "../input_profile/InputProfile";
import ModalSelectProfilePicture from "../modal_select_profile_picture/ModalSelectProfilePicture";
import ModalCover from "./modal_cover/ModalCover";
import ModalLinkSelector from "./modal_link_selector/ModalLinkSelector";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import EditIcon from "@mui/icons-material/Edit";
import { blue } from "@mui/material/colors";
import * as api from "app/api/profile.api";
import AboutMeModal from "../about_me_modal/AboutMeModal";
import FavouriteSongs from "../../../pages/Profiles/AboutMe/FavouriteSongs";
import Button from "app/components/button/button";
import { useHistory } from "react-router-dom";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, checked }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: checked ? "#9c27b0" : "#007BFF", // Purple for Artist, Blue for Fan
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: checked ? "#9c27b0" : "#007BFF", // Purple for Artist, Blue for Fan
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function ModalProfile(props) {
  const {
    setProfileModal,
    profileData,
    infos,
    max,
    handleChange,
    updateAbout,
    details,
    pRef,
    setImageProfile,
    setImageCover,
    handleSocialChange,
    socials,
    songFile,
    setSongFile,
    setShowSong,
    showSong,
  } = props;

  const profileImage = profileData?.avatar?.id;
  const [loading, setLoading] = useState(false);
  const [pictureModal, setPictureModal] = useState(false);
  const [image, setImage] = useState<any>();
  const refSong = useRef(null);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [modalOpen, setOpenModal] = useState(false);
  const [musicGeners, setMusicGeners] = useState([]);
  const history = useHistory();
  const { userInfo } = useUser();
  const refInput = useRef(null);
  const modal_picture_ref = useRef(null);

  // const handleRoleChange = () => {
  //   setInfos((prevRole) => (prevRole === "Fan" ? "Artist" : "Fan"));
  // };

  useEffect(() => {
    try {
      const fetchData = async () => {
        await api.GetProfile({ id: profileData.id }).then((user) => {
          setMusicGeners(user?.genres);
        });
      };
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const openModal = () => {
    setOpenModal(true);
    //disable scrolling on the body when the modal is open
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setOpenModal(false);

    // Restore scrolling on the body when the modal is closed
    document.body.style.overflow = "auto";
  };

  const handleOptionChange = (event) => {
    setShowSong(event.target.value); // Update the selected option when a radio button is clicked
  };

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setImage(event.target.result);
    };
  };

  const handleMusicFile = (e) => {
    let file = e.target.files[0];
    if (file.type !== "audio/mp3" && file.type !== "audio/mpeg") {
      console.log(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 25) {
      console.log("The size of the file is to large");
      return;
    }

    setSongFile(file);
  };

  return (
    <div className={styles.blur}>
      <div className={styles.postBox}>
        <div className={styles.box_header}>
          <div className={styles.header_left}>
            <i
              className={styles.close_icon}
              onClick={() => {
                setProfileModal((prev) => !prev);
                setImageProfile("");
                setImageCover("");
                history.push(`/user/${userInfo.username ?? userInfo?.id}`);
              }}
            >
              <CloseIcon />
            </i>

            <span>Edit profile</span>
          </div>
          <div className={styles.header_rigth}>
            <button
              disabled={loading}
              onClick={() => {
                setLoading(true);
                updateAbout();
              }}
              className={
                !loading
                  ? styles.button_save_modal
                  : styles.button_save_modal_loading
              }
            >
              {loading ? (
                <PulseLoader color="white" size={10} />
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </div>
        <div className={styles.box_profile}>
          <div className={styles.edit_profile_top}>
            <ModalCover
              profileData={profileData}
              setImageCover={setImageCover}
            />

            <div className={styles.edit_profile_picture}>
              <img
                src={
                  profileImage
                    ? DirectusImage(profileImage)
                    : DirectusImage(DefaultAvatar)
                }
                ref={modal_picture_ref}
              />

              <div
                className={styles.camara}
                onClick={() => {
                  refInput.current.click();
                }}
              >
                <input
                  type="file"
                  ref={refInput}
                  hidden
                  onChange={handleImage}
                  accept="image/jpeg,image/png,image/webp,image/jfif,"
                />
                <AddAPhotoOutlinedIcon />
              </div>
            </div>
            <hr />
          </div>
          <div className={styles.usertype_selector}>
            <span>I'm a: </span>
            {/* <div className={styles.usertype_selector_option}>
              <p>Fan</p> <Switch {...label} defaultChecked /> <p>Artist</p>
            </div> */}
            <FormGroup row style={{ display: "flex", alignItems: "center" }}>
              <p style={{ marginRight: "8px" }}>Fan</p>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={
                      infos.role === "cd70c6cd-0266-4b9c-a42e-eaf0a482f417" ||
                      infos.role === "artist"
                    }
                    onChange={() =>
                      handleChange({
                        role:
                          ( infos.role ===
                            "21052289-c845-44bf-8be0-2bc9ea7cbc1f" )
                            ? "artist" // Artist
                            : "fan", // Fan
                      })
                    }
                  />
                }
                label=""
                style={{ margin: 0 }}
              />
              <p style={{ marginLeft: "8px" }}>Artist</p>
            </FormGroup>
          </div>
          <div className={styles.edit_profile_bottom}>
            <hr />
            {/* User Display Name */}
            <div className={styles.edit_profile_input}>
              <InputProfile
                name="display_name"
                infos={infos}
                placeholder="Display name"
                max={max}
                handleChange={handleChange}
                detail
              />
            </div>
            {/* User Bio */}
            <div className={styles.edit_profile_input}>
              <InputProfile
                name="description"
                infos={infos}
                placeholder="Short Bio"
                max={max}
                handleChange={handleChange}
                detail={false}
              />
            </div>
            <div className={styles.edit_profile_input}>
              <InputProfile
                name="location"
                infos={infos}
                placeholder="Location"
                max={max}
                handleChange={handleChange}
                detail
              />
            </div>
            <hr />
            {/* User About */}
            <div className={styles.edit_profile_input}>
              <InputProfile
                textarea={true}
                name="about"
                infos={infos}
                placeholder="About Me"
                max={800}
                handleChange={handleChange}
                detail={false}
              />
            </div>
            <hr />
            {/* User Username */}
            <div className={styles.edit_profile_input}>
              <InputProfile
                name="username"
                infos={infos}
                placeholder="Username"
                max={max}
                handleChange={handleChange}
                detail
                disabled={profileData?.username}
              />
            </div>
            <hr />

            <span className={styles.song_span}>Favourite Song and Genres</span>
            <div className={styles.edit_profile_input}>
              {/* <span>Show featured song on profile</span> */}
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className={styles.switch}>
                  Visible
                  <input
                    type="radio"
                    value="yes"
                    checked={showSong === "yes"} // Check if this option is selected
                    onChange={handleOptionChange}
                    style={{ height: "16px" }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Hidden
                  <input
                    type="radio"
                    value="no"
                    checked={showSong === "no"} // Check if this option is selected
                    onChange={handleOptionChange}
                    style={{ height: "16px" }}
                  />
                </div>
              </div>
            </div>

            <div
              className={styles.image_icon}
              onClick={() => {
                refSong.current.click();
              }}
            >
              <LibraryMusicIcon sx={{ color: blue[700] }} fontSize="medium" />
              <input
                type="file"
                ref={refSong}
                hidden
                onChange={handleMusicFile}
                accept="audio/mp3"
              />
              <span className={styles.input_song_title}>
                {songFile ? songFile.name : "No file selected"}
                <small> (Max: 25MB MP3)</small>
              </span>
            </div>

            <div
              style={{ width: "95%", padding: "2px 10px" }}
              /* onClick={openModal} */
            >
              <Button
                size="md"
                className="btn_none btn_full"
                onClick={openModal}
              >
                Edit Favourite Genres
              </Button>
            </div>
            <hr />

            <ModalLinkSelector
              socials={socials}
              handleSocialChange={handleSocialChange}
            />
          </div>
        </div>
      </div>
      {image ? (
        <ModalSelectProfilePicture
          setPictureModal={setPictureModal}
          setImage={setImage}
          image={image}
          setError={setError}
          setShow={setShow}
          pRef={pRef}
          modal_picture_ref={modal_picture_ref}
          setImageProfile={setImageProfile}
        />
      ) : null}

      {modalOpen ? (
        <AboutMeModal
          closeModal={closeModal}
          setMusicGeners={setMusicGeners}
          musicGeners={musicGeners}
        />
      ) : null}
    </div>
  );
}
