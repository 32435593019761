import React, { useEffect, useRef, useState } from "react";
import styles from "./Admin.module.scss";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { blue } from "@mui/material/colors";
import images from "./images.json";
import { toSvg } from "html-to-image";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import axios from "axios";
import OnBoardNft from "app/components/onboard/onboard_nft_image/OnBoardNft";
import * as api from "app/api/profile.api";
import { DirectusImage } from "app/core/common";

export default function Admin() {
  const [infos, setInfos] = useState({
    username: "",
    display_name: "",
    description: "",
  });
  const refCover = useRef(null);
  const elementRef = useRef(null); // Reference to the content div
  const [error, setError] = useState(null);
  const [nftBackGround, setNftBackGround] = useState(null);
  const [nftImage, setNftImage] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);
  const [userId, setUserId] = useState(null);
  const imageBottomRigth =
    "https://loop-markets.directus.app/assets/ce36c33e-2a96-4543-9a95-ff791dc70a21";
  const imageBottomLeft =
    "https://loop-markets.directus.app/assets/9fd7b350-b91a-48ff-b493-659351368603";
  const imageTop =
    "https://loop-markets.directus.app/assets/27e236b8-0989-42a1-90b8-774f33839f54";
  /**********************/
  // Get Profile/Owner data
  /**********************/
  useEffect(() => {
    setImageProfile("");
    setInfos((prevInfos) => ({
      ...prevInfos,
      display_name: "",
    }));

    const fetchData = async () => {
      await api.GetProfile({ id: userId }).then((user) => {
        if (user[0]?.avatar?.id) {
          setImageProfile(DirectusImage(user[0]?.avatar?.id));
        } else {
          setImageProfile("");
        }
        if (user[0]?.display_name) {
          setInfos((prevInfos) => ({
            ...prevInfos,
            display_name: user[0]?.display_name,
          }));
        }
      });
    };
    fetchData();
  }, [userId]);

  const handleNftBackGround = (nftImage) => {
    setNftBackGround(nftImage);
  };

  // when selected the image from the input, set the background of the preview with it
  const handleNFtImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp" &&
      file.type !== "image/gif"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setNftBackGround(event.target.result);
    };
  };

  // convert base 64 to svg
  const convertDataUrlToFile = async (dataUrl, fileName) => {
    try {
      const response = await fetch(dataUrl);
      const blob = await response.blob();

      // Create a file from the Blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      console.error(error);
      throw new Error("Failed to convert data URL to file.");
    }
  };
  // convert the preview to base 64
  const htmlToImageConvert = async () => {
    try {
      const dataUrl = await toSvg(elementRef.current, {
        cacheBust: false,
        style: styles,
      });
      const fileName = "nft.svg"; // Provide a suitable file name
      const svgFile = await convertDataUrlToFile(dataUrl, fileName);
      setNftImage(svgFile);
    } catch (error) {
      console.error(error);
    }
  };

  // when genre, division, and preview are ready set the nftImage
  useEffect(() => {
    if (nftBackGround) {
      htmlToImageConvert();
    } else {
      return;
    }
  }, [nftBackGround]);

  const handleDownLoadNft = () => {
    if (nftImage) {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(nftImage);
      downloadLink.download = "nft.svg"; // Provide a suitable file name
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <div className={styles.step_five_all} style={{width: "100%"}}>
      <div className={styles.step_five_category}>
        <label>Add username or userid:</label>
        <div className={styles.leaderboard_input}>
          <input
            type="text"
            placeholder="Search artist"
            onChange={(event) => setUserId(event.target.value)}
          />
        </div>
        <button
          className={styles.admin_button}
          onClick={() => handleDownLoadNft()}
        >
          Download NFT
        </button>
      </div>
      <div className={styles.step_five}>
        <div className={styles.step_five_left}>
          <div className={styles.step_five_left_content}>
            <div className={styles.step_five_image_sample}>
              <div
                className={styles.step_five_image_sample_square}
                onClick={() => handleNftBackGround(images[0]?.url)}
              >
                <img
                  src="https://loop-markets.directus.app/assets/315d3685-d60c-415f-841f-77b97fde6593"
                  alt=""
                  className={styles.step_five_image_sample_square_image}
                />
              </div>
              <div className={styles.step_five_image_sample_three}>
                {images.slice(1).map((image, index) => (
                  <div
                    className={styles.other_images}
                    key={image.id}
                    onClick={() => handleNftBackGround(image.url)}
                  >
                    <img src={image.url} alt="" />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.upload_option}>
              <span>Or upload your own</span>
              <div
                className={styles.image_icon}
                onClick={() => {
                  refCover.current.click();
                }}
              >
                <AddPhotoAlternateIcon sx={{ color: blue[700] }} />
                <input
                  type="file"
                  ref={refCover}
                  hidden
                  onChange={(e) => handleNFtImage(e)}
                  accept="image/jpeg,image/png,image/webp,image/jfif,image/gif"
                />
                <span>796 x 1058px</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.step_five_right}>
          <h1>Preview</h1>
          <OnBoardNft
            elementRef={elementRef}
            nftBackGround={nftBackGround}
            imageTop={imageTop}
            imageProfile={imageProfile}
            infos={infos}
            imageBottomLeft={imageBottomLeft}
            imageBottomRigth={imageBottomRigth}
            normal
          />
          <span>
            NFT image is definitive, once finished this process can't be changed
          </span>
        </div>
      </div>
    </div>
  );
}
