import Header from "header/Header";
import styles from "./CreateLoop.module.scss";
import React, { useEffect, useState } from "react";
import Starting from "./Starting/Starting";
import StepOne from "./step_one/StepOne";
import ProgressBar from "app/components/progress-bar/ProgressBar";
import StepTwo from "./step_two/StepTwo";
import StepThree from "./step_three/StepThree";
import StepFive from "./step_five/StepFive";
import StepSix from "./step_six/StepSix";
import StepSeven from "./step_seven/StepSeven";
import { checkUsername, useHook } from "app/hooks/common";
import axios from "axios";
import { DirectusImage, ProfileData, isCreator } from "app/core/common";
import * as api from "app/api/profile.api";
import { dataURLtoFile } from "app/helpers/dataURLtoFile";
import { update } from "ramda";
import { set } from "numeral";
import { useHistory } from "react-router-dom";
import Modal from "app/components/Modal";

type CreateLoopComponent = {
  setFlow: number;
  value: number;
};

export default function CreateLoop() {
  // error handling
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [supportUploadProgress, setSupportUploadProgress] = useState(0);
  const [flow, setFlow] = useState(0);
  const [noheader, setNoHeader] = useState(true);
  const [imageProfile, setImageProfile] = useState(null);
  const [imageProfileFinal, setImageProfileFinal] = useState(null);
  const [imageCoverFinal, setImageCoverFinal] = useState(null);
  const [imageCoverUpdate, setImageCoverUpdate] = useState(null);
  const [nftImage, setNftImage] = useState(null);
  const [nftImageFinal, setNftImageFinal] = useState(null);
  const [imageCover, setImageCover] = useState(null);
  const [nftBackGround, setNftBackGround] = useState(null);
  const [songFile, setSongFile] = useState(null);
  const [genre, setGenre] = useState(null);
  const [division, setDivision] = useState(null);
  const [max, setMax] = useState(110);
  const [ready, setReady] = useState(null);
  const { userInfo, cookie, updateUserInfo } = useHook();
  const [profileData, setProfileData] = useState<ProfileData>(null);
  const [notAvailable, setNotAvailable] = useState(200);
  const userId = userInfo?.id;
  const [voteNftCreated, setVoteNftCreated] = useState(false);
  const [loading, setLoading] = useState(null);
  const [supportNft, setSupportNft] = useState(null);
  const [step5Complete, setStep5Complete] = useState(null);
  const history = useHistory();

  const [infos, setInfos] = useState({
    username: "",
    display_name: "",
    description: "",
  });

  // Redirect to the Artist Signup if the user is not logged in
  useEffect(() => {
    if (!userInfo) {
      history.push("/artist-signup");
    }
  }, [userInfo]);

  // Get the profile data of the user
  useEffect(() => {
    const fetchData = async () => {
      await api.GetProfile({ id: userId }).then((user) => {
        setProfileData(user[0]);

        // Set the initial values for the profile and cover pictures
        if (user[0]?.avatar?.id) {
          setImageProfileFinal(DirectusImage(user[0]?.avatar?.id));
          setImageProfile(DirectusImage(user[0]?.avatar?.id));
        }
        if (user[0]?.display_name) {
          setInfos((prevInfos) => ({
            ...prevInfos,
            display_name: user[0]?.display_name,
          }));
        }
        if (user[0]?.username) {
          setInfos((prevInfos) => ({
            ...prevInfos,
            username: user[0]?.username,
          }));
        }
        if (user[0]?.description) {
          setInfos((prevInfos) => ({
            ...prevInfos,
            description: user[0]?.description,
          }));
        }

        if (user[0]?.background?.id) {
          setImageCoverFinal(DirectusImage(user[0]?.background?.id));
        }

        user[0]?.avatar?.id && user[0]?.background?.id && setReady(true);
      });
      return updateUserInfo();
    };
    fetchData();
  }, []);

  // handle the information of the user display name username and description
  const handleChange = async (selectedOption) => {
    const [name] = Object.keys(selectedOption); // Extracts the first (and in this case, the only) key
    const value = selectedOption[name]; // Retrieves the corresponding value

    // make sure username is
    // infos.username.toLowerCase().replace(/[^a-zA-Z0-9_]/g, '');
    if (name === "username") {
      setInfos({ ...infos, [name]: checkUsername(value) });
    } else {
      setInfos({ ...infos, [name]: value });
    }

    // Check if the entered username is available
    if (
      name === "username" &&
      value !== profileData?.username &&
      value.length > 5
    ) {
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/checkUsername`,
          data: {
            username: value,
          },
        });

        setNotAvailable(response.status);
      } catch (error) {
        //console.error(error);
        setNotAvailable(error.response.status);
      }
    }

    // Change the characters available for the bio
    if (name === "description") {
      setMax(110 - value.length);
    }
  };

  /* ======================== */
  /* Handle the flow of steps */
  /* ========= Start ======== */
  // Go to the next step
  const handleFlowNext = () => {
    setFlow((prevFlow) => (prevFlow += 1));
    // Update progress based on the new flow value
    switch (flow + 1) {
      case 2:
        setProgress(20);
        setReady(false);
        break;
      case 3:
        setProgress(40);
        setReady(false);
        break;
      case 4:
        setProgress(60);
        setReady(false);
        break;
      case 5:
        setProgress(80);
        setReady(true);
        break;
      default:
        setProgress(0);
    }
  };

  // Go to the previous step
  const handleFlowPrev = () => {
    setFlow((prevFlow) => (prevFlow -= 1));
    switch (flow - 1) {
      case 1:
        setProgress(0);
        setReady(true);
        break;
      case 2:
        setProgress(20);
        setReady(true);
        break;
      case 3:
        setProgress(40);
        setReady(true);
        break;
      case 4:
        setProgress(60);
        setReady(true);
        break;
      case 5:
        setProgress(80);
        setReady(true);
        break;
      // Add more cases if needed
      default:
        setProgress(0);
    }
  };
  /* ========= End ======== */
  /* Handle the flow of steps */
  /* ======================== */

  // update the profile picture and cover picture.
  const handleChangePictureCover = async () => {
    const formData = new FormData();
    formData.append("profile_avatar", imageProfileFinal as Blob);
    formData.append("profile_background", imageCoverUpdate as Blob);
    // Append additional fields to formData
    formData.append("user_id", userInfo?.id);
    formData.append("profile_id", userInfo?.profile_id);
    formData.append("cookie", cookie);
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}user/action/update_profile`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      updateUserInfo();
    } catch (error) {
      console.error(error);
      setErrorModalOpen(true);
      setErrorModalMessage(error.response);
    }
  };
  // update the display name and username
  const handleChangeDisplayUsername = async () => {
    setLoading((prev) => !prev);

    // lowercase only
    let isValidUsername = /^[a-zA-Z0-9_]*$/.test(infos.username.toLowerCase());
    if (!isValidUsername) {
      setErrorModalOpen(true);
      setErrorModalMessage(
        "Username can only contain letters, numbers, and underscores."
      );
      setLoading((prev) => !prev);
      return;
    }

    // Remove spaces and convert the username to lowercase
    infos.username = infos.username.toLowerCase().replace(/[^a-zA-Z0-9_]/g, "");
    //infos.username.toLowerCase().replace(/\s/g, "");

    const formData = new FormData();
    formData.append(
      "profile_displayName",
      infos?.display_name ? infos?.display_name : userInfo?.first_name
    );

    formData.append("profile_username", infos?.username ? infos?.username : "");
    formData.append("cookie", cookie);
    formData.append("user_id", userInfo?.id);
    formData.append("profile_id", userInfo?.profile_id);
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}user/action/update_profile`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      updateUserInfo();
      setLoading((prev) => !prev);

      handleFlowNext();
    } catch (error) {
      console.error(error);
    }
  };
  // update the description or short bio
  const handleChangeDescription = async () => {
    setLoading((prev) => !prev);
    const formData = new FormData();
    formData.append(
      "profile_description",
      infos?.description ? infos?.description : userInfo?.description
    );
    formData.append("cookie", cookie);
    formData.append("user_id", userInfo?.id);
    formData.append("profile_id", userInfo?.profile_id);
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}user/action/update_profile`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      setLoading((prev) => !prev);
      handleFlowNext();
    } catch (error) {
      console.error(error);
    }
  };

  /* ======================== */
  /* Handle the voting NFT */
  /* ========= Start ======== */
  const handleVoting = async () => {
    const formData = new FormData();
    formData.append("genreId", genre);
    formData.append("divisionId", division);
    formData.append("image", nftImage);
    formData.append("cookie", cookie);
    formData.append("song", songFile);

    try {
      setLoading((prev) => !prev);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}launchpad/createVoteCollection`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then((response) => {
        if (response.status === 200) {
          setVoteNftCreated(true);
          // Continue to next step
          setStep5Complete((prev) => !prev);
          setLoading((prev) => !prev);
          handleFlowNext();
        }
      });
    } catch (error) {
      //console.log(error.response);
      setLoading((prev) => !prev);
      setErrorModalOpen(true);
      setErrorModalMessage(error?.response?.data);
    }
  };
  /* ========= End ======= */
  /* Handle the voting NFT */
  /* ===================== */

  /* ====================== */
  /* Handle the support NFT */
  /* ======== Start ======= */
  const handleCreateSupportNft = async () => {
    const formData = new FormData();
    formData.append("cookie", cookie);
    formData.append("nft_image_1", supportNft?.[0]);
    formData.append("nft_image_2", supportNft?.[1]);
    formData.append("nft_image_3", supportNft?.[2]);

    try {
      setLoading((prev) => !prev);
      setSupportUploadProgress(0);

      // Send the support NFT images to the middleware
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}launchpad/createSupportCollection
        `,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      if (response) {
        // Set initial progress to 0
        setSupportUploadProgress(0);

        // Increment progress gradually using setTimeout
        const incrementProgress = () => {
          // Maximum number of steps for animation
          const maxSteps = 100;
          // Duration for animation in milliseconds
          const duration = 3000; // Adjust duration as needed
          // Interval for each step
          const interval = duration / maxSteps;

          for (let currentStep = 1; currentStep <= maxSteps; currentStep++) {
            setTimeout(() => {
              setSupportUploadProgress(currentStep);
              if (currentStep === maxSteps) {
                setLoading(false);
              }
            }, currentStep * interval);
          }
        };

        incrementProgress();
        setTimeout(() => {
          handleFlowNext();
        }, 3500);

        setSupportUploadProgress(0);
      } else {
        // If profileData is not true, set progress to 100 immediately
        setSupportUploadProgress(0);
        setLoading(false);
      }
    } catch (error) {
      //console.error(error);
      setSupportUploadProgress(0);
      setLoading((prev) => !prev);
      setErrorModalOpen(true);
      setErrorModalMessage(error?.response?.data);
    }
  };
  /* ========= End ======== */
  /* Handle the support NFT */
  /* ======================== */

  // no need to call handleChangePictureCover, handleChangeDisplayUsername in the useEffect

  let currentStep: any;
  switch (flow) {
    // Let's get started
    case 0:
      currentStep = <Starting setFlow={setFlow} cookie={cookie} />;
      break;
    // Step 1: Upload profile and cover pictures
    case 1:
      currentStep = (
        <StepOne
          imageProfile={imageProfile}
          setImageProfile={setImageProfile}
          setImageCover={setImageCover}
          imageCover={imageCover}
          setImageCoverFinal={setImageCoverFinal}
          imageCoverFinal={imageCoverFinal}
          setReady={setReady}
          handleChangePictureCover={handleChangePictureCover}
          setImageProfileFinal={setImageProfileFinal}
          setImageCoverUpdate={setImageCoverUpdate}
          setErrorModalOpen={setErrorModalOpen}
          setErrorModalMessage={setErrorModalMessage}
        />
      );
      break;
    // Step 2: Set display name and username
    case 2:
      currentStep = (
        <StepTwo
          infos={infos}
          handleChange={handleChange}
          imageProfile={imageProfile}
          imageCoverFinal={imageCoverFinal}
          setReady={setReady}
          handleChangeDisplayUsername={handleChangeDisplayUsername}
          notAvailable={notAvailable}
        />
      );
      break;
    // Step 3: Add a short bio
    case 3:
      currentStep = (
        <StepThree
          infos={infos}
          profileData={profileData}
          handleChange={handleChange}
          imageProfile={imageProfile}
          imageCoverFinal={imageCoverFinal}
          max={max}
          setReady={setReady}
          handleChangeDescription={handleChangeDescription}
        />
      );
      break;
    // Step 4: Create vote NFT
    case 4:
      currentStep = (
        <StepFive
          infos={infos}
          imageProfile={imageProfile}
          nftBackGround={nftBackGround}
          setNftBackGround={setNftBackGround}
          setNftImage={setNftImage}
          setGenre={setGenre}
          setDivision={setDivision}
          genre={genre}
          division={division}
          setReady={setReady}
          handleVoting={handleVoting}
          setSongFile={setSongFile}
          songFile={songFile}
        />
      );
      break;
    // Step 5: Create support NFT
    case 5:
      currentStep = (
        <StepSix
          infos={infos}
          imageProfile={imageProfile}
          loading={loading}
          supportUploadProgress={supportUploadProgress}
          setSupportNft={setSupportNft}
        />
      );
      break;
    // Step 6: All Done
    case 6:
      currentStep = <StepSeven voteNftCreated={voteNftCreated} />;
      break;

    default:
      currentStep = null;
  }

  return (
    <div className={styles.create_loop}>
      <Modal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(!errorModalOpen)}
        customSubClass={styles.onboardModal}
        title="Oops! Something went wrong"
      >
        <div className={styles.onboardModalContent}>
          <span>{errorModalMessage}</span>
        </div>
      </Modal>

      <Header noheader={noheader} />
      <div className={styles.content}>
        {flow < 6 && flow > 0 ? (
          <ProgressBar
            ready={ready}
            flow={flow}
            progress={progress}
            handleFlowNext={handleFlowNext}
            handleFlowPrev={handleFlowPrev}
            handleVoting={handleVoting}
            handleCreateSupportNft={handleCreateSupportNft}
            loading={loading}
            step5Complete={step5Complete}
            handleChangeDisplayUsername={handleChangeDisplayUsername}
            handleChangeDescription={handleChangeDescription}
            setErrorModalOpen={setErrorModalOpen}
            setErrorModalMessage={setErrorModalMessage}
          />
        ) : null}
      </div>
      <div className={styles.flow}>{currentStep}</div>
    </div>
  );
}
