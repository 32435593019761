import Input from "app/components/input/input";
import styles from "./Sidebar.module.scss";
import { useState } from "react";

export default function SearchDrawer({ show, setShow }: any) {
  const [value, setValue] = useState();
  const leftOpen = "100px";
  const leftClose = "50px";
  const transition = "ease-in-out 250ms";
  return (
    <div>
      <div
        onClick={() => setShow(false)}
        className={styles.overlay}
        style={
          show
            ? {
                left: leftOpen,
                visibility: "visible",
                display: "block",
                opacity: 1,
                transition: transition,
              }
            : {
                left: leftClose,
                visibility: "hidden",
                display: "none",
                opacity: 0,
                transition: transition,
              }
        }
      />
      <div
        className={styles.contentDrawer}
        style={
          show
            ? {
                left: leftOpen,
                visibility: "visible",
                display: "block",
                opacity: 1,
                transition: transition,
              }
            : {
                left: leftClose,
                visibility: "hidden",
                display: "none",
                opacity: 0,
                transition: transition,
              }
        }
      >
        <h3>Search</h3>
        <Input placeholder="Search ..." value={value} setValue={setValue} />
        <div className={styles.result}></div>
        <p>. . .</p>
      </div>
    </div>
  );
}
