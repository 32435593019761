import TimeAgo from "app/helpers/timeago";
import styles from "./comments.module.scss";
import { DefaultAvatar, DirectusImage } from "../../core/common";
import { Link, useHistory } from "react-router-dom";
import { textTruncate, truncate } from "app/helpers/helper";
import ReactPlayer from "react-player";
import TextArea from "../createContent/TextArea";

export default function Comments({
  post,
  comments,
  commentLoading,
}: {
  post: any;
  comments: any;
  commentLoading: Boolean;
}) {
  const history = useHistory();
  const handleClick = ({ user }) => {
    const bodyContent = document.getElementById("bodyContent");

    if (bodyContent) {
      // restore scrolling when modal is closed
      document.body.classList.remove("no-scroll");
      document.body.style.overflow = "auto";
    }
    // push to /user/id
    return `/user/${user}`;
  };

  return (
    <>
      {/* loop comments */}
      <div className={styles.comments}>
        {/* Post Detail */}
        <div className={styles.post}>
          <div className={styles.post_artist}>
            <Link to={`/user/${post.artist.username ?? post.artist.id}`}>
              <img src={DirectusImage(post.artist.avatar)} />
            </Link>
          </div>
          <div className={styles.post_detail}>
            <div>
              <p>
                <b>{post.artist.display_name ?? post.artist.first_name}</b> -{" "}
                <span className={styles.post_date}>
                  {TimeAgo(post.date_created)}
                </span>
              </p>
              {/* <p
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: truncate(post.content, 550),
                }}
              /> */}
              <div className={styles.content}>
                {/* <p>{truncate(post.content, post.content.length)}</p> */}
                {/* <TextArea postContent={post.content} disabled={true} /> */}
                {truncate(post.content, post.content.length)}
                {post.image.id ? (
                  <div className={styles.content_media}>
                    {post.type == "video" ? (
                      <ReactPlayer
                        url={DirectusImage(post.image.id)}
                        controls={true}
                        playsinline
                        //playing
                        muted // Add the muted attribute
                        autoplay
                        //loop
                        pip={false}
                        width="100%"
                        height="560px"
                        style={{maxHeight: "650px"}}
                      />
                    ) : (
                      <>
                        <img
                          src={DirectusImage(post.image.id)}
                          alt={`${post.id}`}
                        />
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {commentLoading && (
          <div className={styles.loader}>
            <div className="loader" />
          </div>
        )}

        <p>Comments</p>
        {comments?.map((comment, index) => {
          const profileImage = comment?.user_id?.avatar?.id;
          return (
            <div key={index}>
              <div className={styles.comment}>
                <div className={styles.comment_user}>
                  <Link
                    to={handleClick({user: comment?.user_id?.username ?? comment?.user_id?.id})}
                  >
                    <img
                      src={
                        profileImage
                          ? DirectusImage(comment?.user_id?.avatar?.id)
                          : DirectusImage(DefaultAvatar)
                      }
                    />
                  </Link>
                </div>
                <div className={styles.comment_detail}>
                  <div>
                    <p>
                      <b>{comment?.user_id.display_name ?? comment?.user_id.first_name}</b> -{" "}
                      <span className={styles.date}>
                        {TimeAgo(comment?.date_created)}
                      </span>
                    </p>
                    <p className={styles.content}>{comment?.Text}</p>
                    <div className={styles.actions}>
                      {/* <div className={styles.action}> Like </div> */}
                      {/* Add Replies at a future date */}
                      {/* <div className={styles.action}> Reply </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
