import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useHook } from "app/hooks/common";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { truncate } from "app/helpers/helper";

export default function Collections({
  collection,
  index,
}: {
  collection?: any;
  index?: number;
}) {
  const { cookie } = useHook();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      to={collection?.link}
      className={styles.creators_container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* {index === 2 && <div className={styles.banner}>Coming Soon</div>} */}

      <LazyLoadImage
        src={collection?.image}
        alt="collections-loop-fans"
        effect="blur"
        wrapperClassName={styles.image}
      />
      <div className={styles.overlay} />

      <div className={styles.club_details}>
        <div className={styles.details}>
            <p>{collection?.name}</p>
            <div className={styles.content}>{truncate(collection?.description, 90, "...")}</div>
        </div>
      </div>
    </Link>
  );
}
