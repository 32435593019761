import styles from "./ProgressBar.module.scss";
import PulseLoader from "react-spinners/PulseLoader";

type ProgressBarProps = {
  progress: number;
  handleFlowNext: () => void;
  handleFlowPrev: () => void;
  handleChangeDisplayUsername: () => void;
  handleChangeDescription: () => void;
  flow: number;
  ready: boolean;
  handleVoting?: () => void;
  handleCreateSupportNft?: () => void;
  loading: boolean;
  step5Complete: boolean;
  setErrorModalOpen?: any;
  setErrorModalMessage?: any;
};

export default function ProgressBar({
  progress,
  handleFlowNext,
  handleFlowPrev,
  flow,
  ready,
  handleVoting,
  handleCreateSupportNft,
  loading,
  step5Complete,
  handleChangeDisplayUsername,
  handleChangeDescription,
  setErrorModalOpen,
  setErrorModalMessage,
}: ProgressBarProps) {
  return (
    <>
      <button
        className={
          step5Complete
            ? styles.progress_button_disabled
            : styles.progress_button
        }
        onClick={handleFlowPrev}
        disabled={step5Complete || loading}
      >
        <span>Prev</span>
      </button>
      <progress
        className={styles.progress_bar}
        value={progress}
        max="100"
      ></progress>
      <button
        className={
          !ready
            ? styles.progress_button_disabled
            : loading
            ? styles.progress_button_loading
            : styles.progress_button
        }
        onClick={
          flow === 2
            ? handleChangeDisplayUsername
            : flow === 3
            ? handleChangeDescription
            : flow === 4
            ? handleVoting
            : flow === 5
            ? handleCreateSupportNft
            : handleFlowNext
        }
        disabled={!ready || loading}
      >
        {flow === 5 || flow === 4 || flow === 2 || flow === 3 ? (
          <>
            {loading ? (
              <PulseLoader color="#887dea" size={10} />
            ) : (
              <span>Next</span>
            )}
          </>
        ) : (
          <span>Next</span>
        )}
      </button>
    </>
  );
}
