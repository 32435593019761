import React, { Children, ErrorInfo } from "react";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: _.message };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const { hasError, errorMessage } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="errorCard">
          <div className="errorMessage">
            <div>
              <h1>Something went wrong.</h1>
              <span>the error has been reported</span>
              <div className="errorMessage_div" />
              {errorMessage}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
