import FeedHeader from "../../components/feedHeader";
import styles from "./feed.module.scss";
import { useFeed } from "app/context/feedContext";
import Feed from "app/components/feed/feed";
import { CreateContent } from "app/components/createContent/createContent";
import Tabs from "app/components/Tabs/Tabs";
import { getParam, setParam } from "app/helpers/helper";
import React, { useEffect } from "react";

export default function FeedHome({ containerRef }) {
  const {
    feed,
    feedLoading,
    lastUpdated,
    moreExists,
    fetchMoreFeed,
    fetchLatestFeed,
    setFeed,
    tabChange
  } = useFeed();

  const currentTab = localStorage.getItem("feedType");
  const [activeTab, setActiveTab] = React.useState<string>(currentTab);

  const tabs = [
    {
      name: "For You",
      slug: "foryou",
    },
    {
      name: "Following",
      slug: "following",
    },
  ];

  useEffect(() => {
    tabChange(currentTab);
    localStorage.setItem("feedType", currentTab);
  }, [currentTab]);

  useEffect(() => {
    if ( !currentTab ) {
      localStorage.setItem("feedType", "foryou");
    }
  }, [currentTab]);

  return (
    <div className={styles.mainFeed}>
      <Tabs name="feedType" tabs={tabs} setTab={setActiveTab} localStorage={true} />
      <CreateContent feedRef={feed} updateFeedRef={setFeed} />
      {/* <FeedHeader
        feedLoading={feedLoading}
        fetchLatestFeed={fetchLatestFeed}
        lastUpdated={lastUpdated}
      /> */}
      <Feed
        feed={feed}
        feedLoading={feedLoading}
        lastUpdated={lastUpdated}
        moreExists={moreExists}
        fetchMoreFeed={fetchMoreFeed}
        setFeed={setFeed}
        containerRef={containerRef}
        isProfile={false}
      />
    </div>
  );
}
