import styles from "./Sidebar.module.scss";

export default function NotiDrawer({ show, setShow }: any) {
  const leftOpen = "0";
  const leftClose = "-10px";
  const transition = "ease-in-out 250ms";
  return (
    <div>
      <div
        onClick={() => setShow(false)}
        className={styles.overlay}
        style={
          show
            ? {
                visibility: "visible",
                display: "block",
                opacity: 1,
                zIndex: 1,
                transition: transition,
              }
            : {
                visibility: "hidden",
                display: "none",
                opacity: 0,
                transition: transition,
              }
        }
      />
      <div
        className={styles.contentDrawer}
        style={
          show
            ? {
                left: leftOpen,
                visibility: "visible",
                display: "block",
                opacity: 1,
                zIndex: 1,
                transition: transition,
              }
            : {
                left: leftClose,
                display: "none",
                visibility: "hidden",
                opacity: 0,
                transition: transition,
              }
        }
      >
        <div className={styles.header}>
          <h3>Notifications</h3>
          <button className={styles.close} onClick={() => setShow(false)}><img src="/close.svg" /></button>
        </div>
        <hr />
        <p>No Notifications</p>
      </div>
    </div>
  );
}
