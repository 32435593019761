import axios from "axios";
const limit = 8;

export async function getMyNFTs({ address, page }) {
  try {
    const result = await axios({
      url: `${process.env.REACT_APP_MIDDLEWARE}indexer/nfts/owner/${address}/${page}/${limit}`,
      method: "get",
      data: {},
    });

    return result.data;
  } catch (error) {
    console.error("Error", error.message);
    // Handle the error here
  }
}