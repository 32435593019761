import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import styles from "./AboutMeModal.module.scss";
import { Trans } from "react-i18next";
import axios from "axios";
import { useHook } from "app/hooks/common";
import FavouriteSongs from '../../../pages/Profiles/AboutMe/FavouriteSongs';

//prop types for the modal component
type ModalProps = {
  closeModal: any;
  setMusicGeners: any;
  musicGeners: Array<object>;
};

// Define the structure of a genre
type Genre = {
  id: string;
  name: string;
};

export default function AboutMeModal({
  closeModal,
  setMusicGeners,
  musicGeners,
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [loadingGeners, setLoadingGeners] = useState(false);
  const [genres, setGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const { userInfo, cookie } = useHook();

  // fetch the music genres
  useEffect(() => {
    try {
      setLoadingGeners((prev) => !prev);
      const fetchMusicGenres = async () => {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/genres`,
        });
        // Filter out genres that are already in musicGeners (selected)
        const filteredGenres = response?.data?.genres.filter(
          (genre: Genre) =>
            !musicGeners.find((musicGenre: Genre) => musicGenre.id === genre.id)
        );
        filteredGenres?.shift();
        setGenres(filteredGenres);
        setLoadingGeners(false);
      };
      fetchMusicGenres();
    } catch (error) {
      console.error(error);
    }
  }, []);

  // useEffect to initialize selectedGenres (if there are geners allready selected)
  useEffect(() => {
    if (musicGeners.length > 0) {
      setSelectedGenres(musicGeners);
    }
  }, []);

  // Function to handle adding genres to the selectedGenres state
  const handleAddGenres = (genr: Genre) => {
    if (selectedGenres.length === 0) {
      // If no genres are selected, set the selected genre
      setSelectedGenres([genr]);
    } else if (selectedGenres.length === 6) {
      // Limit the number of selected genres to 6
      return;
    } else {
      // Add the selected genre to the existing selected genres
      setSelectedGenres([...selectedGenres, genr]);
    }
    // Remove the selected genre from the available genres
    setGenres((prevGenres) => prevGenres.filter((item) => item !== genr));
  };

  // Function to handle removing genres from the selectedGenres state
  const handleRemoveGenres = (genr: Genre) => {
    // Filter out the removed genre from the selectedGenres
    const updatedSelectedGenres = selectedGenres.filter(
      (item) => item !== genr
    );
    setSelectedGenres(updatedSelectedGenres);

    // Add the removed genre back to the available genres
    setGenres([genr].concat(genres));
  };
  // Function to handle saving the selected genres
  const handleSaveGenres = async () => {
    setLoading((prev) => !prev);
    // Update the parent state with the selected genres
    setMusicGeners(selectedGenres);

    const genersId = [];

    selectedGenres.forEach((music) => {
      genersId.push(music.id);
    });
    try {
      const updateMusicGeners = await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}user/action/update_genres`,
        data: {
          cookie: cookie,
          profile_id: userInfo?.profile_id,
          genresIds: genersId,
        },
      });

      setLoading(false);
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.blur}>
      <div className={styles.postBox}>
        <div className={styles.box_header}>
          <div className={styles.header_left}>
            <i className={styles.close_icon} onClick={closeModal}>
              <CloseIcon />
            </i>

            Edit Favourite Genres
          </div>
          <div className={styles.header_rigth}>
            <button
              disabled={loading}
              className={
                !loading
                  ? styles.button_save_modal
                  : styles.button_save_modal_loading
              }
              onClick={handleSaveGenres}
            >
              {loading ? (
                <PulseLoader color="white" size={10} />
              ) : (
                <span>
                  Update Genres
                </span>
              )}
            </button>
          </div>
        </div>
        <div className={styles.genres_container}>
          <div className={styles.genres_title}>
            {selectedGenres.length > 0 ? (
              selectedGenres.map((genre, index) => (
                <div key={index} className={styles.selectedGenre}>
                  <CloseIcon
                    fontSize="small"
                    onClick={() => handleRemoveGenres(genre)}
                  />
                  {genre.name}
                </div>
              ))
            ) : (
              <span>
                <Trans>placeholderGenres</Trans>
              </span>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {loadingGeners ? (
              <div className={styles.feed_item_loading}>
                <div className="loader" />
              </div>
            ) : (
              <div className={styles.all_genres}>
                {genres.map((genre, index) => (
                  <div
                    key={index}
                    className={
                      selectedGenres.length === 6
                        ? styles.genre_grey
                        : styles.genre
                    }
                    onClick={() => handleAddGenres(genre)}
                  >
                    <span>{genre.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
