import React from "react";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";

export default function Banner() {
  const history = useHistory();
  return (
    <div className={styles.banner} onClick={() => history.push("/")}>
      <p>
        <span>MUSIC RELEASES</span>
        <img
          src="https://loop-markets.directus.app/assets/636cd83d-2260-4380-949e-aec6e6041eff"
          alt=""
        />
        <span>DROPPING NOW</span>
      </p>
    </div>
  );
}
