import classNames from "classnames";
import styles from "./input.module.scss";
import send_icon from "../../../assets/icons/send.svg";
import send_icon_active from "../../../assets/icons/send_active.svg";

interface InputProps {
  classes?: any;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  setValue?: any;
  onSubmit?: any;
  showIcon?: boolean;
  checkRegisteredUser?: () => void; // Optional function to handle Enter key press
}

export default function Input(props: InputProps) {
  const {
    classes,
    disabled,
    placeholder,
    value,
    setValue,
    onSubmit,
    showIcon,
    checkRegisteredUser, // New prop
  } = props;

  const sanitizedText = (text: string) => text?.replace(/(\r\n|\n|\r)/gm, "");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (checkRegisteredUser) {
        checkRegisteredUser();
      } else if (onSubmit) {
        onSubmit();
        setValue(""); // Clear the input field after submission
      }
    }
  };

  return (
    <div className="textarea_container">
      {showIcon && (
        <div
          className="send_icon"
          onClick={() => {
            if (onSubmit) {
              onSubmit();
              setValue("");
            }
          }}
        >
          <img src={value ? send_icon_active : send_icon} alt="Send" />
        </div>
      )}
      <textarea
        disabled={disabled}
        style={{ margin: "auto" }}
        className={classNames(classes, styles.input)}
        placeholder={placeholder}
        value={sanitizedText(value)}
        onChange={(event) => setValue(sanitizedText(event.target.value))}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
