/**
 * Renders the feed component.
 *
 * @param containerRef - Reference to the container element.
 * @param feed - Array of feed objects.
 * @param feedLoading - Indicates if the feed is currently loading.
 * @param lastUpdated - Last updated timestamp.
 * @param moreExists - Indicates if there are more feed items available.
 * @param fetchMoreFeed - Function to fetch more feed items.
 * @param setFeed - Function to set the feed items.
 * @param isProfile - Indicates if the feed is for a user profile.
 */

import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import { handleLike, isLiked } from "app/components/HandleLike";
import { FeedObject } from "app/core/common";
import styles from "./feed.module.scss";
import { useHook } from "app/hooks/common";
import DetailView from "./DetailView";
import { Trans } from "react-i18next";
import Button from "../button/button";
import PostItem from "./postItem";
import Modal from "../Modal";
import axios from "axios";

export default function Feed({
  containerRef,
  feed,
  feedLoading,
  lastUpdated,
  moreExists,
  fetchMoreFeed,
  setFeed,
  isProfile,
  isUserProfile,
  wall,
}: {
  containerRef: any;
  feed: FeedObject[];
  feedLoading: boolean;
  lastUpdated: any;
  moreExists: boolean;
  fetchMoreFeed: () => void;
  setFeed: (feed: FeedObject[]) => void;
  isProfile?: boolean;
  isUserProfile?: boolean;
  wall?: boolean;
}) {
  const history = useHistory();
  const location = useLocation();
  const { cookie, userInfo } = useHook();
  const [itemIndex, setItemIndex] = useState(0);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [editedPostId, setEditedPostId] = useState(null);
  const [playSong, setPlaySong] = useState(null);

  const handleConfirmDelete = (postId) => {
    setConfirmDelete((prev) => !prev);
    setCurrentItemId(postId);
  };

  /* ================== */
  // Delete post
  /* ================== */
  const handleDelete = async (postId) => {
    try {
      // remove the post from the feed
      const updatedFeed = feed.filter((item) => item.id !== postId);
      setFeed(updatedFeed);
      setConfirmDelete(false);

      // delete the post
      await axios({
        method: "delete",
        url: `${process.env.REACT_APP_MIDDLEWARE}arena/post/${postId}`,
        headers: {
          "Content-Type": "application/json",
          user_cookie: cookie,
        },
        withCredentials: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  /* ================== */
  // Load more feed
  /* ================== */
  const loadMore = () => {
    //console.log("loadMore")
    fetchMoreFeed();
  };

  /* ================== */
  // Restore the scroll position
  /* ================== */
  // Restore the scroll position after exiting fullscreen
  const onFullScreenChange = (event) => {
    const scrollPosition = localStorage.getItem("feedPosition");
    window.scrollTo(0, parseInt(scrollPosition, 10));
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("mozfullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener("mozfullscreenchange", onFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullScreenChange
      );
    };
  }, []);

  // Restore the scroll position after returning to the feed page
  useEffect(() => {
    if (location.pathname === "/" && !isProfile) {
      const scrollPosition = localStorage.getItem("feedPosition");

      if (containerRef.current) {
        document.documentElement.scrollTo(0, parseInt(scrollPosition, 10));
      }
    }
  }, [location]);

  // Save the scroll position when scrolling
  const onScroll = () => {
    const { scrollTop, scrollHeight } = document.documentElement;

    // Save the scroll position
    if (!isProfile && !document.fullscreenElement) {
      localStorage.setItem("feedPosition", scrollTop.toString());
    }

    if (
      moreExists &&
      !feedLoading &&
      window.innerHeight + scrollTop >= scrollHeight - 500
    ) {
      return loadMore();
    }
    return false;
  };

  /**********************/
  // Intersection Observer
  /**********************/
  const userPausedRef = useRef(false);
  useEffect(() => {
    const container = containerRef.current;
    window.addEventListener("scroll", onScroll);

    // Intersection Observer to pause videos when out of view and play when in view
    const videos = container.querySelectorAll("video");
    let currentPlayingVideo = null;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry: any) => {
          const video = entry.target;
          if (entry.isIntersecting && !showDetailModal) {
            if (video !== currentPlayingVideo && !userPausedRef.current) {
              if (currentPlayingVideo) {
                currentPlayingVideo.pause();
              }
              video.play();
              currentPlayingVideo = video;
            }
          } else if (video === currentPlayingVideo || showDetailModal) {
            if (!userPausedRef.current) {
              video.pause();
              currentPlayingVideo = null;
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    // Add observer to all video elements
    videos.forEach((video) => {
      observer.observe(video);

      video.addEventListener("play", () => {
        userPausedRef.current = false;
      });
      video.addEventListener("pause", () => {
        userPausedRef.current = true;
      });
      video.addEventListener("ended", () => {
        // Video has ended, reset currentTime to restart
        video.currentTime = 0;
        video.play();
      });
    });

    return () => {
      window.removeEventListener("scroll", onScroll);
      observer.disconnect();
    };
  });

  /**********************/
  // Single-tap like action
  /**********************/
  const handleSingleTap = (itemId) => {
    if (userInfo) {
      if (!isLiked(itemId, feed)?.liked) {
        //handleHeartOverlay(itemId);
      }
      handleLike({ itemId, setItems: setFeed, cookie, userInfo });
    }
  };

  /**********************/
  // Double-tap like action
  /**********************/
  /*   const handleDoubleTap = (itemId) => {
    console.log("currentTime: " + currentTime);
    console.log("lastTapTime: " + lastTapTime);
    if (currentTime - lastTapTime < doubleClickThreshold) {
      if (!isLiked(itemId, feed)?.liked) {
        handleHeartOverlay(itemId);
      }
      handleLike({ itemId, setItems: setFeed, cookie, userInfo });
    }
    setLastTapTime(currentTime);
    setTimeout(() => {
      setLastTapTime(0);
    }, doubleClickThreshold); // Adjust the duration as needed
  }; */

  /**********************/
  // Handle heart overlay
  /**********************/
  const handleHeartOverlay = (itemId) => {
    const imageContainer = document.querySelector(
      `.imageContainer-${itemId}`
    ) as HTMLElement;
    const heartIcon = imageContainer.querySelector(
      ".heart-icon"
    ) as HTMLElement;

    heartIcon.style.opacity = "1"; // Show the heart icon
    heartIcon.style.display = "block"; // Show the heart icon
    setTimeout(() => {
      heartIcon.style.opacity = "0"; // Hide the heart icon
      heartIcon.style.display = "none"; // Show the heart icon
    }, 800); // Adjust the duration as needed
  };

  useEffect(() => {
    const bodyContent = document.getElementById("bodyContent");

    if (bodyContent) {
      if (showDetailModal) {
        // prevent scrolling when modal is open
        document.body.classList.add("no-scroll");
      } else {
        // restore scrolling when modal is closed
        document.body.classList.remove("no-scroll");
        document.body.style.overflow = "auto";
      }

      return () => {
        // cleanup
        document.body.style.overflow = "auto";
      };
    }
  }, [showDetailModal]);

  return (
    <>
      {showDetailModal && (
        <DetailView
          show={showDetailModal}
          toggleModal={setShowDetailModal}
          index={itemIndex}
          setIndex={setItemIndex}
          items={feed}
          setItems={setFeed}
          currentTime={currentTime}
          feedLoading={feedLoading}
          moreExists={moreExists}
          fetchMoreFeed={fetchMoreFeed}
        />
      )}
      <div
        className={`feed ${
          isProfile ? `${styles.feed} ${styles.profile_feed}` : `${styles.feed}`
        }`}
        id="feed"
      >
        {feed.length == 0 && !feedLoading ? (
          <div className="card">
            <h4 style={{ textAlign: "center" }}>
              {isUserProfile ? (
                <Trans>noFeedProfileMessage</Trans>
              ) : isProfile ? (
                wall ? (
                  "Nobody has posted here yet. Be the first to post in this artist's community!"
                ) : (
                  <Trans>noFeedUserMessage</Trans>
                )
              ) : (
                <Trans>noFeedMessage</Trans>
              )}
            </h4>
            {!isProfile && (
              <Link to="/loops/">
                <Button className="btn_sm btn_solid btn_centered">
                  Artist Loops
                </Button>
              </Link>
            )}
          </div>
        ) : feed.length == 0 && feedLoading ? (
          <div className={styles.feed_item_loading}>
            <div className="loader" />
          </div>
        ) : (
          <>
            {feed?.map((item, index) => (
              <PostItem
                feed={feed}
                setFeed={setFeed}
                index={index}
                setItemIndex={setItemIndex}
                cookie={cookie}
                handleSingleTap={handleSingleTap}
                item={item}
                key={index}
                setShowDetailModal={setShowDetailModal}
                userInfo={userInfo}
                setConfirmDelete={setConfirmDelete}
                handleConfirmDelete={handleConfirmDelete}
                editedPostId={editedPostId}
                setEditedPostId={setEditedPostId}
                playSong={playSong}
                setPlaySong={setPlaySong}
              />
            ))}
            {moreExists && feedLoading && <div className="loader" />}
          </>
        )}
      </div>
      <Modal
        onClose={() => setConfirmDelete(false)}
        isOpen={confirmDelete}
        title={""}
        customSubClass="defaultModal"
      >
        <div className="defaultModalContent">
          <p>
            Are you sure you want to delete this post? <br />{" "}
            <b>(This action cannot be undone)</b>
          </p>
          <div className="defaultModalButtons">
            <Button
              size="sm"
              className="btn_none"
              onClick={() => setConfirmDelete(false)}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              className="btn_danger"
              onClick={() => handleDelete(currentItemId)}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
