import { useMediaPlayer } from "app/context/mediaPlayerContext";
import styles from "./styles.module.scss";
import { DirectusImage } from "app/core/common";
import { truncate } from "app/helpers/helper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TracksMenu from "./tracksMenu";

export default function MusicList({
  track,
  handlePlaySong,
  handleSetAlbumId,
  isMenuOpen,
  onMenuToggle,
  setMenuOpenForTrackId,
}: {
  track: any;
  handlePlaySong: () => void;
  handleSetAlbumId?: any;
  isMenuOpen?: any;
  onMenuToggle?: any;
  setMenuOpenForTrackId?: any;
}) {
  const { play, actualTrack } = useMediaPlayer();

  const isCurrentTrackPlaying =
    actualTrack?.track?.id === track.track.id && play;

  const handleCloseMenu = () => {
    // Close the menu for the current track
    onMenuToggle();
  };

  return (
    <div className={styles.music_list}>
      <div
        className={styles.music_list__item}
        onClick={() => {
          handlePlaySong();
          handleSetAlbumId(track?.track?.album_id);
        }}
      >
        <img src={DirectusImage(track?.track?.artwork)} alt="" />
        <div className={styles.music_list__info}>
          <h4 className={isCurrentTrackPlaying ? styles.active : ""}>
            {truncate(track?.track?.title ?? track?.track?.title, 30, "...")}
          </h4>
          <p>
            {truncate(
              track?.track?.artist?.username ?? track?.track?.artist?.username,
              30,
              "..."
            )}
          </p>
        </div>
      </div>
      <MoreHorizIcon className={styles.playIcon} onClick={onMenuToggle} />
      {isMenuOpen && (
        <TracksMenu
          track={track}
          handleCloseMenu={handleCloseMenu}
          handlePlaySong={handlePlaySong}
          handleSetAlbumId={handleSetAlbumId}
        />
      )}
    </div>
  );
}
