import React from "react";
import styles from "./Inbox.module.scss";
import Loader from "app/components/Loader";
import ConversationItem from "app/components/conversation_item/ConversationItem";

export default function ConversationContainer({
  loading,
  conversations,
  handleSelectConversation,
  selectedConversation,
}: {
  conversations: any;
  handleSelectConversation: any;
  loading: boolean;
  selectedConversation: any;
}) {
  return (
    <div className={styles.conversations_container}>
      <div className={styles.conversation_header}>
        <h1>Conversations</h1>
      </div>
      <div className={styles.conversations_list}>
        {loading ? (
          <div
            style={{
              position: "relative",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgb(23 25 27 / 48%)",
            }}
          >
            <Loader />
          </div>
        ) : conversations?.length > 0 ? (
          conversations?.map((convo, index) => (
            <ConversationItem
              convo={convo}
              key={index}
              handleSelectConversation={handleSelectConversation}
              selectedConversation={selectedConversation}
            />
          ))
        ) : (
          <span className={styles.no_convo}>No exisiting conversations</span>
        )}
      </div>
    </div>
  );
}
