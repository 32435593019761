import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import ReactCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Button from "../button/button";
import { useHistory } from "react-router-dom";

interface CarouselObject {
  link: string;
  title: string;
  desc: string;
  image: string;
  video?: string;
}

export default function Carousel({
  title,
  items,
}: {
  title: string;
  items: CarouselObject[];
}) {
  const history = useHistory();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, deviceType, slidesToShow, totalItems },
    } = rest;

    const slideCount =
      deviceType == "desktop"
        ? currentSlide * 3 >= items.length
        : deviceType == "tablet"
        ? currentSlide * 2 >= items.length
        : currentSlide + 1 == items.length; // Mobile

    const hidden =
      deviceType == "desktop"
        ? items.length < 3
        : deviceType == "tablet"
        ? items.length < 3
        : items.length < 2; // Mobile

    return (
      <div className={styles.carousel_button_group}>
        <Button
          hidden={hidden}
          disabled={currentSlide === 0 || currentSlide! < 0}
          className={currentSlide === 0 ? "disable" : ""}
          onClick={() => goToSlide(currentSlide - 1)}
        >
          &#8592;
        </Button>

        <Button
          hidden={hidden}
          disabled={slideCount}
          onClick={() =>
            goToSlide(currentSlide !== slideCount && currentSlide + 1)
          }
        >
          &#8594;
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className={styles.section}>
        <h3 className={styles.title}>{title}</h3>
        <ReactCarousel
          responsive={responsive}
          className={styles.cards}
          draggable={false}
          infinite={false}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={
            <ButtonGroup
              next={undefined}
              previous={undefined}
              goToSlide={undefined}
            />
          }
        >
          {items?.map((item, index) => {
            return (
              <>
                <div className={styles.card} key={index} onClick={() => history.push(item.link)}>
                  <div className={styles.overlay} />
                  <div className={styles.image}>
                    <img src={item.image} />
                  </div>
                  <div className={styles.content}>
                    <h5 className={styles.title}>{item.title}</h5>
                    <p>{item.desc}</p>
                  </div>
                </div>
              </>
            );
          })}
        </ReactCarousel>
      </div>
    </>
  );
}
