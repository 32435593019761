import { createContext, useContext, useEffect, useRef, useState } from "react";
import { FeedObject } from "app/core/common";
import { useHook } from "app/hooks/common";
import * as api from "../api/user.api";
import { getParam } from "app/helpers/helper";
import { get } from "http";

interface FeedState {
  feed: FeedObject[];
  feedLoading: boolean;
  lastUpdated: Date;
  moreExists: boolean;
}

interface FeedActions {
  fetchLatestFeed: () => void;
  fetchMoreFeed: () => void;
  setFeed: (feed: FeedObject[]) => void;
  tabChange: (tab: string) => void;
}

type FeedHook = FeedState & FeedActions;

const FeedContext = createContext<FeedHook>({
  feed: [],
  feedLoading: true,
  lastUpdated: new Date(),
  moreExists: true,
  fetchLatestFeed: () => {},
  fetchMoreFeed: () => {},
  setFeed: () => {},
  tabChange: () => {},
});

interface Props {
  id?: string;
  children?: React.ReactNode;
}

export const FeedContextProvider: React.FC<Props> = ({ children }) => {
  const { cookie, userInfo } = useHook();
  const [feed, setFeed] = useState<FeedObject[]>([]);
  const [feedLoading, setFeedLoading] = useState<boolean>(true);
  const [feedMeta, setFeedMeta] = useState<any>();
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [page, setPage] = useState(1);
  const [moreExists, setMoreExists] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  const forYou = useRef(getParam("tab") === "foryou");

  /**************************************/
  // Fetch User Feed
  /**************************************/
  const fetchUserFeed = async () => {
    setFeedLoading(true);

    if (userInfo && cookie) {
      try {
        const newFeed = await api.FetchFeed({
          userInfo,
          page,
          forYou: forYou.current,
        }); // Fetch feed
        setFeed((prevFeed) => [...prevFeed, ...newFeed.feed]); // Append new feed to existing feed
        setFeedMeta(newFeed.meta);
        setFeedLoading(false);
        setLastUpdated(new Date());
      } catch (error) {
        console.error(error); // Log error
        setFeedLoading(false);
      }
    }
  };

  const tabChange = (tab: string) => {
    forYou.current = tab === "foryou";
    setPage(1);
    setFeed([]);
    fetchUserFeed();
  };

  // Fetch feed on mount
  useEffect(() => {
    fetchUserFeed();
  }, [userInfo, page]);

  /*   useEffect(() => {
    if (followingList) {
      console.log(followingList);
    }
  }, [followingList]); */

  /**************************************/
  // Fetch More Feed
  /**************************************/
  const fetchMoreFeed = () => {
    const totalPages =
      feed.length > 0
        ? Math.ceil(feedMeta.filter_count / feedMeta.limit)
        : undefined;
    if (totalPages != undefined && page !== totalPages && !feedLoading) {
      setFeedLoading(true);
      setPage(page + 1); // Increment page number
      setMoreExists(page + 1 !== totalPages); // Check if more pages exist
    }
  };

  /**************************************/
  // Fetch Latest and push to top of feed
  /**************************************/
  const fetchLatestFeed = async () => {
    try {
      const lastDate = feed[0].date_created;
      if (lastDate) {
        setFeedLoading(true);
        if (userInfo && cookie) {
          await api
            .FetchByDate({ cookie, ids: [], lastDate, userInfo })
            .then((newFeed) => {
              setFeed((prevFeed) => [...newFeed.feed, ...prevFeed]); // Append new feed to existing feed at the top
              setFeedMeta(newFeed.meta);
              setLastUpdated(new Date());
              setFeedLoading(false);
            })
            .catch((error) => {
              setFeedLoading(false);
            });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FeedContext.Provider
      value={{
        feed,
        feedLoading,
        lastUpdated,
        moreExists,
        fetchLatestFeed,
        fetchMoreFeed,
        setFeed,
        tabChange,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};

export const useFeed = (): FeedHook => useContext(FeedContext);
