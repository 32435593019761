import React, { useEffect, useRef, useState } from "react";
import styles from "./StepOne.module.scss";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { blue } from "@mui/material/colors";
import PreviewContainer from "app/components/preview_container/PreviewContainer";
import ModalSelectProfilePicture from "app/components/Profile/modal_select_profile_picture/ModalSelectProfilePicture";
import OnBoardPictureModal from "app/components/onboard/onboard_modal/OnBoardPictureModal";
import { set } from "numeral";

export default function StepOne({
  imageProfile,
  setImageProfile,
  setImageCover,
  imageCover,
  setImageCoverFinal,
  imageCoverFinal,
  setReady,
  handleChangePictureCover,
  setImageProfileFinal,
  setImageCoverUpdate,
  setErrorModalOpen,
  setErrorModalMessage,
}) {
  const refProfile = useRef(null);
  const refCover = useRef(null);
  const pRef = useRef(null);
  const modal_picture_ref = useRef(null);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp"
    ) {
      setErrorModalOpen(true);
      setErrorModalMessage("Unsupported file format, please upload a jpeg, png or webp file.");
      return;
    } else if (file.size > 1024 * 1024 * 10) {
      setErrorModalOpen(true);
      setErrorModalMessage("The size of the file is too large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setImage(event.target.result);
    };
  };
  const handleCoverImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 10) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setImageCover(event.target.result);
    };
  };
  //if the image and the profile picture are ready, execute the function to update them
  useEffect(() => {
    if (imageProfile && imageCoverFinal) {
      setReady(true);
      handleChangePictureCover();
    }
  }, [imageProfile, imageCoverFinal]);

  return (
    <div className={styles.first_step}>
      <div className={styles.first_step_top}>
        <h1>
          Create your first Artist Loop so fans can vote for you and explore
          your exclusive content
        </h1>
        <span>Add Avatar and Cover Image</span>
        <small>Supported media: <b>jpeg | png | webp</b></small>
        <hr />
        <div className={styles.select_images_icon}>
          <div
            className={styles.profile_image_icon}
            onClick={() => {
              refProfile.current.click();
            }}
          >
            <input
              type="file"
              ref={refProfile}
              hidden
              onChange={handleImage}
              accept="image/jpeg,image/png,image/webp,image/jfif,"
            />
            <AddPhotoAlternateIcon sx={{ color: blue[700] }} />
            <span><b>Avatar</b></span>
            <span>200 x 200px</span>
            <span>Max: 7MB</span>
          </div>
          <div
            className={styles.cover_image_icon}
            onClick={() => {
              refCover.current.click();
            }}
          >
            <input
              type="file"
              ref={refCover}
              hidden
              onChange={handleCoverImage}
              accept="image/jpeg,image/png,image/webp,image/jfif,"
            />
            <AddPhotoAlternateIcon sx={{ color: blue[700] }} />
            <span><b>Cover Image</b></span>
            <span>1200 x 800px</span>
            <span>Max: 10MB</span>
          </div>
        </div>
      </div>
      <PreviewContainer
        modal_picture_ref={modal_picture_ref}
        imageProfile={imageProfile}
        imageCover={imageCover}
        setImageCover={setImageCover}
        setImageCoverFinal={setImageCoverFinal}
        imageCoverFinal={imageCoverFinal}
        setImageCoverUpdate={setImageCoverUpdate}
        showDetails={false}
      />
      {image ? (
        <OnBoardPictureModal
          image={image}
          setImage={setImage}
          setImageProfile={setImageProfile}
          setError={setError}
          modal_picture_ref={modal_picture_ref}
          setImageProfileFinal={setImageProfileFinal}
        />
      ) : null}
    </div>
  );
}
