import axios from "axios";

 interface SendEvent {
  cookie: string;
  event: string;
  eventId: string;
 }
 
 // Send Song Play Event
 export const sendEvent = async ({cookie, event, eventId} : SendEvent) => {
  if (cookie && event) {
    axios(`${process.env.REACT_APP_MIDDLEWARE}arena/event`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        event: event,
        eventId: eventId,
        cookie: cookie,
      },
    });
  }
};