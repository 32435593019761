import React, { useEffect, SetStateAction, useState } from "react";
import styles from "./ShareComponent.module.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import soundCloud from "../../../assets/icons/logo-soundcloud.svg";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import instagram from "../../../assets/icons/instant-camera-2.svg";
import TelegramIcon from "@mui/icons-material/Telegram";
import facebook from "../../../assets/icons/facebook.svg";
import x from "../../../assets/icons/twitter.png";
import web from "../../../assets/icons/url.svg";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

declare global {
  interface Window {
    FB: {
      init: (params: {
        appId: string;
        autoLogAppEvents: boolean;
        xfbml: boolean;
        version: string;
      }) => void;
      ui: (params: { method: string; href: string }) => void;
    };
  }
}

export default function ShareComponent({
  setShare,
  profile,
  collection,
}: {
  profile?: {
    id: string;
    username: string;
  };
  setShare?: React.Dispatch<SetStateAction<boolean>>;
  collection?: any;
  vote?: string;
}) {
  const [copied, setCopied] = useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const copyToClipboard = () => {
    if (inputRef.current) {
      navigator.clipboard.writeText(inputRef.current.value);
      setCopied(true);
    }
  };

  useEffect(() => {
    // Load Facebook SDK asynchronously
    const loadFacebookSDK = () => {
      const script = document.createElement("script");
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      script.onload = initFacebookSDK;
      document.body.appendChild(script);
    };

    const initFacebookSDK = () => {
      window.FB.init({
        appId: "your-facebook-app-id",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v12.0",
      });
    };

    loadFacebookSDK();

    return () => {
      // Cleanup function
    };
  }, []);

  const url =
    collection
      ? `https://app.loop.fans/collection/${collection.id}`
      : `https://app.loop.fans/user/${
          profile.username ? profile.username : profile.id
        }`;

  const handleFacebookShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
    );
  };

  const handleTwitterShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`
    );
  };

  const handleTelegramShare = () => {
    window.open(`https://t.me/share/url?url=${encodeURIComponent(url)}`);
  };

  const handleWhatsAppShare = () => {
    window.open(`whatsapp://send?text=${encodeURIComponent(url)}`);
  };

  const sendByEmail = () => {
    const subject = "Check this out!";
    const body = `Hey there!


I found something amazing on Loop.fans that I thought you might enjoy. Check it out and let me know what you think!

${url}`;
    const mailToLink = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailToLink;
  };

  return (
    <div className={styles.blur}>
      <div className={styles.postBox}>
        <div className={styles.shares_header}>
          <span>Share this link via:</span>
          <div
            onClick={() => setShare((prev) => !prev)}
            className={styles.cancel_icon}
          >
            <CancelIcon />
          </div>
        </div>
        <div className={styles.social_icons}>
          <div className={styles.icon} onClick={handleFacebookShare}>
            <img src={facebook} alt="" />
          </div>
          <div className={styles.icon} onClick={handleTwitterShare}>
            <img src={x} alt="" />
          </div>
          <div className={styles.icon} onClick={handleTelegramShare}>
            <TelegramIcon />
          </div>
          <div className={styles.icon} onClick={handleWhatsAppShare}>
            <WhatsAppIcon />
          </div>

          <div className={styles.icon} onClick={sendByEmail}>
            <MailOutlineIcon />
          </div>
        </div>
        <div className={styles.copy_link}>
          <span>Or copy link:</span>
          <div className={styles.copy_input}>
            <img src={web} alt="" />
            <input ref={inputRef} type="text" value={url} readOnly />
            <div
              className={copied ? styles.button_copied : styles.button_copy}
              onClick={copyToClipboard}
            >
              {copied ? "Copied" : "Copy"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
