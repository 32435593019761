import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import { WalletProvider } from "cloud-social-wallet";
import { UserProvider } from "app/context/userInfo";
import ErrorBoundary from "ErrorBoundary";
import { FeedContextProvider } from "app/context/feedContext";
import "app/util/i18n.ts";
import { LoginContextProvider } from "app/context/loginProvider";
import { MediaPlayerProvider } from "app/context/mediaPlayerContext";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <ErrorBoundary>
      <WalletProvider
        config={{
          rpc: "https://rpc.loop.pfc.zone",
          backend_url: "https://auth.loop.fans",
          prefix: "loop",
        }}
      >
        <LoginContextProvider>
          <UserProvider>
            <FeedContextProvider>
              <MediaPlayerProvider>
                <App />
              </MediaPlayerProvider>
            </FeedContextProvider>
          </UserProvider>
        </LoginContextProvider>
      </WalletProvider>
    </ErrorBoundary>
  </BrowserRouter>
);
