import { useEffect, useRef, useState } from "react";
import styles from "./collections.module.scss";

import { Link, useLocation } from "react-router-dom";
import {
  DirectusImage,
  CourseUrl,
  CreatorCollections,
  creatorType,
} from "app/core/common";
import * as api from "app/api/collections.api";
import Loader from "app/components/Loader";
import NftDrops from "app/components/nft_drops_/NftDrops";
import { useHook } from "app/hooks/common";
import CtaStudio from "./ctaStudio";

interface props {
  username: string;
  isUserProfile: boolean;
}

export default function ProfileCollections({ username, isUserProfile }: props) {
  const { userInfo } = useHook();
  const [collections, setCollections] = useState<CreatorCollections[]>([]);
  const [loading, setLoading] = useState(true);
  const artist = creatorType(userInfo?.role) === "Artist" || creatorType(userInfo?.role) === "Developer";

  useEffect(() => {
    api
      .getCreatorCollections({ username: username, limit: 0, type: 0 })
      .then((data) => {
        setCollections(data);
        setLoading(false);
      });
  }, []);

  return (
    <div className={styles.courses}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {artist && isUserProfile ? <CtaStudio key="cta-studio" /> : null}

          {collections?.map((item, index) => (
            <NftDrops collection={item} key={index} />
          ))}
        </>
      )}
    </div>
  );
}
