import axios from "axios";

export async function FetchFeed({ userInfo, page, forYou }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/feed`,
    method: "post",
    data: {
      userInfo: userInfo,
      page: page,
      forYou: forYou
    },
  });

  return result.data;
}

export async function FetchByDate({ cookie, ids, lastDate, userInfo }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/fetchByDate`,
    method: "post",
    data: {
      cookie: cookie,
      ids: ids,
      lastDate: lastDate,
      userInfo: userInfo
    },
  });

  return result.data;
}

export async function FetchComments({ post_id}) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/comments`,
    method: "post",
    data: {
      post_id: post_id
    },
  });

  return result.data;
}

export async function fetchUserData({ cookie, address }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}user/userInfo`,
    method: "post",
    data: {
      cookie: cookie,
      address: address,
    },
  });

  return result.data;
}

/* ================== */
// Following/Followers List
/* ================== */
export async function fetchUserFollowing({ cookie, userInfo }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}user/followingIds`,
    method: "post",
    data: {
      cookie: cookie,
      userInfo: userInfo,
    },
  });

  return result.data;
}

// Update WhatsApp
export async function userUpdateWhatsApp({ cookie, userInfo, whatsapp }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}user/update/whatsapp`,
    method: "post",
    data: {
      cookie: cookie,
      userInfo: userInfo,
      whatsapp: whatsapp,
    },
  });

  return result.data;
}
