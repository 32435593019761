import React from "react";
import Modal from "../Modal";

import styles from "./notification.module.scss";

const convertErrorMessage = (message: string): string => {
  if (
    message ===
      "Account does not exist on chain. Send some tokens there before trying to query sequence." ||
    message.includes("insufficient")
  ) {
    return "Add funds to your wallet!";
  }

  return message;
};

const Fail = ({ setTheme, message }: { setTheme: any; message?: string }) => {
  function onClose() {
    setTheme(undefined, undefined);
    return;
  }

  return (
    <Modal isOpen={true} title="" onClose={onClose} zIndex={4} center>
      <div className={styles.notification_content}>
        {/*  <img src={FailedIcon} alt='wait' /> */}
        <p className={styles.title}>Transaction Failed</p>
        <div className={styles.description}>
          {message ? convertErrorMessage(message) : "Please try again."}
        </div>
        <button onClick={() => onClose()}>CANCEL</button>
      </div>
    </Modal>
  );
};

export default Fail;
