import { useState, useEffect, useRef } from "react";
import timeAgo from "../../helpers/timeago";
import Comments from "../../components/comments";
import { useDoubleTap } from "use-double-tap";

import styles from "./DetailView.module.scss";

import Input from "app/components/input/input";
import { DetailViewProps, DirectusImage } from "../../core/common";
import { useUser } from "app/context/userInfo";
import * as api from "app/api/user.api";
import { handleCommentCount } from "app/components/HandleComment";
import ReactPlayer from "react-player";
import Button from "app/components/button/button";

import like_icon from "../../../assets/icons/like.svg";
import liked_icon from "../../../assets/icons/liked.svg";
import comment_icon from "../../../assets/icons/comment.svg";
import { handleLike } from "app/components/HandleLike";
import next_icon from "../../../assets/icons/next.svg";
import prev_icon from "../../../assets/icons/prev.svg";
import close_icon from "../../../assets/icons/close.svg";
import { useHook } from "app/hooks/common";

export default function DetailView({
  show,
  toggleModal,
  index,
  setIndex,
  items,
  setItems,
  currentTime,
  moreExists,
  fetchMoreFeed,
  feedLoading,
}: DetailViewProps) {
  const { cookie, address } = useHook();
  const { userInfo } = useUser();
  const [fetchedComments, setFetchedComments] = useState(false);
  const [commentLoading, setCommentLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const prev = index - 1;
  const next = index + 1;
  const item = items[index];

  useEffect(() => {
    (function () {
      const fetchComments = async () => {
        setComments([]);
        setCommentLoading(true);
        await api.FetchComments({ post_id: item.id }).then((comments) => {
          setComments(comments);
          setCommentLoading(false);
        });
      };

      fetchComments();

      return () => {
        setComments([]);
        setFetchedComments(false);
      };
    })();
  }, [item]);

  // Single-tap like action
  const handleSingleTap = () => {
    if (userInfo) {
      handleLike({ itemId: item.id, setItems, cookie, userInfo });
    }
  };

  const onSubmit = async () => {
    await handleCommentCount({
      cookie,
      userInfo,
      itemId: item.id,
      setItems,
      newComment,
    });
    const updatedComments = [
      {
        id: Math.floor(Math.random() * 9000000000) + 1000000000,
        Text: newComment,
        date_created: new Date(),
        user_id: {
          first_name: userInfo.first_name,
          display_name: userInfo.display_name,
          username: userInfo.username,
          avatar: {
            id: userInfo.avatar,
          },
        },
      },
      ...comments,
    ];
    return setComments(updatedComments);
  };

  const player = useRef(null);
  const [hasSeeked, setHasSeeked] = useState(false);
  const handlePlayerReady = () => {
    if (!hasSeeked) {
      player.current.seekTo(currentTime);
      setHasSeeked(true);
    }
  };

  const handlePrev = () => {
    if (prev >= 0 && prev < items.length) {
      setIndex(prev);
    }
  };
  const handleNext = () => {
    if (next < items.length) {
      setIndex(next);
      // load more feed items if we're at the end of the feed and more exists
      if (!feedLoading && moreExists) {
        fetchMoreFeed();
      }
    }
  };

  // Modal Controls: (Escape to close, Arrow keys to navigate)
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && show) {
        toggleModal(false);
      } else if (
        event.key === "ArrowLeft" &&
        prev >= 0 &&
        prev < items.length
      ) {
        handlePrev();
      } else if (event.key === "ArrowRight" && next < items.length) {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [show, prev, next, items]);

  return (
    show && (
      <section className={styles.modal}>
        <div className={styles.navigation}>
          <button disabled={prev < 0} onClick={() => handlePrev()}>
            <img src={prev_icon} />
          </button>
          <button disabled={next >= items.length} onClick={() => handleNext()}>
            <img src={next_icon} />
          </button>
        </div>

        <div
          className={styles.modal_backdrop}
          onClick={() => toggleModal(false)}
        />

        <section className={styles.modal_content}>
          <Button
            className={styles.close_button}
            onClick={() => toggleModal(false)}
          />
          {/* <div className={styles.content_image}>
            {item.type == "video" ? (
              <ReactPlayer
                ref={player}
                url={DirectusImage(item.image.id)}
                controls={true}
                playsinline
                playing
                muted // Add the muted attribute
                loop
                onReady={handlePlayerReady}
                width="100%"
                height="100%"
              />
            ) : (
              <>
                <img src={DirectusImage(item.image.id)} alt={`${item.id}`} />
                <div className={styles.overlay} />
              </>
            )}
          </div> */}
          <div className={styles.content}>
            <div className={styles.comments}>
              {/* Reply Content Start */}
              <Comments
                post={item}
                comments={comments}
                commentLoading={commentLoading}
              />
              {/* Reply Content End */}
            </div>

            <div className={styles.footer}>
              <div className={styles.actions}>
                <span>
                  <img
                    onClick={() => handleSingleTap()}
                    src={item?.liked ? liked_icon : like_icon}
                    alt="likes"
                  />{" "}
                  {item.likes}
                </span>
                <span>
                  <img src={comment_icon} alt="comments" /> {item?.comments}
                </span>
              </div>

              <div className={styles.add_comment}>
                <Input
                  classes={styles.input}
                  placeholder="Add a comment..."
                  value={newComment}
                  setValue={setNewComment}
                  onSubmit={() => onSubmit()}
                  showIcon={true}
                  disabled={!userInfo}
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    )
  );
}
