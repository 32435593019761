import React, { useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import SendIcon from "@mui/icons-material/Send";
import styles from "./ConversationInput.module.scss";
import Input from "../input/input";

export default function ConversationInput({
  loading,
  sendMessageHandler,
  handleMessage,
  message,
  selectedConversationInfos,
}: {
  loading: boolean;
  sendMessageHandler: any;
  handleMessage: any;
  message: string;
  selectedConversationInfos?: any;
}) {

  return (
    <div className={styles.actions_container}>
      {/*container*/}
      <div className={styles.input_content}>
        {/*input*/}
        <Input
          setValue={handleMessage}
          value={message}
          placeholder="Type a message..."
          onSubmit={() => sendMessageHandler()}
          disabled={!selectedConversationInfos}
        />
        {/*send button*/}
        <button
          className={styles.btn}
          disabled={loading || !selectedConversationInfos}
          onClick={() => sendMessageHandler()}
        >
          {loading ? (
            <ClipLoader color="#E9EDEF" size={25} />
          ) : (
            <SendIcon className="dark:fill-dark_svg_1" />
          )}
        </button>
      </div>
    </div>
  );
}
