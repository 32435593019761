import styles from "./ModalMobileAlert.module.scss";

export default function MobileModalAlert() {
  return (
    <div className={styles.modal_mobile}>
      <div className={styles.modal_top}>
        <h1>Experience it on desktop</h1>
      </div>
      <div className={styles.modal_bottom}>
        <span>To unlock all features please use the app on your computer.</span>
      </div>
    </div>
  );
}
