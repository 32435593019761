import React from "react";
import styles from "./Tabs.module.scss";
import { setParam } from "app/helpers/helper";
import { getParam } from "app/core/common";

interface TabsProps {
  name: string;
  slug: string;
}

export default function Tabs({ tabs, setTab, name, localStorage }: { tabs?: TabsProps[], setTab: (tab: string) => void, name?: string, localStorage?: boolean}) {
  const currentTab = localStorage ? window.localStorage.getItem(name ? name : "tab") : getParam(name ? name : "tab");
  const handleTab = (tab: string) => {
    if (localStorage) {
      window.localStorage.setItem(name ? name : "tab", tab);
      setTab(tab);
    } else {
      setParam(name ? name : "tab", tab.toLocaleLowerCase());
      setTab(tab);
    }
  };

  return (
    <>
      <div className={styles.tabs}>
        {tabs &&
          tabs?.map((tab, index) => {
            return (
                <div
                  key={index}
                  className={`${styles.tab} ${
                    tab.slug == currentTab ? styles.active : ""
                  }`}
                  onClick={() => handleTab(tab.slug)}
                >
                  <span>{tab.name}</span>
                </div>
            );
          })}
      </div>
    </>
  );
}
