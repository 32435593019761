import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "./button/button";

export default function NoContent({
  message,
  link,
  showButton,
  buttonMessage,
}: {
  message: string;
  link?: string;
  showButton: boolean;
  buttonMessage?: string;
}) {
  return (
    <div className="card">
      <h3>
        <Trans>{message}</Trans>
      </h3>
      <Link hidden={!showButton} to={link}>
        <Button className="btn_sm btn_solid btn_centered">
          {buttonMessage}
        </Button>
      </Link>
    </div>
  );
}
