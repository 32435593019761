import { useEffect, useRef, useState } from "react";
import styles from "./AboutMe.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import AboutMeModal from "app/components/Profile/about_me_modal/AboutMeModal";
import { Trans } from "react-i18next";
import axios from "axios";
import * as api from "app/api/profile.api";

type MusicProps = {
  isUserProfile: boolean;
  handleTab: any;
  userId: string;
};

export default function MusicImTo({ isUserProfile, userId }: MusicProps) {
  const [modalOpen, setOpenModal] = useState(false);
  const [musicGeners, setMusicGeners] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        await api.GetProfile({ id: userId }).then((user) => {
          setMusicGeners(user?.genres);
        });
      };
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const openModal = () => {
    setOpenModal(true);
    //disable scrolling on the body when the modal is open
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setOpenModal(false);
    // Restore scrolling on the body when the modal is closed
    document.body.style.overflow = "auto";
  };

  return (
    <div className={styles.music}>
      <span>
        Genres I'm into :

        {/* {isUserProfile && musicGeners.length > 0 ? (
          <div
            className={styles.music_button}
            style={{ fontWeight: 400, padding: "2px 10px" }}
            onClick={openModal}
          >
            <EditIcon fontSize="small" />
          </div>
        ) : null} */}
      </span>
      <div className={styles.music_buttons}>
        {/* if musics geners have data then select the first 6, if not show the button to add music genres*/}
        {musicGeners.length > 0 ? (
          musicGeners.slice(0, 6).map((music, index) => (
            <div className={styles.music_button} key={index}>
              {music.name}
            </div>
          ))
        ) : (
          <div onClick={openModal}>
            {isUserProfile ? (
              <div className={styles.music_button}>
                <Trans>addMusicalGenres</Trans>
              </div>
            ) : null}
          </div>
        )}
      </div>

      {modalOpen ? (
        <AboutMeModal
          closeModal={closeModal}
          setMusicGeners={setMusicGeners}
          musicGeners={musicGeners}
        />
      ) : null}
    </div>
  );
}
