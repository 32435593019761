import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ScrollToTop = () => {
  const history = useHistory();

  /* ==================== */
  /*  Scroll to Top
  /* ==================== */
  useEffect(() => {
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  /* ==================== */
  /*  Scroll to Top on route change
  /* ==================== */
  useEffect(() => {
    const unlisten = history.listen(() => {
      // Scroll to the top of the page on route change
      window.scrollTo(0, 0);
    });

    return () => {
      // Cleanup: Stop listening when the component is unmounted
      unlisten();
    };
  }, [history]);

  return null; // This component doesn't render anything
};

export default ScrollToTop;
