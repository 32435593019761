import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { useHook } from "app/hooks/common";
import { getParam } from "app/core/common";
import MusicTable from "./musicTable";
import { useLocation } from "react-router-dom";
import MusicList from "./musicList";
import { useMediaPlayer } from "app/context/mediaPlayerContext";
import axios from "axios";
import Loader from "app/components/Loader";
import { useHistory } from "react-router-dom";
import AlbumView from "./albumView";
import { IoMdArrowBack } from "react-icons/io";
import useClickOutside from "app/helpers/clickOutside";

interface Track {
  track: {
    id: string;
    title: string;
    artist: {
      username: string;
    };
    artwork: string;
    duration: number;
  };
}

interface Album {
  album: { id: string; name: string; tracks: number; artist: string };
}

// Define the type for the index
type Index = number;

export default function MusicLibrary() {
  const { cookie } = useHook();
  const [loading, setLoading] = useState<boolean>(false);
  const [playingIndex, setPlayingIndex] = useState<number | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [tracks, setTracks] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [albumId, setAlbumId] = useState(getParam("album"));
  const [albumTracks, setAlbumTracks] = useState([]);
  const [albumView, setAlbumView] = useState(true);
  const [data, setData] = useState([]);
  const [menuOpenForTrackId, setMenuOpenForTrackId] = useState(null);
  const menuRef = useRef(null);

  const history = useHistory();
  const location = useLocation();

  // Listen for query parameter changes and toggle views accordingly
  useEffect(() => {
    const param = getParam("album");
    if (param) {
      setAlbumId(param);
      setAlbumView(false); // Switch to track view when album param is present
    } else {
      setAlbumView(true); // Switch back to album view when album param is removed
    }
  }, [location]);

  const {
    toggleMediaPlayerModal,
    tooglePlayPause,
    handleTrack,
    showPlayerModal,
    actualTrack,
    play,
    handleTracks,
    handleSetAlbumId,
    addTracksToQueue,
  } = useMediaPlayer();

  // Fetch albums on component mount
  useEffect(() => {
    const fetchAlbums = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE}music/albums`,
          {
            headers: {
              "Content-Type": "application/json",
              user_cookie: cookie,
            },
          }
        );
        if (response?.data) {
          // Update the tracks inside each album with the album_id
          const updatedAlbums = response.data.map((album) => {
            const album_id = album.album.id;
            const updatedTracks = album.album.tracks.map((track) => ({
              ...track,
              album_id: album_id, // Add album_id to each track
            }));
            return {
              ...album,
              album: {
                ...album.album,
                tracks: updatedTracks, // Replace the tracks with updated ones
              },
            };
          });
          setAlbums(updatedAlbums);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching albums:", error);
      }
    };
    fetchAlbums();
  }, [cookie]);

  // Fetch songs of selected album
  useEffect(() => {
    if (albumId) {
      const fetchTracks = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_MIDDLEWARE}music/album/${albumId}`,
            {
              headers: {
                "Content-Type": "application/json",
                user_cookie: cookie,
              },
            }
          );
          if (response?.data) {
            // Add album_id inside the track object
            const tracksWithAlbumId = response.data.map((track: Track) => ({
              ...track,
              track: {
                ...track.track, // Spread the existing track details
                album_id: albumId, // Add albumId inside the track object
              },
            }));
            setTracks(tracksWithAlbumId); // Set the modified tracks
          }
        } catch (error) {
          console.error("Error fetching tracks for album:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchTracks();
    }
  }, [albumId, cookie]);

  const handlePlayPause = (track: Track, index: Index) => {
    if (
      actualTrack?.track?.id === track?.track?.id &&
      showPlayerModal &&
      play
    ) {
      tooglePlayPause(false); // Pause the current song
      setPlayingIndex(null);
    } else {
      if (tracks?.length > 1) {
        handleTracks(tracks, index); // Pass the tracks array and the current index
        toggleMediaPlayerModal(true); // Open the media player
        tooglePlayPause(true); // Play the new song
        setPlayingIndex(index);
      } else {
        handleTrack(track); // Set the current track
        toggleMediaPlayerModal(true); // Open the media player
        tooglePlayPause(true); // Play the new song
        setPlayingIndex(index);
      }
    }
  };

  const handlePlayPauseAlbum = (album) => {
    if (
      album?.album?.tracks.find(
        (track) => track.id === actualTrack?.track?.id
      ) &&
      showPlayerModal &&
      play
    ) {
      tooglePlayPause(false); // Pause the current song
      setPlayingIndex(null);
    } else {
      const selectedTracks = album?.album?.tracks.map((track: Track) => ({
        track: {
          ...track, // Spread the existing track details // Add albumId inside the track object
        },
      }));
      handleTracks(selectedTracks, 0); // Pass the tracks array and the current index
      toggleMediaPlayerModal(true); // Open the media player
      tooglePlayPause(true); // Play the new song
      setPlayingIndex(0);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAlbumSelect = (album: Album) => {
    history.push({
      pathname: "/music-library",
      search: `?album=${album.album.id}`, // Set the album as a query parameter
    });
  };

  const handleGoBack = () => {
    history.push("/music-library");
  };

  if (albumView) {
    return (
      <AlbumView
        albums={albums}
        handleAlbumSelect={handleAlbumSelect}
        loading={loading}
        handlePlayPauseAlbum={handlePlayPauseAlbum}
        handleSetAlbumId={handleSetAlbumId}
      />
    );
  }

  const handleOpenMenu = (trackId) => {
    if (menuOpenForTrackId === trackId) {
      // If the same menu is open, close it
      setMenuOpenForTrackId(null);
    } else {
      // Open the menu for the selected track and close others
      setMenuOpenForTrackId(trackId);
    }
  };

  return (
    <div className={styles.music_library_container}>
      <div className={styles.music_library_container__header}>
        <div className={styles.music_library_container__header_infos}>
          <div className={styles.music_library_container__header_infos_details}>
            <div
              className={
                styles.music_library_container__header_infos_details_text
              }
            >
              <h2 onClick={() => handleGoBack()}>
                <IoMdArrowBack /> <span>Back to album view</span>
              </h2>
              <h1>Music Library</h1>
              <p>Tracks: {tracks?.length}</p>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(23, 25, 27, 0.48)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : windowWidth > 767 ? (
        <div className={styles.musicTableContainer}>
          <table ref={menuRef}>
            <thead>
              <tr>
                <th>#</th>
                <th>Album</th>
                <th>Track</th>
                <th>Artist</th>
                <th>Duration</th>
                <th style={{ color: "transparent" }}>Playing</th>
                <th style={{ color: "transparent" }}>Playing</th>
              </tr>
            </thead>
            <tbody>
              {tracks?.map((track, index) => (
                <MusicTable
                  key={index}
                  track={track}
                  index={index}
                  isPlaying={playingIndex === index}
                  handlePlayPause={() => handlePlayPause(track, index)}
                  handleSetAlbumId={handleSetAlbumId}
                  isMenuOpen={menuOpenForTrackId === track.track.id}
                  onMenuToggle={() => handleOpenMenu(track.track.id)}
                  setMenuOpenForTrackId={setMenuOpenForTrackId}
                  menuRef={menuRef}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <hr />
          {tracks?.map((track, index) => (
            <MusicList
              key={index}
              track={track}
              handlePlaySong={() => handlePlayPause(track, index)}
              handleSetAlbumId={handleSetAlbumId}
              isMenuOpen={menuOpenForTrackId === track.track.id}
              onMenuToggle={() => handleOpenMenu(track.track.id)}
              setMenuOpenForTrackId={setMenuOpenForTrackId}
            />
          ))}
        </>
      )}
    </div>
  );
}
