import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Project_Gallery = ({
  gallery,
  preview,
}: {
  gallery?: any;
  preview?: boolean;
}) => {
  const [galleryLimit, setGalleryLimit] = useState<number>(4);
  useEffect(() => {
    if (gallery && preview) {
      setGalleryLimit(4);
    } else {
      setGalleryLimit(gallery?.length);
    }
  }, [preview]);
  return (
    gallery && (
      <div className={styles.gallery}>
        <div className={styles.info_header}>
          <h6>Gallery</h6>
        </div>
        <div className={styles.gallery_items}>
          {}
          {gallery?.slice(0, galleryLimit)?.map((r: any, i: number) => {
            return preview && i === galleryLimit - 1 ? (
              <div className={`${styles.item} ${styles.more} `} key={i}>
                <LazyLoadImage
                  src={
                    process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS +
                    r?.directus_files_id.id
                  }
                  alt="thumb"
                  effect="blur"
                  wrapperClassName={styles.image}
                />
                <p className={styles.message}>+{gallery?.length} More</p>
              </div>
            ) : (
              <div className={styles.item} key={i}>
                <LazyLoadImage
                  src={
                    process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS +
                    r?.directus_files_id.id
                  }
                  alt="thumb"
                  effect="blur"
                  wrapperClassName={styles.image}
                />
              </div>
            );
          })}
        </div>
        <hr />
      </div>
    )
  );
};

export default Project_Gallery;
