import { Button, Card, Grid } from "semantic-ui-react";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./discover.module.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CreatorCollections } from "app/core/common";
import ArtistImage from "./ArtistImage";
import ArtistSong from "./ArtistSong";
import NftSlider from "app/components/nftSlider";
import VideoSlider from "app/components/videoSlider";
import videos from "../../helpers/discoveryVideos.json";
import Collections from "app/components/discover/collections";
import CollectionSlider from "app/components/discover/collections/collectionSlider";

export default function DiscoverView() {
  const [collections, setCollections] = useState<CreatorCollections[]>([]);
  const [creators, setCreators] = useState([]);
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [genre, setGenre] = useState(1);
  const [playingSong, setPlayingSong] = useState<string>("");

  useEffect(() => {
    async function fetchCreators() {
      setLoading((prev) => !prev);
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}fans/creators`,
          headers: {
            "Content-Type": "application/json",
            page: page,
            limit: 16,
            query: `featured: { _eq: true }`,
          },
        });
        const data = await response.data;
        setCreators(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    async function fetchSongs() {
      setLoading((prev) => !prev);
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/leaderboard/latest?limit=1&page=1`,
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.data;
        setSongs(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading((prev) => !prev);
      }
    }

    async function fetchCollectibles() {
      setLoading((prev) => !prev);
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/collections?limit=8&page=1`,
          headers: {
            "Content-Type": "application/json",
            query: `featured: { _eq: true }`,
          },
        });
        const data = await response.data;
        setCollections(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading((prev) => !prev);
      }
    }

    fetchCreators();
    //fetchSongs();
    //fetchCollectibles();
  }, [page]);

  return (
    <div className={styles.discover}>
      <div className={styles.banner}>
        <img
          src="https://loop-markets.directus.app/assets/1b0d7110-d61a-47b4-a013-e3801b90e34d"
          alt="loop-banner"
        />
      </div>
      <div className={styles.container}>
        <div className={styles.collection_slider}>
          <div className={styles.title}>
            <h1>Recent Releases</h1>
          </div>
          <CollectionSlider />
        </div>
        <div className={styles.artist_slider}>
          <div className={styles.title}>
            <h1>Featured Artists</h1>
            <Link to="/loops">
              <Button className={styles.view_all}>SEE ALL</Button>
            </Link>
          </div>
          <div className={styles.artist_box}>
            {creators && creators?.length > 0 ? (
              creators?.map((creator, index) => (
                <ArtistImage
                  creator={creator}
                  creatorLaunchPad={creator?.launchpad}
                  key={index}
                />
              ))
            ) : (
              <div className="loader" />
            )}
          </div>
        </div>
        {/* <div className={styles.music_awards}>
          <div className={styles.title}>
            <h1>Web3 Music Awards</h1>
            <Link to="/wma/list">
              <Button className={styles.view_all}>SEE ALL</Button>
            </Link>
          </div>
          <div className={styles.leader_board_latest}>
            {songs && songs?.length > 0 ? (
              songs
                ?.slice(0, 12)
                ?.map((creator, index) => (
                  <ArtistSong
                    creator={creator}
                    key={index}
                    playingSong={playingSong}
                    setPlayingSong={setPlayingSong}
                  />
                ))
            ) : (
              <div className="loader" />
            )}
          </div>
        </div> */}
        {/* <div className={styles.artist_collectibles}>
          <div className={styles.title}>
            <h1>Featured Digital Collectibles</h1>
          </div>

          <NftSlider collections={collections} />
        </div> */}
        {/* <div className={styles.popular_videos}>
          <h1>Featured AI Music Videos</h1>
          <VideoSlider videos={videos} />
        </div> */}
      </div>
    </div>
  );
}
