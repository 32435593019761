import React, { useState, useRef } from "react";
import styles from "./MediaPlayer.module.scss";
import Equalizer from "../equalizer/Equalizer";
import { sendEvent } from "app/helpers/EventTracker";
import { useHook } from "app/hooks/common";

export default function MediaPlayer({
  title,
  song,
  size,
}: {
  title: string;
  song: string;
  size?: string;
}) {
  const equalizerRef = useRef(null);
  const { cookie } = useHook();

  // Handle song play
  const [isPlaying, setIsPlaying] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);
  const [playingSong, setPlayingSong] = useState(null);

  const handlePlayer = () => {
    setPlayingSong(song);
    setCurrentSong(song);
    if (playingSong === currentSong) {
      setPlaying((prev) => !prev);
      !playing &&
        // Send Song Play Event
        sendEvent({
          cookie: cookie,
          event: "play_song",
          eventId: song,
        });
    } else {
      setPlaying(false);
    }
  };

  return (
    song && (
      <div
        className={`${styles.mediaPlayer} ${
          size === "small" ? styles.mediaPlayer_small : ""
        }`}
      >
        {title && (
          <div>
            <p className={styles.price_text}>{title}</p>
          </div>
        )}
        <button
          className={playing ? styles.playing : styles.playSong}
          onClick={() => handlePlayer()}
        >
          {playing ? (
            <span>
              <img src="/assets/icons/media_pause.svg" />
            </span>
          ) : (
            <span>
              <img src="/assets/icons/media_play.svg" />
            </span>
          )}
        </button>
        {!playing ? null : (
          <Equalizer
            playing={playing}
            equalizerRef={equalizerRef}
            setPlaying={setIsPlaying}
            song={song}
            classes={`${styles.equalizer}`}
            barLimit={24}
          />
        )}
      </div>
    )
  );
}
