import React from "react";
import styles from "./OnboardInput.module.scss";

export default function OnboardInput({
  infos,
  handleChange,
  max,
  name,
  detail,
  placeholder,
  disabled,
}: {
  infos?: any;
  handleChange?: any;
  max?: number;
  name?: string;
  detail?: boolean;
  placeholder?: string;
  disabled?: boolean;
}) {
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Change the type of the event parameter to React.ChangeEvent<HTMLInputElement>
    const { name, value } = e.target;
    // If the value is empty, set it to the default value from the database

    handleChange({ [name]: value }); // Pass an object containing the updated value and its corresponding name
  };
  return (
    <input
      placeholder={placeholder}
      className={styles.input}
      onChange={handleTextAreaChange}
      name={name}
      maxLength={20}
      minLength={5}
      value={infos[name]}
    />
  );
}
