import { useEffect, useRef, useState } from "react";
import styles from "./courses.module.scss";

import { Link, useLocation } from "react-router-dom";
import { DirectusImage, CourseUrl, CreatorCourses } from "app/core/common";
import * as api from "app/api/courses.api";
import Loader from "app/components/Loader";

export default function ProfileCourses() {
  const [courses, setCourses] = useState<CreatorCourses[]>([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const id = location.pathname.split("/")[1];

  useEffect(() => {
    api.getCreatorCourses({ slug: id }).then((data) => {
      setCourses(data);
      setLoading(false);
    });
  }, []);

  return (
    <div className={styles.courses}>
      {loading ? (
        <Loader />
      ) : (
        courses?.map((item, index) => {
          return (
            <Link
              to={`/courses/${item?.slug}`}
              key={index}
              className={styles.card}
            >
              <div className={styles.courseImage}>
                <img src={DirectusImage(item?.image.id)} />
              </div>
              <p className={styles.title}>{item?.title}</p>
              <p className={styles.description}>{item?.description}</p>
            </Link>
          );
        })
      )}
    </div>
  );
}
