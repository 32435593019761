import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHook } from "app/hooks/common";
import { ProfileData, isCreator } from "app/core/common";
import styles from "./LeaderBoard.module.scss";

export default function LeaderBoardTabs({
  tabName,
  handleTab,
}: {
  tabName: string;
  handleTab: (name: string) => void;
}) {
  const tabs = [
    {
      slug: "list",
      name: <Trans>vote</Trans>,
      creator: true,
    },
    {
      slug: "result",
      name: <Trans>leaderboard</Trans>,
      creator: true,
    },
    {
      slug: "prizes",
      name: "Prizes",
      creator: true,
    },
  ];
  return (
    <div className={styles.tabs}>
      {tabs?.map((item, index) => {
        return (
          <div
            key={index}
            className={`${styles.tab} ${
              tabName?.includes(item.slug) ? styles.active : ""
            }`}
            onClick={() => handleTab(item.slug)}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
}
