import React from "react";
import styles from "./styles.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { truncate } from "app/helpers/helper";
import { useHistory } from "react-router-dom";
import { useMediaPlayer } from "app/context/mediaPlayerContext";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import QueueIcon from "@mui/icons-material/Queue";
import PersonIcon from "@mui/icons-material/Person";

export default function TracksMenu({
  track,
  handleMenuToggle,
  handlePlayPauseAlbum,
  handleSetAlbumId,
  handleAddToQueueAlbum,
  handleCloseMenu,
  handlePlaySong,
}: any) {
  const history = useHistory();
  const { actualTrack, addTrackToQueue } = useMediaPlayer();

  const handleOpenArtistProfile = () => {
    history.push(
      `/user/${track?.track?.artist.username ?? track?.track?.artist.id}`
    );
  };

  return (
    <div className={styles.club_details}>
      <div className={styles.details}>
        <div className={styles.details__top}>
          <div>
            <p>
              {truncate(track?.track?.title ?? track?.track?.title, 30, "...")}
            </p>
            <span>
              {truncate(
                track?.track?.artist?.username ??
                  track?.track?.artist?.username,
                30,
                "..."
              )}
            </span>
          </div>
          <div className={styles.close} onClick={() => handleCloseMenu()}>
            <CloseIcon />
          </div>
        </div>

        <div
          className={styles.content}
          onClick={() => {
            if (!actualTrack) {
              handleSetAlbumId(track?.track?.album_id);
              addTrackToQueue(track);
              handlePlaySong();
              handleCloseMenu();
            } else {
              addTrackToQueue(track);
              handleCloseMenu();
              handleSetAlbumId(track?.track?.album_id);
            }
          }}
        >
          <QueueIcon /> Add Song to Queue
        </div>

        <div
          className={styles.content}
          onClick={() => handleOpenArtistProfile()}
        >
          <PersonIcon /> See Artist
        </div>
      </div>
    </div>
  );
}
