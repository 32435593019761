import { useRef, useState } from "react";
import styles from "./index.module.scss";

import share from "assets/fans/share.svg";
//import { Trans } from "react-i18next";
import { useHook } from "app/hooks/common";
import {
  CurrencyRateType,
  DirectusImage,
  LaunchPadNFT,
  NFTMinterQuery,
} from "app/core/common";
import Modal from "app/components/Modal";
import { Link } from "react-router-dom";
import Button from "app/components/button/button";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import { LaunchPadNFTQueryInfo } from "app/api/common.api";
import { textTruncate } from "app/helpers/helper";
import Equalizer from "app/components/equalizer/Equalizer";

const curDate = new Date().toISOString();

const NftInfo = ({
  info,
  hasRedeemables,
  owner,
  hasBenefits
}: {
  info?: LaunchPadNFTQueryInfo;
  hasRedeemables: boolean;
  owner: boolean;
  hasBenefits: boolean;
}) => {
  /* const { MintNft } = handleFansTransaction(); */
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { address } = useHook();

  // Handle song play
  const equalizerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);
  const [playingSong, setPlayingSong] = useState(null);

  const handlePlayer = (song) => {
    setPlayingSong(song);
    setCurrentSong(song);
    if (playingSong === currentSong) {
      setPlaying((prev) => !prev);
    } else {
      setPlaying(false);
    }
  };

  return (
    <>
      <div className={styles.nft_view}>
        <div className={styles.header}>
          <div className={styles.desc}>
            <b
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: info.metadata.name,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: info.metadata.description,
              }}
            />
          </div>

          <div className={styles.artist}>
            <Link
              to={`/user/${
                info.collection.artist.username ?? info.collection.artist.id
              }`}
              className={styles.profile}
            >
              <img
                src={
                  "https://loop-markets.directus.app/assets/" +
                  info.collection.artist?.avatar?.id
                }
                alt="thumb"
              />
              <div>
                <span>
                  <>CREATOR</>
                </span>
                <p>{info.collection.artist.first_name}</p>
              </div>
            </Link>
            <div className={styles.divider} />
            <div>
              <span>
                <>Collection</>
              </span>
              <Link
                to={`/collection/${info.collection.launchpadInfo?.launchpad?.id}`}
              >
                <p style={{ textTransform: "capitalize" }}>
                  {textTruncate(info.metadata.project, 15)}
                </p>
              </Link>
            </div>
          </div>

          {/* Media Player */}
          {info?.collection?.song?.id && (
            <div className={styles.mediaPlayer}>
              <div>
                <p className={styles.price_text}>Track</p>
              </div>
              <button
                className={playing ? styles.playing : styles.playSong}
                onClick={() => handlePlayer(info?.collection?.song?.id)}
              >
                {playing ? (
                  <span>
                    <img src="/assets/icons/media_pause.svg" />
                  </span>
                ) : (
                  <span>
                    <img src="/assets/icons/media_play.svg" />
                  </span>
                )}
              </button>
              {!playing ? null : (
                <Equalizer
                  playing={playing}
                  equalizerRef={equalizerRef}
                  setPlaying={setIsPlaying}
                  song={info.collection.song.id}
                  classes={styles.equalizer}
                  barLimit={24}
                />
              )}
            </div>
          )}

          <Link
            to={`/user/${
              info.collection.artist.username ?? info.collection.artist.id
            }?tab=posts`}
          >
            <Button className={styles.artist_button_outline}>
              Visit Creator Profile
            </Button>
          </Link>

          {owner && hasBenefits && (
            <Button
              className={styles.artist_button}
              onClick={() =>
                document.getElementById("benefits").scrollIntoView()
              }
            >
              <Trans>viewBenefits</Trans>
            </Button>
          )}

          {/* NFT Mint Section */}
          {/* {mintStarted ? (
              <>
                <div className={styles.mint_live}>
                  <div>
                    <p className={styles.price_text}>
                      <Trans>price</Trans>
                    </p>
                    <p className={styles.price_value}>
                      {price == 0
                        ? `Free`
                        : `$${Number(price).toLocaleString()} ${symbol}`}
                    </p>
                    <p className={styles.convert} hidden={price == 0}>
                      $
                      {currencyRate
                        ? (currencyRate?.value * (price * 1)).toFixed(2)
                        : "..."}
                      <span> {currencyRate && currencyRate?.code}</span>
                    </p>
                  </div>
                  {mintEnded ? (
                    <button className={styles.buy} disabled>
                      Mint Ended
                    </button>
                  ) : (
                    <>
                      {!address ? (
                        <>
                          <Button onClick={() => setShowLoginModal(true)}>
                            Login
                          </Button>
                        </>
                      ) : (
                        <button
                          disabled={
                            !address || launchpadNFT?.mint_status !== "active"
                          }
                          onClick={() =>
                            price == 0 ? mintHandler() : setPaymentModal(true)
                          }
                          className={styles.buy}
                        >
                          {launchpadNFT?.mint_status !== "active" ? (
                            <Trans>pausedCap</Trans>
                          ) : price == 0 ? (
                            <Trans>mintNowCap</Trans>
                          ) : (
                            <Trans>buyNowCap</Trans>
                          )}
                        </button>
                      )}
                    </>
                  )}
                </div>
                <EndDate />
              </>
            ) : (
              <div className={styles.mint_live}>
                <span className={styles.countdown}>
                  <CountdownComp
                    launchpadNFT={launchpadNFT}
                    index={Number(index)}
                  />
                </span>
              </div>
            )} */}

          {/* <h3>
              {launchpadNFT?.launchpad_type[Number(index)].collection.name}
            </h3>
            <p
              className={styles.about}
              dangerouslySetInnerHTML={{
                __html:
                  launchpadNFT?.launchpad_type[Number(index)].collection
                    .description,
              }}
            /> */}
        </div>
      </div>
    </>
  );
};

export default NftInfo;
