import { createContext, useContext, useState } from "react";

interface LoginActions {
  showLoginModal: boolean;
  showSignup: boolean;
  artistSignup?: boolean;
  showRegister?: boolean;
  toggleArtistSignup?: (value: boolean) => void;
  toggleLoginModal: () => void;
  toggleSignupModal: (value: boolean) => void;
  toggleEmailVerificationModal: () => void;
  toogleRegisterModal: (value: boolean) => void;
}

export const LoginProviderContext = createContext<LoginActions>({
  showLoginModal: false,
  showSignup: false,
  artistSignup: false,
  showRegister: false,
  toggleArtistSignup: () => {},
  toggleLoginModal: () => {},
  toggleSignupModal: () => {},
  toggleEmailVerificationModal: () => {},
  toogleRegisterModal: () => {},
});

export const LoginContextProvider = ({ children }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [artistSignup, setArtistSignup] = useState(false);

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const toggleEmailVerificationModal = () => {
    setShowEmailVerification(!showEmailVerification);
  };

  const toggleSignupModal = (value: boolean) => {
    setShowSignup(value);
  };

  const toogleRegisterModal = (value: boolean) => {
    setShowRegister(value);
  };

  const toggleArtistSignup = (value: boolean) => {
    setArtistSignup(value);
  };

  return (
    <LoginProviderContext.Provider
      value={{
        showLoginModal,
        showSignup,
        artistSignup,
        toggleArtistSignup,
        toggleLoginModal,
        toggleSignupModal,
        toggleEmailVerificationModal,
        toogleRegisterModal,
        showRegister,
      }}
    >
      {children}
    </LoginProviderContext.Provider>
  );
};

export const useLogin = (): LoginActions => useContext(LoginProviderContext);
