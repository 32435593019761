import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import { useMediaPlayer } from "app/context/mediaPlayerContext";

export default function MoreMenu({
  album,
  track,
  handleAddToQueueAlbum,
  closeMenu,
  handlePlayPauseAlbum,
  handlePlayPause,
}: {
  album?: any;
  track?: any;
  handleAddToQueueAlbum?: (album: any) => void;
  closeMenu?: () => void;
  handlePlayPauseAlbum?: (album: any) => void;
  handlePlayPause?: any;
}) {
  const history = useHistory();
  const { actualTrack, addTrackToQueue } = useMediaPlayer();

  const handleOpenArtistProfile = () => {
    if (album) {
      history.push(`/user/${album?.album?.artist.username}`);
    } else {
      history.push(
        `/user/${track?.track?.artist.username ?? track?.track?.artist.id}`
      );
    }
  };

  return (
    <div className={styles.menu}>
      <span
        onClick={() => {
          if (album && !actualTrack) {
            handleAddToQueueAlbum(album); // Ensure this is executed if the album exists
            handlePlayPauseAlbum?.(album); // Ensure this is executed as well
          } else if (album && actualTrack) {
            handleAddToQueueAlbum?.(album); // Ensure this is executed if the album exists
          } else if (!actualTrack) {
            addTrackToQueue(track);
            handlePlayPause();
            closeMenu();
          } else {
            addTrackToQueue(track);
            closeMenu();
          }
        }}
      >
        Add to Queue
      </span>
      <span onClick={handleOpenArtistProfile}>See Artist</span>
    </div>
  );
}
