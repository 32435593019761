import React, { useState } from "react";
import styles from "./discover.module.scss";
import { useHook } from "app/hooks/common";
import { DirectusImage } from "app/core/common";
import { Link } from "react-router-dom";

export default function ArtistImage({
  creator,
  creatorLaunchPad,
}: {
  creator?: any;
  creatorLaunchPad?: any;
}) {
  const { cookie } = useHook();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={styles.creators_container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={DirectusImage(creator?.avatar?.id)} alt={`${creator?.id}`} />
      <div className={styles.overlay} />

      <div className={styles.club_details}>
        <div className={styles.details}>
          <Link to={`/user/${creator.username ?? creator.id}`}>
            <p>{creator?.display_name ?? creator?.first_name}</p>
          </Link>
          <div className={styles.content}>{creator?.description}</div>
        </div>
      </div>
    </div>
  );
}
