import { useHook } from "app/hooks/common";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../Modal";
import CloseIcon from "@mui/icons-material/Close";
import { DirectusImage } from "app/core/common";
import Loader from "../Loader";
import Button from "../button/button";
import HeadsetIcon from "@mui/icons-material/Headset";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

const OnboardModal = React.memo(() => {
  const { cookie, userInfo, updateUserInfo } = useHook();
  const history = useHistory();
  const [userType, setUserType] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (userInfo?.onboard === false) {
      setOpen(true);
    } else if (userInfo?.onboard === true) {
      setOpen(false);
    }
  }, [userInfo?.onboard]); // Depend on userInfo.onboard only

  const handleUserType = (event, type) => {
    event.preventDefault();
    setUserType(type);
    // Additional logic if needed
  };

  const handleUserOnboard = async () => {
    if (!userType) {
      setError("Please select an option");
      return;
    }
    setError("");
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("profile_onboard", "true");
      formData.append("cookie", cookie);
      formData.append("user_id", userInfo?.id);
      formData.append("profile_id", userInfo?.profile_id);
      formData.append("profile_type", userType);

      const change = await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE}user/action/update_profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      updateUserInfo(); // Update user info only after the API call is successful
      setLoading(false);
      setOpen(false); // Close the modal before redirecting

      if (change?.status === 200) {
        history.push(`/user/${userInfo.username ?? userInfo?.id}?edit=true`);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleCloseOnboard = async () => {
    setError("");
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("profile_onboard", "true");
      formData.append("cookie", cookie);
      formData.append("user_id", userInfo?.id);
      formData.append("profile_id", userInfo?.profile_id);
      formData.append("profile_type", "fan");

      await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE}user/action/update_profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLoading(false);
      setOpen(false); // Close the modal before redirecting
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={open}
      onClose={() => {}}
      customSubClass="userFlowModal"
      title=""
      sm={true}
    >
      <div
        style={{
          width: "100%",
          textAlign: "right",
          paddingRight: "20px",
          cursor: "pointer",
        }}
        onClick={() => handleCloseOnboard()}
      >
        <CloseIcon />
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "50px", padding: "20px" }}
        className="defaultModalContent"
      >
        <div className="image_text">
          <img
            src={DirectusImage("27e236b8-0989-42a1-90b8-774f33839f54")}
            alt=""
          />
          <span>Tell us a bit about you</span>
        </div>
        {loading ? (
          <div
            style={{
              position: "relative",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgb(23 25 27 / 48%)",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div
              className="button_fan"
              onClick={(event) => handleUserType(event, "fan")}
              style={{
                background: userType === "fan" ? "#9b83f9" : "transparent",
              }}
            >
              <HeadsetIcon />
              <span>I’m a Fan</span>
            </div>
            <div
              className="button_artist"
              onClick={(event) => handleUserType(event, "artist")}
              style={{
                background:
                  userType === "artist"
                    ? "rgba(157, 114, 219, 1)"
                    : "transparent",
              }}
            >
              <MusicNoteIcon />
              <span>I’m an Artist</span>
            </div>
            <Button size="md" onClick={() => handleUserOnboard()}>
              Continue
            </Button>
            <div style={{ marginTop: "10px" }}></div>
            <hr />
            <span
              style={{
                fontSize: "14px",
                color: "gray",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => handleCloseOnboard()}
            >
              Click to continue later
            </span>
            <div style={{ color: "red", margin: "20px" }}>
              {error ? error : null}
            </div>
          </>
        )}
        <br />
      </div>
    </Modal>
  );
});

export default OnboardModal;
