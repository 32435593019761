import React from "react";
import styles from "./collections.module.scss";
import Button from "app/components/button/button";

export default function CtaStudio() {
  const openLink = () => {
    window.open("https://studio.loop.fans", "_blank");
  };

  return (
    <div className={styles.creators_container} onClick={() => openLink()}>
      Create New Release
    </div>
  );
}
