import React, { useEffect, useState } from "react";
import styles from "./Inbox.module.scss";
import axios from "axios";
import { useHook } from "app/hooks/common";
import InboxMessageScreen from "./InboxMessageScreen";
import useScreenSize from "app/helpers/useScreenSize";
import ConversationContainer from "./ConversationContainer";

export default function Inbox() {
  const [conversations, setConversations] = useState([]);
  const { cookie } = useHook();
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [selectedConversationInfos, setSelectedConversationInfos] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const screenSize = useScreenSize();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/inbox/list`,
          headers: {
            user_cookie: cookie,
          },
        });
        setConversations(response.data || []);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [cookie]);

  const handleSelectConversation = async (convo) => {
    setSelectedConversation(convo);
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_MIDDLEWARE}arena/inbox/messages`,
        headers: {
          user_cookie: cookie,
          inbox_id: convo,
        },
      });
      setSelectedConversationInfos(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.inbox_container}>
      {/* <div className={styles.inbox_title_subtitle_input}>
        <div className={styles.inbox_title}>
          <h3>Inbox</h3>
          <p className={styles.subHeading}>
            Chat and be in touch with other members
          </p>
        </div>
      </div> */}
      <div className={styles.chat_container}>
        {screenSize.width < 600 ? (
          selectedConversation ? (
            <InboxMessageScreen
              selectedConversationInfos={selectedConversationInfos}
              selectedConversation={selectedConversation}
              setSelectedConversation={setSelectedConversation}
              setSelectedConversationInfos={setSelectedConversationInfos}
            />
          ) : (
            <ConversationContainer
              handleSelectConversation={handleSelectConversation}
              loading={loading}
              conversations={conversations}
              selectedConversation={selectedConversation}
            />
          )
        ) : (
          <>
            <ConversationContainer
              handleSelectConversation={handleSelectConversation}
              loading={loading}
              conversations={conversations}
              selectedConversation={selectedConversation}
            />
            <div className={styles.messages_container}>
              {!selectedConversation ? (
                <div className={styles.no_convo_selected}>
                  <img
                    src="https://loop-markets.directus.app/assets/27e236b8-0989-42a1-90b8-774f33839f54"
                    alt="loop-logo"
                  />
                </div>
              ) : (
                <InboxMessageScreen
                  selectedConversationInfos={selectedConversationInfos}
                  selectedConversation={selectedConversation}
                  setSelectedConversation={setSelectedConversation}
                  setSelectedConversationInfos={setSelectedConversationInfos}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
