import React from "react";
import styles from "./StepSeven.module.scss";
import PurpleButton from "app/components/purple-button/PurpleButton";
import { useHook } from "app/hooks/common";
import { Link } from "react-router-dom";
import Loader from "app/components/Loader";

export default function StepSeven({ voteNftCreated }) {
  const { userInfo, cookie, followingList } = useHook();

  const actions = [
    {
      to: `/user/${userInfo?.username ?? userInfo?.id}`,
    },
  ];
  return (
    <div className={styles.starting}>
      <div className={styles.middle_content}>
        <div className={styles.middle_img}></div>
        {!voteNftCreated ? (
          <>
            <div className={styles.text}>
              <h1>Finishing up...</h1>
            </div>

            <Loader />
          </>
        ) : (
          <div className={styles.go_to_my_profile}>
            <div className={styles.text}>
              <h1>You're all set!</h1>
            </div>
            {actions.map((action) => (
              <Link to={action.to} key={action.to}>
                <PurpleButton text={"Go to my profile"} />
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
