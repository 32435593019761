import { useState } from "react";
import axios from "axios";
import { useHook } from "app/hooks/common";
import { dec } from "ramda";
import { sendGleam } from "app/helpers/gleam";

// Gleam window
declare global {
  interface Window {
    Gleam: any;
  }
}

/************************/
// Handle Follow/Unfollow
/************************/
export async function handleFollow({ cookie, userInfo, follower_id }) {
  const userData = {
    id: userInfo.id,
    profile_id: userInfo.profile_id,
  };

  try {
    const res = await axios({
      url: `${process.env.REACT_APP_MIDDLEWARE}user/action/follow`,
      method: "post",
      data: {
        cookie,
        userInfo: userData,
        follower_id: follower_id,
      },
    });
    // return success message
    if (res.data) {
      // Send report to Gleam
      sendGleam({
        event: "followartists",
        value: follower_id,
      });

      return {
        success: true,
        message: res.data,
      };
    } else {
      return {
        success: false,
        message: res,
      };
    }
  } catch (error) {
    // return error message
    console.error(error);
    return error;
  }
}

export async function handlePostLike({ cookie, userInfo, post_id }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/action/like`,
    method: "post",
    data: {
      cookie,
      userInfo,
      post_id,
    },
  });

  if (result.data === "Liked") {
    // Send Report to Gleam
    sendGleam({
      event: "likepost",
      value: post_id,
    });
  }

  return result.data;
}

export async function handlePostComment({
  cookie,
  userInfo,
  post_id,
  comment,
}) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/action/comment`,
    method: "post",
    data: {
      cookie,
      userInfo,
      post_id,
      comment,
    },
  });

  // Send Report to Gleam
  sendGleam({
    event: "commentpost",
    value: post_id,
  });

  return result.data;
}
