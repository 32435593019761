import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import folder from "../../../assets/LOOP-FANS-FOLDER.png";
import Button from "../button/button";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { DirectusAssets } from "app/core/common";
import JSZip from "jszip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ClipLoader } from "react-spinners";

export default function File_Benefit({
  files,
  collection,
}: {
  files?: any;
  collection?: any;
}) {
  const [showContent, setShowContent] = useState<boolean>(false);
  const [fileList, setFileList] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const file = files ? files[fileIndex] : [];

  useEffect(() => {
    if (showContent && files) {
      const fetchAndExtractZip = async () => {
        try {
          const response = await fetch(DirectusAssets(file.files[0].file.id));
          const blob = await response.blob();
          const zip = await JSZip.loadAsync(blob);
          const files = [];

          zip.forEach((relativePath, zipEntry) => {
            files.push(relativePath);
          });

          setFileList(files);
        } catch (error) {
          console.error("Failed to fetch or extract zip file:", error);
        }
      };

      fetchAndExtractZip();
    }
  }, [showContent, fileIndex]);

  const handleDownload = async (index) => {
    if (collection) {
      return;
    } else {
      try {
        const response = await fetch(DirectusAssets(file.files[0].file.id));
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${file.name}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Failed to download zip file:", error);
      }
    }
  };

  const handleFileChange = (index: number) => {
    setFileIndex(index);
    if (fileIndex === index) {
      setShowContent(!showContent);
    } else {
      setShowContent(true);
    }
  };

  useEffect(() => {
      setFileList([]);
  }, [fileIndex, showContent]);

  useEffect(() => {
    // sort albums by order
    if (files?.length > 1) {
      files.sort((a: any, b: any) => a.order - b.order);
    }
  }, [files]);

  return (
    files?.length > 0 && (
      <div className={styles.fan_media}>
        <div className={styles.info_header}>
          <h6>
            {files.length > 1 ? <h6>Files: {files.length}</h6> : <h6>File</h6>}
          </h6>
        </div>
        {files.map((file: any, index: number) => (
          <div key={index}>
            <div className={styles.folder_info}>
              <img
                src={folder}
                alt=""
                onClick={() => handleFileChange(index)}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  /* alignItems: "center", */
                  gap: "20px",
                }}
              >
                <div className={styles.folder_content}>
                  <b>Name:</b> {file?.name}
                </div>
                <div
                  className={styles.folder_content}
                  onClick={() => handleFileChange(index)}
                >
                  <b>View Files</b>{" "}
                  {showContent && fileIndex === index ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowRightIcon />
                  )}
                </div>
                {collection ? null : (
                  <Button onClick={() => handleDownload(index)} size="sm">
                    Download
                  </Button>
                )}
              </div>
            </div>
            {showContent && fileIndex === index ? (
              <div className={styles.file_content}>
                <b>Files</b>
                <br />
                {fileList.length > 0 ? (
                  <ul>
                    {fileList.map((fileName, index) => (
                      <li key={index}>{fileName}</li>
                    ))}
                  </ul>
                ) : (
                  <ClipLoader />
                )}
              </div>
            ) : null}
          </div>
        ))}
        <hr />
      </div>
    )
  );
}
