import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./ModalSelectProfilePicture.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import PulseLoader from "react-spinners/PulseLoader";
import Cropper from "react-easy-crop";
import getCroppedImg from "app/helpers/getCroppedImg";

export default function ModalSelectProfilePicture({
  setPictureModal,
  setImage,
  image,
  setError,
  setShow,
  modal_picture_ref,
  pRef,
  setImageProfile,
}) {
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setcroppedAreaPixels] = useState(null);
  const slider = useRef(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setcroppedAreaPixels(croppedAreaPixels);
  };

  const zoomIn = () => {
    slider.current.stepUp();
    setZoom(slider.current.value);
  };
  const zoomOut = () => {
    slider.current.stepDown();
    setZoom(slider.current.value);
  };

  const getCroppedImage = useCallback(
    async (show) => {
      try {
        if (!croppedAreaPixels) {
          console.error("Cropped area pixels are null");
          return;
        }

        const img = await getCroppedImg(image, croppedAreaPixels);
        if (show) {
          setZoom(1);
          setCrop({ x: 0, y: 0 });
          setImage(img);
        } else {
          return img;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [croppedAreaPixels, image]
  );

  const updateProfilePicture = async () => {
    try {
      setLoading(true);
      let img = await getCroppedImage(false);

      if (img) {
        const imageUrl = URL.createObjectURL(img);

        setLoading(false);
        setImage("");
        modal_picture_ref.current.src = imageUrl;
        setImageProfile(img);
        setShow(false);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.error);
    }
  };

  const coverRef = useRef(null);
  const [width, setWidth] = useState();
  useEffect(() => {
    if (coverRef.current) {
      setWidth(coverRef.current.clientWidth);
    }
  }, [coverRef.current, window.innerWidth]);

  return (
    <div className={styles.postBox}>
      <div className={styles.box_header}>
        <div className={styles.header_left}>
          <i
            className={styles.close_icon}
            onClick={() => {
              setImage((prev) => !prev);
            }}
          >
            <CloseIcon />
          </i>

          <span>Profile Photo</span>
        </div>
        <div className={styles.header_rigth}>
          <button
            disabled={loading}
            onClick={() => {
              updateProfilePicture();
            }}
            className={
              !loading
                ? styles.button_save_modal
                : styles.button_save_modal_loading
            }
          >
            {loading ? (
              <PulseLoader color="white" size={10} />
            ) : (
              <span>Save</span>
            )}
          </button>
        </div>
      </div>
      <div className={styles.box_profile}>
        <div className={styles.edit_profile_top}>
          <div className={styles.crooper}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={false}
            />
          </div>
        </div>
        <div className={styles.edit_profile_bottom}></div>
      </div>
    </div>
  );
}
