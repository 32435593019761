import { useCallback, useEffect, useState, useRef } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./LeaderBoard.module.scss";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import LeaderBoardTabs from "./LeaderBoardTabs";
import { useHook } from "app/hooks/common";
import Creators from "app/components/leaderboard/Creators";
import LeaderBoardList from "app/components/leaderboard/leaderboard_comp/LeaderBoardList";
import { useInView } from "react-intersection-observer";
import { InView } from "react-intersection-observer";
import { getParam, setParam } from "app/helpers/helper";
import VotingSoonForm from "../Collection/votingSoon.form";
import VoteModal from "app/components/leaderboard/vote_modal/voteModal";
import AdComponent from "app/components/AdComponent";
import Prizes from "app/components/leaderboard/prizes/Prizes";

declare global {
  interface Window {
    aso: any[];
  }
}

export default function LeaderBoard() {
  const { userInfo } = useHook();
  const paramGenre = Number(getParam("genre"));
  const paramDivision = Number(getParam("division"));
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabName, setTabNname] = useState(
    window.location.pathname.split("/")[2]
  );
  const [genres, setGenres] = useState(null);

  const [divisions, setDivisions] = useState(null);
  const [genre, setGenre] = useState(paramGenre ? paramGenre : 1);
  const [division, setDivision] = useState(paramDivision ? paramDivision : 1);
  const [voted, setVoted] = useState(false);
  const [myUser, setMyUser] = useState(userInfo);
  const containerRef = useRef(null);
  const [moreFeed, setMoreFeed] = useState(true);
  const [voting, setVoting] = useState(false);
  const [creator, setCreator] = useState(null);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const [page, setPage] = useState(1);
  const [playingSong, setPlayingSong] = useState(null);

  const history = useHistory();
  const location = useLocation();
  //exctarting the value of the url
  const { type } = useParams<{ type: string }>();

  // promo hub
  const [adZone, setAdZone] = useState("117491");
  /* const [hubListing, setHubListing] = useState([
    "https://media.aso1.net/js/ifr.html#id=117491",
    "https://media.aso1.net/js/ifr.html#id=118639",
    "https://media.aso1.net/js/ifr.html#id=118640",
  ]); */

  // Clear the previous content
  const clearPrevContent = () => {
    setCreators([]);
    setPage(1);
  };

  const handleInViewChange = (inView) => {
    if (inView) {
      showMore();
    }
  };
  const showMore = () => {
    setPage((prev) => prev + 1);
  };

  /* ======================== */
  /* if there is no tabname, set list  */
  /* ======================= */
  useEffect(() => {
    if (!tabName) {
      history.push(
        `/wma/list?genre=${paramGenre ? paramGenre : 1}&division=${
          paramDivision ? paramDivision : 1
        }`
      );
      setTabNname("list");
    }
  }, [tabName]);

  // setting the url with the selected tab
  const handleTab = useCallback(
    (tabName: any) => {
      history.push(
        `/wma/${tabName}?genre=${paramGenre}&division=${paramDivision}`
      );
      setTabNname(tabName);
    },
    [history, paramGenre, paramDivision]
  );

  /* ======================== */
  // Set Params
  /* ======================= */
  useEffect(() => {
    paramGenre && setGenre(paramGenre);
    paramDivision && setDivision(paramDivision);
    // no param
    if (!paramGenre && !paramDivision) {
      setParam("genre", 1);
      setParam("division", 1);
    }
  }, [location.search]);

  /* ======================== */
  // Fetch Creators
  /* ======================= */
  useEffect(() => {
    //setPlayingSong(null);
    async function fetchData() {
      if (genre && division) {
        setLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_MIDDLEWARE}arena/leaderboard/list/${genre}/${division}/9/${page}`
          );
          const data = await response.data;

          setCreators((prev) => [...prev, ...data]);
          setLoading(false);
        } catch (err) {
          // Handle any errors
          console.log(err);
        }
      }
    }

    fetchData();
  }, [genre, division, page]);

  /* ======================== */
  // Fetch Geners/Divisions
  /* ======================= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/divisions`,
        });
        // Remove the first element from divisions array
        const updatedDivisions = response?.data?.divisions.slice(1);

        // Add the new element at the beginning
        /* updatedDivisions.unshift({ id: "0", name: "Votes by Division" }); */

        const responseGeners = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/genres`,
        });

        const updatedGeners = responseGeners?.data?.genres.slice(1);
        //console.log(updatedGeners);

        // Add the new element at the beginning
        /* updatedGeners.unshift({ id: "0", name: "Votes by Genre" }); */

        setDivisions(updatedDivisions);
        setGenres(updatedGeners);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // saving the selected genre and division
  const handleRegisterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === "genre") {
      setCreators([]);
      setGenre(Number(value));

      setParam(name, value);
      setPage(1);
    } else {
      setCreators([]);
      setDivision(Number(value));

      setParam(name, value);
      setPage(1);
    }
  };
  // function to controll how the vote process is performed
  const handleVote = (creatorId: string) => {
    // Implement your voting logic here
    setMyUser((prevUser) => ({
      ...prevUser,
      voted: true,
      votedArtistId: creatorId,
    }));
  };

  useEffect(() => {
    if (voting) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }

    return () => {
      // Clean up the effect
      document.body.classList.remove("disable-scroll");
    };
  }, [voting]);

  const handleOpenVote = (creatorLaunchPad) => {
    setCreator(creatorLaunchPad);
    setVoting((prev) => !prev);
  };
  useEffect(() => {
    if (voting) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = "auto"; // Allow scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset on component unmount
    };
  }, [voting]);

  return (
    <div className={styles.clubs}>
      <div className={styles.leaderboard_title_subtitle_input}>
        <div className={styles.leaderboard_title}>
          <h3>Web3 Music Awards</h3>
          <p className={styles.subHeading}>
            View artists participating in the WMA
          </p>
        </div>
      </div>
      <div className={styles.leaderboard_options}>
        <div className={styles.leaderboard_click_pages}>
          <LeaderBoardTabs tabName={tabName} handleTab={handleTab} />
        </div>
        <div className={styles.categories_select}>
          {type === "list" && (
            <div className={styles.select1}>
              <select
                name="genre"
                id="genre"
                required
                onChange={handleRegisterChange}
                defaultValue={genre ? genre : "Select Genre"}
                style={{ marginBottom: "10px" }}
                value={genre}
              >
                {genres &&
                  genres?.map((gen) => (
                    <option key={gen?.id} value={gen?.id}>
                      {gen?.name}
                    </option>
                  ))}
              </select>
            </div>
          )}

          {type === "prizes" ? null : (
            <div className={styles.select2}>
              <select
                name="division"
                id="division"
                required
                onChange={handleRegisterChange}
                defaultValue={division ? division : "Select division"}
                value={division}
              >
                {divisions &&
                  divisions?.map((option) => (
                    <option key={option?.id} value={option?.id}>
                      {option.description
                        ? `${option?.name} - (${option?.description})`
                        : option?.name}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>
      </div>
      {creators?.length > 0 && type === "list" ? (
        <>
          <div className={styles.club} ref={containerRef}>
            {/* {hubListing?.map((link, index) => (
              <div key={index} className={styles.hub_container}>
                <AdComponent state={genre || division} link={link} />
              </div>
            ))} */}
            {creators?.map((creator, index) => (
              <Creators
                creator={creator?.artist}
                creatorLaunchPad={creator?.launchpad}
                song={creator?.song?.id}
                key={index}
                myUser={myUser}
                handleVote={handleVote}
                setPage={setPage}
                playingSong={playingSong}
                setPlayingSong={setPlayingSong}
                handleOpenVote={handleOpenVote}
              />
            ))}
            {moreFeed && (
              <InView as="div" onChange={handleInViewChange}>
                <div ref={ref} onClick={() => showMore()}></div>
              </InView>
            )}
          </div>
        </>
      ) : null}

      {type === "result" ? (
        <>
          <LeaderBoardList
            genres={genres}
            genre={genre}
            division={division}
            divisions={divisions}
            handleTab={handleTab}
            clearPrevContent={() => clearPrevContent()}
          />
          {/* <div className="card">
            <h5 className="card-body" style={{ marginBottom: "20px", textAlign: "center" }}>
              Leaderboard update in progress. Please check back soon!
            </h5>
          </div> */}
        </>
      ) : (
        loading && <div className="loader" />
      )}

      {type === "prizes" ? (
        <>
          <Prizes />
        </>
      ) : null}

      {voting ? <VoteModal collection={creator} setVoting={setVoting} /> : null}
    </div>
  );
}
