import { useEffect, useState } from "react";
import styles from "./feed.module.scss";
import Modal from "../Modal";

import lockIcon from "../../../assets/icons/lock.svg";
import Button from "../button/button";
import axios from "axios";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

interface GatedContentLaunchpadProps {
  project_name: string;
  project_slug: string;
}

/* ========================== */
/* ===== No Access Flow ===== */
/* ========================== */
export const GatedContentCheck = ({ creator, content }) => {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [launchpad, setLaunchpad] = useState<GatedContentLaunchpadProps>();

  useEffect(() => {
    async function fetchLatestLaunchpad() {
      return await axios({
        url: `${process.env.REACT_APP_MIDDLEWARE}arena/launchpad/latest/${creator.id}`,
      });
    }

    if (showModal) {
      fetchLatestLaunchpad().then((res) => {
        if (res.data) {
          setLaunchpad(res.data[0]);
        }
        setLoading(false);
      });
    }
  }, [showModal]);

  return (
    <div className={styles.noAccess}>
      <Modal
        modalContentClass={styles.NoAccessModal}
        center={true}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={`Access ${creator.first_name}'s VIP Club`}
      >
        {loading ? (
          <Loader />
        ) : launchpad ? (
          <>
            <p>
              <Trans i18nKey="gatedContentClubOnGoing" />
            </p>
            <Link to={`/collection/${launchpad.project_slug}`}>
              <Button
                className={styles.AccessButton}
                onClick={() => setShowModal(true)}
              >
                Get My VIP Pass
              </Button>
            </Link>
          </>
        ) : (
          <>
            <p>
              <Trans i18nKey="gatedContentClubExpired" />
            </p>
          </>
        )}
      </Modal>


      <p>{content}</p>
      <br />

      <div className={styles.content}>
        <img src={lockIcon} />
        <p>
          This content is exclusive to {creator.first_name}'s VIP Club, Unlock
          it now by joining the club.
        </p>
        <Button
          className={styles.AccessButton}
          onClick={() => setShowModal(true)}
        >
          Exclusive Access
        </Button>
      </div>
    </div>
  );
};
