import React from "react";
import styles from "./InputProfile.module.scss";
import { checkUsername } from "app/hooks/common";

export default function InputProfile({
  infos,
  handleChange,
  max,
  name,
  detail,
  placeholder,
  disabled,
  onboard,
  textarea,
}: {
  infos?: any;
  handleChange?: any;
  max?: number;
  name?: string;
  detail?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onboard?: boolean;
  textarea?: boolean;
}) {
  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    // If the value is empty, set it to the default value from the database

    handleChange({ [name]: value }); // Pass an object containing the updated value and its corresponding name
  };

  return (
    <div className={styles.textarea_container}>
      {!onboard ? (
        <span>
          {placeholder} {disabled ? " - view only" : ""}
        </span>
      ) : null}

      <textarea
        disabled={disabled}
        style={
          disabled
            ? { cursor: "not-allowed" }
            : textarea
            ? { height: "130px" }
            : {}
        }
        /* placeholder={placeholder} */
        name={name}
        value={name === "username" ? checkUsername(infos[name]) : infos[name]}
        maxLength={max || 110}
        className={styles.input}
        onChange={handleTextAreaChange}
      ></textarea>
      {!detail && (
        <div className={styles.remaining}>{max} characters remainig</div>
      )}
    </div>
  );
}
