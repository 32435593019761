import React, { useEffect, useRef, useState } from "react";
import styles from "./AboutMe.module.scss";
import AboutMeText from "./AboutMeText";
import MusicImTo from "./MusicImTo";
import NftsIOwn from "./NftsIOwn";
import FavouriteSongs from "./FavouriteSongs";

export default function AboutMe({
  isUserProfile,
  profileData,
  latestNfts,
  handleTab,
  userId,
}) {
  return (
    <div className={styles.about_me_container}>
      <AboutMeText
        isUserProfile={isUserProfile}
        profileData={profileData}
        handleTab={handleTab}
      />
      <MusicImTo
        isUserProfile={isUserProfile}
        handleTab={handleTab}
        userId={userId}
      />
      {/* <NftsIOwn
        isUserProfile={isUserProfile}
        latestNfts={latestNfts}
        handleTab={handleTab}
      /> */}
      {/* <FavouriteSongs isUserProfile={isUserProfile} handleTab={handleTab}  /> */}
    </div>
  );
}
