import moment from "moment-timezone";
import React, { useRef, useState } from "react";
import styles from "./ConversationMessages.module.scss";
import { DirectusImage } from "app/core/common";

export default function Message({
  me,
  user,
  message,
}: {
  me: boolean;
  user: any;
  message: any;
}) {
  const messageR = useRef(null);
  const [optionMessage, setOptionMessage] = useState(false);

  return (
    <>
      <div
        ref={messageR}
        onClick={() => setOptionMessage((prev) => !prev)}
        className={`${styles.container} ${me ? styles.container_me : ""}`}
      >
        {/*Message container*/}
        {me ? null : (
          <img src={DirectusImage(message?.creator?.avatar)} alt="" />
        )}

        <div
          className={`${styles.message_container_message} ${
            me ? styles.bg_green : styles.bg_dark
          }`}
        >
          {/*message*/}
          <div className={styles.message_message}>
            <div className={styles.message_text}>{message?.message}</div>
          </div>
          {/*message date*/}
          <span className={styles.timestamp}>
            {moment(message?.date_created).format("HH:mm")}
          </span>
          {/*triangle*/}
        </div>
      </div>
    </>
  );
}
