import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHook } from "app/hooks/common";
import { ProfileData, isCreator } from "app/core/common";
import styles from "./Profile.module.scss";

export const ProfileTabs = ({
  user,
  tabName,
  handleTab,
}: {
  user: ProfileData;
  tabName: string;
  handleTab: (name: string) => void;
}) => {
  const location = useLocation();
  const userId = location.pathname.split("/")[3];
  const { userInfo } = useHook();

  const tabs = [
    {
      slug: "releases",
      name: isCreator(user.role) ? (
        "Releases"
      ) : (
        <Trans>collectionTab</Trans>
      ),
      creator: true,
      hidden: false
    },
    {
      slug: "posts",
      name: "Wall",
      creator: true,
    },
    {
      slug: "about",
      name: "About Me",
      creator: true,
    },
    /* {
      slug: "courses",
      name: <Trans>courses</Trans>,
      hidden: user?.access_courses ? false : true,
      creator: true,
    }, */
    /* {
      slug: "gallery",
      name: <Trans>gallery</Trans>,
      creator: true,
    },
    {
      slug: "events",
      name: <Trans>events</Trans>,
      creator: true,
    }, */
  ];

  return (
    <div className={styles.tabs}>
      {isCreator(user?.role) ? (
        <>
          {tabs?.map((item, index) => {
            return (
              <div
                className={`${styles.tab} ${
                  tabName == item.slug ? styles.active : ""
                }`}
                key={index}
                hidden={item.creator != !!isCreator(user?.role) || item.hidden}
                onClick={() => handleTab(item.slug)}
              >
                {item.name}
              </div>
            );
          })}
          {/* {isUserProfile && (
            <>
              <div
                className={`${styles.tab} ${
                  tabName == "nfts" ? styles.active : ""
                }`}
                onClick={() => handleTab("nfts")}
              >
                <Trans>myNFTs</Trans>
              </div>
            </>
          )} */}
        </>
      ) : (
        <>
          <div
            className={`${styles.tab} ${
              tabName == "posts" || tabName == "" ? styles.active : ""
            }`}
            onClick={() => handleTab("posts")}
          >
            <Trans>posts</Trans>
          </div>

          <div
            className={`${styles.tab} ${
              tabName == "about" || tabName == "" ? styles.active : ""
            }`}
            onClick={() => handleTab("about")}
          >
            <Trans>aboutMe</Trans>
          </div>

          {/* <div
            className={`${styles.tab} ${
              tabName == "nfts" || tabName == "" ? styles.active : ""
            }`}
            onClick={() => handleTab("nfts")}
          >
            <Trans>myAccount</Trans>
          </div> */}
        </>
      )}
    </div>
  );
};
