import { useState } from "react";
import axios from "axios";
import { useHook } from "app/hooks/common";

export type RestNft = {
  collection_id: number;
  token_id: string;
  name: string;
  description: string;
  image: string;
  token_uri: string;
  tier: number;
  owner: string;
  collection: RestCollection;
  created_at: string;
  attributes: NftAttribute[];
};

export type Creator = {
  artist: {
    id: string;
    first_name: string;
    display_name: string;
    username: string;
    avatar: {
      id: string;
    };
  };
  id: string;
  leaderboard: {
    id: string;
    name: string;
    division: {
      id: string;
      name: string;
    };
    genre: {
      id: string;
      name: string;
    };
  };
  song: {
    id: string;
  };
};

export type RestCollection = {
  id: number;
  name?: string;
  description?: string;
  created_at?: string;
  artist: {
    id: string;
    first_name: string;
    username: string;
    avatar: { id: string };
  };
  song?: {
    id: string;
  };
  launchpadInfo?: {
    launchpad?: {
      id: number;
    };
  };
  collection_album?: any;
  collection_video?: any;
  collection_files?: any;
  gallery?: any;
};

export type RestBenefits = {
  collection_gallery?: {
      gallery_items: {
        directus_files_id: {
          id: string;
          title: string;
        };
      };
  };
  collection_album?: {
    id: string;
    name: string;
    genre: {
      id: number;
      name: string;
    };
    tracks: {
      track: {
        id: string;
        title: string;
      };
    };
  };
  gallery?: any;
  collection_video?: {
    id: string;
    name: string;
    videos: {
      video: {
        id: string;
        title: string;
      };
    };
  };
  collection_files?: {
    id: string;
    name: string;
    files: {
      file: {
        id: string;
        title: string;
      };
    };
  };
  collection_song?: any;
};

export type NftAttribute = {
  id: number;
  collection_id: number;
  token_id: string;
  attribute_key: string;
  attribute_value: string;
};

export type MintConfigCosmWasm = {
  nft_addr: string;
  nft_max_supply: number;
  nft_current_supply: number;
  nft_price_amount: string;
  nft_symbol: string;
  mint_start_time: number;
  public_mint_limit: number;
  public_mint_period: number;
  whitelist_mint_limit: number;
  whitelist_mint_period: number;
  public_mint_price?: string;
  whitelist_mint1_limit?: number;
  whitelist_mint1_period?: number;
  whitelist_mint1_price?: string;
  whitelist_mint2_limit?: number;
  whitelist_mint2_period?: number;
  whitelist_mint2_price?: string;
};

export type LaunchPadNFTInfo = {
  owner: string;
  tier_index: string;
  token1_addr: string;
  token1_amount: string;
  token2_addr: string;
  token2_amount: string;
  vesting_period: string;
  token_uri: string;
  type: "Reward" | "Standard";
};

export type LaunchPadNFTStakeInfo = {
  claimed_days: number;
  total_staked_time: number;
  is_staking: boolean;
};

export type LaunchpadNFTMetadata = {
  name: string;
  project: string;
  description: string;
  image: string;
  attributes: {
    trait_type: string;
    value: string;
  }[];
  external_url?: string;
  animation_url?: string;
  youtube_url?: string;
};

export type LaunchPadNFTQueryInfo = {
  collection?: RestCollection;
  benefits?: RestBenefits;
  mintConfig?: MintConfigCosmWasm;
  info: LaunchPadNFTInfo;
  metadata: LaunchpadNFTMetadata;

  contracts: {
    nft: string;
    nftStaking: string;
    nftAuction: string;
    nftSale: string;
  };
};

/************************/
// Check if Following
/************************/
export async function checkIsFollowing({ cookie, userInfo, follower_id }) {
  try {
    const userData = {
      id: userInfo.id,
      profile_id: userInfo.profile_id,
    };

    const res = await axios({
      url: `${process.env.REACT_APP_MIDDLEWARE}user/action/isFollowing`,
      method: "post",
      data: {
        cookie,
        userInfo: userData,
        id: follower_id,
      },
    });
    // return success message
    if (res.data.length === 0) {
      // not following
      return {
        isFollowing: false,
      };
    } else {
      // following
      return {
        isFollowing: true,
      };
    }
  } catch (error) {
    // return error message
    console.error(error);
    return error;
  }
}

export async function CheckTransactionStatus({ transaction_id }) {
  const result = await axios.get(
    `${process.env.REACT_APP_MIDDLEWARE}marketplace/transaction_status/${transaction_id}`
  );
  return result.data;
}

export const queryLaunchpadNFTInfo = async (
  contract: string,
  tokenId: string
) => {
  const url = `${process.env.REACT_APP_MIDDLEWARE}indexer/nftDetail/${tokenId}`;
  const response = await axios.get(url);
  const data: RestNft = response.data;
  const attributes = data?.attributes?.map((item) => {
    return {
      trait_type: item.attribute_key,
      value: item.attribute_value,
    };
  });

  const result: LaunchPadNFTQueryInfo = {
    collection: {
      id: data.collection.id,
      song: { id: data?.collection?.song?.id },
      artist: {
        id: data.collection.artist.id,
        first_name: data.collection.artist.first_name,
        username: data.collection.artist.username,
        avatar: { id: data.collection?.artist?.avatar?.id },
      },
      launchpadInfo: {
        launchpad: {
          id: data.collection.launchpadInfo[0].launchpad.id,
        },
      },
    },
    benefits: {
      collection_gallery: {
        ...data.collection.gallery[0],
      },
      collection_album: {
        ...data.collection.collection_album,
      },
      collection_video: {
        ...data.collection.collection_video,
      },
      collection_files: {
        ...data.collection.collection_files,
      },
    },
    info: {
      owner: data.owner,
      tier_index: data.tier + "",
      token1_addr: "",
      token1_amount: "",
      token2_addr: "",
      token2_amount: "",
      vesting_period: "",
      token_uri: data.token_uri,
      type: "Standard",
    },
    metadata: {
      name: data.name,
      project: data.collection.name,
      description: data.description,
      image: data.image,
      attributes: attributes,
      external_url: "",
      animation_url: "",
      youtube_url: "",
    },
    contracts: {
      nft: contract,
      nftStaking: "",
      nftAuction: "",
      nftSale: "",
    },
  };

  return result;
};

export async function getRedeemablesForNft({
  contract,
  tokenId,
  address,
  cookie,
}: {
  contract: string;
  tokenId: string;
  address: string;
  cookie: string;
}) {
  const result = await axios.get(
    `${process.env.REACT_APP_MIDDLEWARE}minter/redeemables/${tokenId}?address=${address}&cookie=${cookie}`
  );
  return result?.data;
}
