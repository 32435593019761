import { useEffect, useRef, useState } from "react";
import styles from "./status.module.scss";
import { useHistory } from "react-router-dom";
import { TransactionType } from "app/core/common";
import { CheckTransactionStatus } from "app/api/common.api";
import Button from "app/components/button/button";
import Loader from "app/components/Loader";
import { useHook } from "app/hooks/common";
import { Trans } from "react-i18next";
import { t } from "i18next";
//import { Trans } from 'react-i18next';

export default function TransactionStatus() {
  const { userInfo } = useHook();
  const history = useHistory();
  const url = new URL(window.location.href);
  const transaction_id = url.searchParams.get("id");
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [transaction, setTransaction] = useState<TransactionType>();

  useEffect(() => {
    CheckTransactionStatus({ transaction_id }).then((res) => {
      // Delay for 8 seconds
      setTimeout(() => {
        setTransaction(res);
        setTransactionLoading(false);
      }, 5000);
    });
  }, [transaction_id]);

  const [wordIndex, setWordIndex] = useState(0);
  const text = useRef([
    `${t("transactionMessage1")}`,
    `${t("transactionMessage2")}`,
    `${t("transactionMessage3")}`,
  ]);

  useEffect(() => {
    //when it gets to last word in array, don't loop
    if (wordIndex === text.current.length - 1) return;
    const interval = setInterval(() => {
      setWordIndex((wordIndex) => wordIndex + 1);
    }, Math.floor(Math.random() * 3000) + 1000);
    return () => clearInterval(interval);
    //when wordIndex changes, reset to 0
  }, [wordIndex]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          {transactionLoading && !transaction ? (
            <div>
              <h3>{text.current[wordIndex]}</h3>
              <Loader />
            </div>
          ) : transaction.payment_status === "APPROVED" ? (
            <>
              <h3>
                <Trans>transactionApproved</Trans>
              </h3>
              <p>
                {transaction?.number_of_nfts > 1 ? (
                  <Trans>transactionMintPlural</Trans>
                ) : (
                  <Trans>transactionMintSingular</Trans>
                )}
              </p>
              <Button
                onClick={() =>
                  userInfo
                    ? history.push(`${userInfo.id}`)
                    : history.push(`/`)
                }
              >
                {userInfo ? <Trans>transactionMyNFTs</Trans> : <>login</>}
              </Button>
            </>
          ) : (
            <>
              <h3>{transaction.payment_status}</h3>
              <p>
                <Trans>transactionPurchaseError</Trans>
              </p>
              <Button
                className={styles.try_again}
                onClick={() =>
                  history.push(
                    `/collection/${transaction?.launchpad_id?.project_slug}`
                  )
                }
              >
                <Trans>transactionTryAgain</Trans>
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
