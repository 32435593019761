import styles from "./ArtistSignUp.module.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { deepPurple } from "@mui/material/colors";
import Button from "app/components/button/button";
import PurpleButton from "app/components/purple-button/PurpleButton";
import { Link } from "react-router-dom";
import { useLogin } from "app/context/loginProvider";
import { useHook } from "app/hooks/common";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BrowserView, MobileView, isSafari } from "react-device-detect";
import MobileModalAlert from "app/components/onboard/mobile_modal_alert/MobileModalAlert";
import SafariAlert from "app/components/onboard/safari_alert/SafariAlert";
import { DirectusImage } from "app/core/common";

export default function ArtistSignup() {
  const { userInfo, cookie, followingList } = useHook();
  const {
    showSignup,
    toggleLoginModal,
    toggleSignupModal,
    toggleArtistSignup,
  } = useLogin();
  const history = useHistory();
  useEffect(() => {
    if (userInfo) {
      history.push("/create-loop");
    }
  }, [userInfo]);

  if (isSafari) {
    return (
      <>
        <SafariAlert />
      </>
    );
  }

  return (
    <>
      <MobileView>
        <MobileModalAlert />
      </MobileView>
      <BrowserView>
        <div className={styles.signup_container}>
          <div className={styles.signup_left}>
            <h1>Join the Web3 Music Awards!</h1>
          </div>
          <div className={styles.signup_right}>
            <img
              src="https://loop-markets.directus.app/assets/27e236b8-0989-42a1-90b8-774f33839f54"
              alt=""
            />
            <h1>Create your Artist Profile</h1>

            <ul>
              <li>
                <CheckCircleOutlineIcon
                  fontSize="small"
                  sx={{ color: deepPurple[200] }}
                />
                Join the top artists leaderboards
              </li>
              <li>
                <CheckCircleOutlineIcon
                  fontSize="small"
                  sx={{ color: deepPurple[200] }}
                />
                Get votes from our community
              </li>
              <li>
                <CheckCircleOutlineIcon
                  fontSize="small"
                  sx={{ color: deepPurple[200] }}
                />
                Create content to get more fans
              </li>
              <li>
                <CheckCircleOutlineIcon
                  fontSize="small"
                  sx={{ color: deepPurple[200] }}
                />
                Follow other artists for collaborations
              </li>
            </ul>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                fontWeight: "bold",
                paddingBottom: "20px",
              }}
            >
              <img
                style={{ width: "100px" }}
                src={DirectusImage("847028fe-7063-46fd-8f73-5e16210fa6f6", 80)}
              />{" "}
              <p
                // underline
                /* style={{ cursor: "pointer", textDecoration: "underline"}}
                onClick={() => {
                  // open in new tab
                  window.open("https://app.loop.fans/loops", "_blank");
                }} */
              >
              Over 1200 Artists have joined
              </p>
            </div>
            <div
              style={{ minWidth: "300px" }}
              onClick={() => {
                toggleLoginModal();
                toggleArtistSignup(true);
                toggleSignupModal(true);
              }}
            >
              <Button
                className="btn_full btn_solid"
                size="sm"
                outline={false}
                children={"Get started"}
              />
            </div>

            {/* <p>Need more convincing?</p>
            <div
              style={{ minWidth: "300px" }}
              onClick={() => {
                // open in new tab
                window.open("https://awards.loop.fans", "_blank");
              }}
            >
              <Button
                className="btn_full"
                size="sm"
                outline={false}
                children={"Learn more about the WMAs"}
              />
            </div> */}
          </div>
        </div>
      </BrowserView>
    </>
  );
}
