import { handlePostLike } from "app/api/actions.api";

// Handle the like action
export const isLiked = (itemId, feed) => {
  return feed.find((item) => (item.id === itemId ? { ...item } : {}));
};

export const handleLike = async ({ itemId, setItems, cookie, userInfo }) => {
  setItems((prevItems) =>
    prevItems.map((item) => {
      return item.id === itemId
        ? {
            ...item,
            liked: (item.liked = !item.liked),
            likes: item.liked ? item.likes + 1 : item.likes - 1,
          }
        : item;
    })
  );
  await handlePostLike({ cookie, post_id: itemId, userInfo });
  return;
};
