import React from "react";
import styles from "./SafariAlert.module.scss";

export default function SafariAlert() {
  return (
    <div className={styles.modal_mobile}>
      <div className={styles.modal_top}>
        <h1>Experience it on Chrome or FireFox</h1>
      </div>
      <div className={styles.modal_bottom}>
        <span>For best experience please use Chrome or Firefox.</span>
      </div>
    </div>
  );
}
