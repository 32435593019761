import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import styles from "../modals/PaymentModal/PaymentModal.module.scss";
import ClipLoader from "react-spinners/ClipLoader";

export default function CheckoutForm({ price, quantity, setPaymentSuccess }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          setPaymentSuccess(true);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // We are removing the return_url parameter to handle the success state within the component.
      },
      redirect: "if_required", // This prevents the redirection and handles the payment result here.
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } else if (paymentIntent) {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          setPaymentSuccess(true);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Payment method is required.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {/* Show any error or success messages */}
      {message && (
        <div
          style={{
            marginTop: "10px",
            padding: "10px 20px",
            background: "red",
            color: "white",
          }}
        >
          {message}
        </div>
      )}
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className={styles.buy}
      >
        <span>
          {isLoading ? (
            <div className="spinner" id="spinner">
              <ClipLoader color="#E9EDEF" size={25} />
            </div>
          ) : (
            <span style={{ fontWeight: "Bold", fontSize: "16px" }}>
              Pay Now ${price * quantity}
            </span>
          )}
        </span>
      </button>
    </form>
  );
}
const clientSecret = new URLSearchParams(window.location.search).get(
  "payment_intent_client_secret"
);
