import React from "react";
import styles from "./Prizes.module.scss";
import StarRateIcon from "@mui/icons-material/StarRate";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import RedeemIcon from "@mui/icons-material/Redeem";
import StarIcon from "@mui/icons-material/Star";

const prizeData = [
  {
    position: 1,
    star: "$10,000",
    established: "$10,000",
    midLevel: "$7,500",
    emerging: "$7,500",
    early: "$5,000",
    enthusiast: "$5,000",
  },
  {
    position: 2,
    star: "$5,000",
    established: "$5,000",
    midLevel: "$4,000",
    emerging: "$4,000",
    early: "$2,500",
    enthusiast: "$2,500",
  },
  {
    position: 3,
    star: "$2,500",
    established: "$2,500",
    midLevel: "$2,000",
    emerging: "$2,000",
    early: "$1,500",
    enthusiast: "$1,500",
  },
  {
    position: 4,
    star: "$1,500",
    established: "$1,500",
    midLevel: "$1,000",
    emerging: "$1,000",
    early: "$750",
    enthusiast: "$750",
  },
  {
    position: 5,
    star: "$1,000",
    established: "$1,000",
    midLevel: "$500",
    emerging: "$500",
    early: "$250",
    enthusiast: "$250",
  },
];

export default function Prizes() {
  return (
    <div className={styles.prizes_container}>
      <div className={styles.prizes_title}>
        <h1>$250,000 worth of Prizes!</h1>
      </div>
      <div className={styles.prizes_content}>
        <div className={styles.message}>
          <div className={styles.icon}>
            <StarRateIcon
              sx={{
                color: "gold",
              }}
            />
          </div>

          <div className={styles.prizes_info}>
            <h1>Top 5 in each division!</h1>
            <span>
              The Top 5 Artists in each of our divisions will receive the
              highest $ Prizes.
            </span>
          </div>
        </div>
        <div className={styles.message}>
          <div className={styles.icon}>
            <WorkspacePremiumIcon
              sx={{
                color: "gold",
              }}
            />
          </div>

          <div className={styles.prizes_info}>
            <h1>Everyone Wins!</h1>
            <span>
              In the spirit of inclusivity and community, every artist with at
              least one vote will win a share of the $160K FANS Tokens pool.
            </span>
          </div>
        </div>
        <div className={styles.message}>
          <div className={styles.icon}>
            <RedeemIcon
              sx={{
                color: "gold",
              }}
            />
          </div>

          <div className={styles.prizes_info}>
            <h1>Sponsor Prizes!</h1>
            <span>
              Special prizes from sponsors who are industry leaders in providing
              services and tools for artists to succeed.
            </span>
          </div>
        </div>
      </div>
      <div className={styles.prizes_tab}>
        <h1>The Dollar Value of Prizes for Each Division</h1>
      </div>
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Top 5</th>
              <th>Star</th>
              <th>Established</th>
              <th>Mid level</th>
              <th>Emerging</th>
              <th>Early</th>
              <th>Enthusiast</th>
            </tr>
          </thead>
          <tbody>
            {prizeData.map((row) => (
              <tr key={row.position}>
                <td>
                  <div className={styles.star_rank}>
                    <StarIcon
                      sx={{
                        fontSize: 40,
                        color: "gold",
                      }}
                    />
                    <span>{row.position}</span> {/* Display rank */}
                  </div>
                </td>
                <td>{row.star}</td>
                <td>{row.established}</td>
                <td>{row.midLevel}</td>
                <td>{row.emerging}</td>
                <td>{row.early}</td>
                <td>{row.enthusiast}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.bottom_message}>
        <p>
          ALL OTHER ARTISTS WITH VOTES WILL SHARE IN THE $160,000 WORTH OF FANS
          TOKENS PRIZE POOL
        </p>
        <span>
          ALL AMOUNT SHOWN WILL BE PAID OUT IN AN EQUIVALENT VALUE OF FANS
          TOKENS
        </span>
      </div>
    </div>
  );
}
