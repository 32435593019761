import React, { useState } from "react";
import styles from "./Starting.module.scss";
import PurpleButton from "app/components/purple-button/PurpleButton";
import axios from "axios";
import { BrowserView, MobileView, isSafari } from "react-device-detect";
import MobileModalAlert from "app/components/onboard/mobile_modal_alert/MobileModalAlert";
import SafariAlert from "app/components/onboard/safari_alert/SafariAlert";
import { useHook } from "app/hooks/common";
import { isCreator } from "app/core/common";
import { Checkbox, FormControlLabel } from "@mui/material";
import { blue } from "@mui/material/colors";
import Button from "app/components/button/button";

export default function Starting({ setFlow, cookie }) {
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useHook();
  const handleChangeRole = async () => {
    try {
      /* if (!agree) {
        return;
      } */

      setLoading((prev) => !prev);

      if (isCreator(userInfo.role)) {
        setFlow(1);
        setLoading((prev) => !prev);
        return;
      }

      await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}arena/changeUserRole`,
        data: {
          cookie,
        },
      });
      setFlow(1);
      setLoading((prev) => !prev);
    } catch (error) {
      setLoading((prev) => !prev);
      console.error(error);
      setLoading((prev) => !prev);
    }
    //setFlow(1);
    return;
  };
  if (isSafari) {
    return (
      <>
        <SafariAlert />
      </>
    );
  }

  return (
    <>
      <MobileView>
        <MobileModalAlert />
      </MobileView>
      <BrowserView>
        <div className={styles.starting}>
          <div className={styles.middle_content}>
            <div className={styles.middle_img}>
              <h1>Let's get you started</h1>
            </div>
            <div className={styles.text}>
              <span>
                We will guide you step-by-step to complete setting up your
                Artist Loop - it takes just 4 minutes
              </span>
            </div>
          </div>

          <div>
            {/* <FormControlLabel
              label={
                <p>
                  I agree to the{" "}
                  <a
                    href="https://awards.loop.fans/Terms-of-service"
                    target="_blank"
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://awards.loop.fans/Privacy-Policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
              }
              control={
                <Checkbox
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  checked={agree}
                  onClick={() => setAgree(!agree)}
                  sx={{ color: "#fff" }}
                />
              }
            /> */}
          </div>
          <Button
            disabled={loading || !userInfo}
            className={styles.progress_button}
            onClick={() => {
              handleChangeRole();
            }}
          >
            {loading ? "Loading..." : "Start"}
          </Button>
        </div>
      </BrowserView>
    </>
  );
}
