import { useContext, useEffect, useRef, useState } from "react";
import styles from "./events.module.scss";

import { Link, useHistory, useLocation } from "react-router-dom";

import { DirectusImage, UserAlbums } from "app/core/common";
import * as api from "app/api/profile.api";
import Loader from "app/components/Loader";
import Button from "app/components/button/button";
import { truncate } from "app/helpers/helper";
import { set } from "numeral";
import { Trans } from "react-i18next";
import { useHook } from "app/hooks/common";
import Modal from "app/components/Modal";
import { t } from "i18next";
import NoContent from "app/components/NoContent";

export default function ProfileEvents({ creator }) {
  const { userInfo } = useHook();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const profile_id = location.pathname.split("/")[1];

  const [events, setEvents] = useState<any[]>([]);
  const [viewEvent, setViewEvent] = useState<Number>(null);
  const [transitionIn, setTransitionIn] = useState(false);

  // Fetch Events
  useEffect(() => {
    api.GetProfileEvents({ id: profile_id }).then((data) => {
      setEvents(data);
      setLoading(false);
    });
  }, [userInfo]);

  // Handle Event Click
  const handleEvent = (id: number) => {
    setTransitionIn(!transitionIn);

    setTimeout(() => {
      id === viewEvent ? setViewEvent(null) : setViewEvent(id);
    }, 500);
  };

  return (
    <>
      <div className={styles.event}>
        {loading ? (
          <Loader />
        ) : viewEvent ? (
          events
            ?.filter((item) => item.id === viewEvent)
            ?.map((item, index) => {
              const { endDate, endTime, mintPrice } =
                item.collection?.fans_launchpad_type[0]?.launchInfo;

              const endingDate = new Date(endDate + " " + endTime);

              const [price, symbol] = mintPrice?.split(" ") || ["", "USDC"];

              const collection_slug =
                item.collection?.fans_launchpad_type[0]?.launchpad_id
                  .project_slug;
              return (
                <div
                  key={item.event_name + index}
                  style={
                    !transitionIn
                      ? {
                          transition: "all 250ms ease-in-out",
                          opacity: 0,
                          transform: "translateX(-100%)",
                        }
                      : {
                          transition: "all 250ms ease-in-out",
                          opacity: 1,
                          transform: "translateX(0%)",
                        }
                  }
                >
                  <div className={styles.event_detail_view}>
                    <div
                      className={styles.back_button}
                      onClick={() => handleEvent(item.id)}
                    >
                      &#8249; Back to Events {/* <Trans>backToAlbums</Trans> */}
                    </div>

                    <div className={styles.image}>
                      <img src={`${DirectusImage(item.image?.id)}`} />
                    </div>

                    <div className={styles.detail}>
                      <div className={styles.right}>
                        <div className={styles.collection}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t("eventMessage", {
                                event_name: item.event_name,
                                creator_name: creator?.first_name,
                              }),
                            }}
                          />
                        </div>
                        <hr />
                        <Button
                          disabled={endingDate < new Date()}
                          className={styles.button}
                          onClick={() =>
                            history.push(`/collection/${collection_slug}`)
                          }
                        >
                          {endingDate < new Date()
                            ? t("eventSoldOut")
                            : price == 0
                            ? t("free")
                            : t("viewCollection")}
                        </Button>
                      </div>

                      <div className={styles.left}>
                        <div className={styles.section}>
                          <div>
                            <h1 className={styles.title}>{item.event_name}</h1>
                          </div>
                          <p className={styles.desc}>
                            {item.event_description}
                          </p>
                        </div>

                        <div className={styles.section}>
                          <div>
                            <h1 className={styles.title}>{t("eventDate")}</h1>
                          </div>
                          <p className={styles.desc}>
                            {new Date(item.start_date).toLocaleString()}
                          </p>
                        </div>

                        <div className={styles.section}>
                          <div>
                            <h1 className={styles.title}>
                              {t("eventLocation")}
                            </h1>
                          </div>
                          <p className={styles.desc}>{item.event_location}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
        ) : (
          <div className={styles.cards}>
            {events.length > 0 ? (
              events?.map((item, index) => {
                const { mintPrice } =
                  item.collection?.fans_launchpad_type[0]?.launchInfo;
                const [price, symbol] = mintPrice?.split(" ") || ["", "USDC"];

                return (
                  <div
                    key={item.event_name + index}
                    className={styles.card}
                    style={
                      transitionIn
                        ? {
                            transition: "all 250ms ease-in-out",
                            opacity: 0,
                            transform: "translateX(100%)",
                          }
                        : {
                            transition: "all 250ms ease-in-out",
                            opacity: 1,
                            transform: "translateX(0%)",
                          }
                    }
                    onClick={() => handleEvent(item.id)}
                  >
                    <img src={`${DirectusImage(item.image?.id)}`} />
                    <div className={styles.content}>
                      <h1 className={styles.title}>{item.event_name}</h1>
                      <p className={styles.desc}>
                        {new Date(item.start_date).toLocaleString()}
                      </p>
                      <p className={styles.desc}>{item.event_location}</p>

                      <p className={styles.price}>
                        {price == 0
                          ? `Free`
                          : `$${Number(price).toLocaleString()} ${symbol}`}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoContent message={t("noEventMessage", {user: creator?.first_name})} showButton={false} />
            )}
          </div>
        )}
      </div>
    </>
  );
}
