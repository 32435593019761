import React, { Component, useEffect, useState } from "react";
import styles from "./gallery.module.scss";
import { DirectusImage } from "app/core/common";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Gallery({
  launchpadNFT,
  index,
  imageIndex,
  setImageIndex,
  voteModal,
}: {
  launchpadNFT?: any;
  index?: number;
  imageIndex?: number;
  setImageIndex?: any;
  voteModal?: boolean;
}) {
  const [gallery, setGallery] = useState([]);

  const _gallery = [];
  const banner = launchpadNFT?.info?.banner;

  useEffect(() => {
    // Clear Gallery
    setGallery([]);
    // Create Gallery
    if (voteModal) {
      launchpadNFT?.launchpad_type[index]?.type_gallery?.map((r: any) => {
        _gallery.push({
          original: DirectusImage(r.directus_files_id.id),
          thumbnail: DirectusImage(r.directus_files_id.id),
        });
      });
    } else if (launchpadNFT?.info?.collection_type === "vote") {
      launchpadNFT?.info?.launchpad_type[index]?.type_gallery?.map((r: any) => {
        _gallery.push({
          original: DirectusImage(r.directus_files_id.id),
          thumbnail: DirectusImage(r.directus_files_id.id),
        });
      });
    } else {
      if (banner && banner?.id) {
        _gallery.push({
          original: DirectusImage(banner.id),
          thumbnail: DirectusImage(banner.id),
        });
      }
    }

    setGallery(_gallery);
  }, [launchpadNFT, index]);

  return (
    <div className={styles.block}>
      {gallery.length > 1 && (
        <div className={styles.slider}>
          {gallery?.map((image, index) => {
            return (
              <div
                className={styles.slider_img}
                onClick={() => setImageIndex(index)}
              >
                <LazyLoadImage
                  src={image?.thumbnail}
                  effect="blur"
                  wrapperClassName={styles.image}
                />
              </div>
            );
          })}
        </div>
      )}
      <div className={styles.daynamicSlider}>
        <div className={styles.activeImage}>
          <LazyLoadImage
            src={gallery[imageIndex]?.original}
            effect="blur"
            wrapperClassName={styles.image}
          />
        </div>
      </div>
    </div>
  );
}
