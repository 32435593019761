import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Button from "app/components/button/button";
import { useHistory, useLocation } from "react-router-dom";

import * as api from "app/api/courses.api";
import { DirectusImage, CourseItems } from "app/core/common";
import CourseList from "./CourseList";

function scrollToSection(anchor: string): void {
  const section = document.getElementById(anchor);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
}

export default function ProfileHeader() {
  const location = useLocation();
  const courseId = location.pathname.split("/")[2];
  const [course, setCourse] = useState<CourseItems>();

  useEffect(() => {
    api.getCourse({ id: courseId }).then((data) => {
      setCourse(data[0]);
    });
  }, []);


  return (
    <div className={styles.course}>
      {course ? (
        <>
          <div
            className={styles.header}
            style={{
              backgroundImage: `url(${DirectusImage(course.banner.id)})`,
            }}
          >
            <div className={styles.overlay} />
            <div className={styles.content}>
              <div className={styles.content}>
                <div className={styles.creator}>
                  <h3>{course?.creator.first_name}</h3>
                  <h5>{course?.title}</h5>
                  <div className={styles.desc}>{course?.description}</div>
                  <div className={styles.actions}>
                    <Button
                      className={styles.solidBtn}
                      onClick={() => scrollToSection("course")}
                    >
                      Start Course
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.body} id="course">
            <CourseList course={course} />
          </div>
        </>
      ) : (
        <div className="loader" />
      )}
    </div>
  );
}
