import styles from "./PreviewContainer.module.scss";
import OnBoardCover from "../onboard/onboard_cover_modal/OnBoardCover";
import { DefaultAvatar, DirectusImage } from "app/core/common";

export default function PreviewContainer({
  modal_picture_ref,
  imageProfile,
  imageCover,
  setImageCover,
  setImageCoverFinal,
  imageCoverFinal,
  infos,
  setImageCoverUpdate,
  showDetails,
}: {
  modal_picture_ref?: any;
  imageProfile?: any;
  imageCover?: any;
  setImageCover?: any;
  setImageCoverFinal?: any;
  imageCoverFinal?: any;
  infos?: any;
  setImageCoverUpdate?: any;
  showDetails?: any;
}) {
  return (
    <div className={styles.preview_container}>
      <div className={styles.preview_title}>
        <h1>Preview</h1>
        <span>This is what your Artist Loop will look like</span>
      </div>
      <div className={styles.preview}>
        <div className={styles.preview_cover}>
          <OnBoardCover
            imageCover={imageCover}
            setImageCover={setImageCover}
            setImageCoverFinal={setImageCoverFinal}
            imageCoverFinal={imageCoverFinal}
            setImageCoverUpdate={setImageCoverUpdate}
          />
        </div>
        <div className={styles.preview_profile_img}>
          <img
            ref={modal_picture_ref}
            src={imageProfile ? imageProfile : DirectusImage(DefaultAvatar)}
          />
        </div>
        {showDetails && (
          <div className={styles.preview_infos}>
            <h1>
              {!infos?.display_name ? "Display Name" : infos?.display_name}
            </h1>
            <span>
              {!infos?.username ? "@username" : `@${infos?.username}`}
            </span>
            <p>{!infos?.description ? "Short Bio" : infos?.description}</p>
          </div>
        )}
      </div>
    </div>
  );
}
