import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Link, useLocation, useParams } from "react-router-dom";

import * as api from "app/api/collections.api";
import { CurrencyRateType, IPFSImage, Redeemable } from "app/core/common";
import NFTInfo from "./NFTInfo";
import { Trans } from "react-i18next";
import NFTDesc from "./NFTDesc";
import Redeemables from "./Redeemables";
import {
  LaunchPadNFTQueryInfo,
  getRedeemablesForNft,
  queryLaunchpadNFTInfo,
} from "app/api/common.api";
import { useHook } from "app/hooks/common";
import Album_Benefits from "app/components/Benefits/Album_Benefits";
import Project_Video from "app/components/Benefits/projectVideo";
import File_Benefit from "app/components/Benefits/File_Benefit";
import { useNotification } from "app/context/notification";
import Project_Gallery from "../Collection/projectGallery";

export default function NftDetail() {
  const params = useParams<any>();
  const { address, cookie } = useHook();
  const [type, setType] = useState<number>(1);

  const [redeemables, setRedeemables] = useState<Redeemable[]>([]);
  const [showQrCodeModal, setShowQrCodeModal] = useState(
    Array(redeemables.length).fill(false)
  );

  const [nftAddress] = useState(params && params.address);
  const [nftTokenId] = useState(params && params.id);
  const [attributesCount, setAttributesCount] = useState<number[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [info, setInfo] = useState<LaunchPadNFTQueryInfo>();
  const [owner, setOwner] = useState<boolean>(false);
  const [collection, setCollection] = useState<boolean>(false);
  const [album, setAlbum] = useState(null);
  const [video, setVideo] = useState(null);
  const [file, setFile] = useState(null);
  const [albumCover, setAlbumCover] = useState(null);
  const [gallery, setGallery] = useState(null);

  const [hasBenefits, setHasBenefits] = useState<boolean>(false);

  // GET NFT DETAIL
  useEffect(() => {
    queryLaunchpadNFTInfo(nftAddress, nftTokenId)?.then(
      (result: LaunchPadNFTQueryInfo) => {
        if (result) {
          //setAttributesCount(result.metadata.attributes.map((_) => 0));
          setTotalCount(0);
          setInfo({
            ...result,
          });
          setOwner(result?.info?.owner === address);
        }
      }
    );
  }, [nftAddress, nftTokenId, address]);

  useEffect(() => {
    try {
      const fetchRedeemables = async () => {
        let redeemablesResult = await getRedeemablesForNft({
          contract: nftAddress,
          tokenId: nftTokenId,
          address: address,
          cookie: cookie,
        });
        //console.log("redeemablesResult", redeemablesResult);
        setRedeemables(redeemablesResult);
      };
      if (cookie && address && owner) {
        fetchRedeemables().catch((err) => console.log(err));
      }
    } catch (error) {
      console.error(error);
    }
  }, [info, nftTokenId, nftAddress, address, owner]);

/*   const { notificationEvent } = useNotification();
  useEffect(() => {
    notificationEvent({ transaction_type: "vote", subType: 2 });
  }, []); */

  useEffect(() => {
    if (
      info?.benefits?.collection_album[Number(type) - 1]?.id ||
      info?.benefits?.collection_files[Number(type) - 1]?.files?.[
        Number(type) - 1
      ]?.file?.id ||
      info?.benefits?.collection_video[Number(type) - 1]?.videos?.[
        Number(type) - 1
      ]?.video?.id
    ) {
      setCollection(true);
      setHasBenefits(true);
    }

    /* ================ */
    /* === Benefits === */
    /* ================ */
    // Album Cover
    if (info?.metadata?.image) {
      setAlbumCover(info?.metadata?.image);
    }

    // Albums
    if (info?.benefits?.collection_album) {
      setAlbum(info?.benefits?.collection_album ? Object.values(info?.benefits?.collection_album) : false);
      setHasBenefits(true);
    }

    // Gallery
    if (info?.benefits?.collection_gallery) {
      setGallery(info?.benefits?.collection_gallery?.gallery_items ? Object.values(info?.benefits?.collection_gallery?.gallery_items) : false);
      setHasBenefits(true);
    }

    // Videos
    if (info?.benefits?.collection_video) {
      // change object to array
      setVideo(info?.benefits?.collection_video ? Object.values(info?.benefits?.collection_video) : false);
      setHasBenefits(true);
    }

    // Files
    if (info?.benefits?.collection_files) {
      setFile( info?.benefits?.collection_files ? Object.values(info?.benefits?.collection_files) : false);
      setHasBenefits(true);
    }
  }, [info]);

  return (
    <>
      <div className={styles.nft_detail}>
        {/*
        <Link className={styles.back} to={`/${userInfo.id}`}>
          <img src="/back.svg" alt="back_icon" style={{ marginRight: "10px" }} />{" "}
          My NFTs
        </Link>
      */}
        {info ? (
          <>
            <div className={styles.launchViewHeader}>
              <div className={styles.launchViewLeft}>
                <div className={styles.nft_image}>
                  <img src={info && IPFSImage(info?.metadata?.image, true)} />
                </div>
              </div>
              <div className={styles.launchViewRight}>
                <NFTInfo
                  info={info}
                  hasRedeemables={redeemables.length > 0 || album || video || file}
                  owner={owner}
                  hasBenefits={hasBenefits}
                />
              </div>
            </div>

          {/* <NFTDesc info={info} /> */}
          {/* {redeemables.length > 0 && owner && <Redeemables redeemables={redeemables} />} */}
          <div id="benefits">
            {owner ? (
              <>
                {collection && (
                  <>
                    <h1 style={{ fontSize: "30px", marginLeft: "20px" }}>
                      Collection Benefits
                    </h1>
                  </>
                )}
                <Album_Benefits albums={album} albumCover={albumCover} />
                <Project_Gallery gallery={gallery} preview={false} />
                <Project_Video videos={video} />
                <File_Benefit files={file} />
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
    </>
  );
}
