import { Trans } from "react-i18next";
import Modal from "../Modal";
import styles from "./notification.module.scss";
import Confetti from "react-confetti";
import { useEffect } from "react";
import React from "react";

const InProgressError = ({ setTheme, title }: { setTheme: any , title?: string }) => {
  function onClose() {
    setTheme({
      type: undefined,
      subType: undefined,
    });
  }

  const [recycle, setRecycle] = React.useState(true);
  useEffect(() => {
    setTimeout(() => {
      setRecycle(false);
    }, 3500);
  }, []);

  return (
    <>
      <Confetti className="Confetti" recycle={recycle} />
      <Modal isOpen={true} title="" onClose={() => onClose()} zIndex={4} center>
        <div className={styles.notification_content}>
          <div className={styles.image_content}>
            <img src={"/confetti.svg"} alt="wait" />
            <p className={styles.title}>
              {title ?? <Trans>TransactionDelayTitle</Trans>}
            </p>
            <div style={{ textAlign: "center", marginBottom: "50px" }}>
              Your collectiable may take a moment to show up in your wallet.
            </div>
            <button onClick={() => onClose()}>CLOSE</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InProgressError;
