import axios from "axios";

export async function getCreatorCourses({ slug }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}courses/creator/${slug}`,
    method: "get",
    data: {},
  });

  return result.data;
}

export async function getCourse({ id }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}courses/${id}`,
    method: "get",
    data: {},
  });

  return result.data;
}

export async function getCourseIntro({ id }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}courses/intro/${id}`,
    method: "get",
    data: {},
  });

  return result.data;
}

export async function getCourseClass({ id }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}courses/class/${id}`,
    method: "get",
    data: {},
  });

  return result.data;
}