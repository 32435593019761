import React, { useEffect, useRef } from "react";
import Message from "./Message";
import styles from "./ConversationMessages.module.scss";
import { useHook } from "app/hooks/common";

export default function ConversationMessages({ messages }: { messages: any }) {
  const { userInfo } = useHook();
  const endRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (endRef.current) {
      endRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  return (
    <div className={styles.messages_container_1}>
      {/*container*/}
      <div className={styles.messages}>
        {/*messages*/}
        {messages &&
          messages.map((message: any, index) => (
            <Message
              message={message}
              key={index}
              me={userInfo?.id === message?.creator?.id}
              user={userInfo}
            />
          ))}
        <div style={{ height: "1px" }} ref={endRef}></div>
      </div>
    </div>
  );
}
