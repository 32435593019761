import Cookie from "js-cookie";
import { useUser } from "app/context/userInfo";
import { isCreator } from "app/core/common";

export const checkUsername = (username: string) => {
  return username.toLowerCase().replace(/[^a-zA-Z0-9_]/g, '');
}
  

export const useHook = () => {
  const cookie = Cookie.get("cookie");
  const address = Cookie.get("address");
  const {
    userInfo,
    checkingUserInfo,
    followingListLoaded,
    followingList,
    myNfts,
    myNFTsLoading,
    loadMoreNFTs,
    updateMyNFTs,
    updateFollowingList,
    updateUserInfo,
  } = useUser();
  const _isCreator = isCreator(userInfo?.role);

  return {
    address,
    cookie,
    userInfo,
    checkingUserInfo,
    followingListLoaded,
    followingList,
    isCreator: _isCreator,
    myNfts,
    myNFTsLoading,
    loadMoreNFTs,
    updateMyNFTs,
    updateFollowingList,
    updateUserInfo,
  };
};
