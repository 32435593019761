import { useContext } from 'react';
import axios from 'axios';
import { MINTER_ENDPOINT } from 'app/core/common';
import { useNotification } from "app/context/notification";

type TxProps = {
  collection_addr?: string;
  address?: string;
};

export default (props?: TxProps) => {
  const { notificationEvent } = useNotification();

  const MintNft = async ({
    cookie,
    collection_addr,
    address,
    redirectTo,
    mint_type,
    type_name
  }: {
    cookie,
    collection_addr: string;
    address: string;
    redirectTo: string;
    mint_type: string;
    type_name: string;
  }) => {
    try {
      if (!address || !collection_addr) {
        // Notification Error
        notificationEvent({ subType: 3 });
        return;
      } else {
        // Send Transaction
        // Notification Progress
        notificationEvent({ subType: 1 });
        const url = `${MINTER_ENDPOINT}minter/mint`;
        // const url = `${MINTER_ENDPOINT}/free_claim`;
        // console.log("Mint collection addr : ", minter);
        await axios({
          url,
          method: 'post',
          data: {
            type: "free_claim",
            query: {
              cookie: cookie,
              collection_addr: collection_addr,
              recipient: address,
              type_name: type_name,
            },
          },
        })
          .then((res: any) => {
            if (res.status === 200) {
              notificationEvent({
                subType: 2,
                transaction_type: type_name,
                txHash: res.data.tx_hash,
                redirectURL: redirectTo,
              });
              return;
            } else {
              notificationEvent({ subType: 3, txErrorMessage: res.message });
              return;
            }
          })
          .catch(err => {
            //return error
            notificationEvent({
              subType: 3,
              txErrorMessage: err.response.data,
            });
            return;
          });
      }
    } catch (error) { }
  };

  return {
    MintNft,
  };
};
