import React, { useEffect, useState } from "react";
import styles from "./InboxMessageScreen.module.scss";
import { useHook } from "app/hooks/common";
import { DirectusImage } from "app/core/common";
import ConversationInput from "app/components/conversation_input/ConversationInput";
import ConversationMessages from "app/components/conversations_messages/ConversationMessages";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import useScreenSize from "app/helpers/useScreenSize";
import { set } from "numeral";

interface InboxMessageScreenProps {
  selectedConversationInfos: any;
  selectedConversation: string;
  setSelectedConversation: any;
  setSelectedConversationInfos: any;
}

export default function InboxMessageScreen({
  selectedConversationInfos,
  selectedConversation,
  setSelectedConversation,
  setSelectedConversationInfos,
}: {
  selectedConversation: string;
  selectedConversationInfos: any;
  setSelectedConversation: any;
  setSelectedConversationInfos: any;
}) {
  const { userInfo, cookie } = useHook();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const screenSize = useScreenSize();
  const me = userInfo?.id;
  const receiver = selectedConversationInfos?.inboxInfo[0]?.receiver?.id;
  const sender = selectedConversationInfos?.inboxInfo[0]?.sender?.id;
  const title = selectedConversationInfos?.inboxInfo[0]?.title;
  const convoId = selectedConversation;
  /* ======================== */
  /* show the picture of the other user */
  /* ======================= */
  const showpicture = me === receiver ? sender : receiver;

  useEffect(() => {
    setMessages([]);
    setSelectedConversationInfos(null);
    /* setLoading(true); */
    /* setSelectedConversation(null); */
  }, [convoId]);

  useEffect(() => {
    setMessages(selectedConversationInfos?.messages);
  }, [selectedConversationInfos]);

  const handleMessage = (text:string) => {
    const newText = text;
    setMessage(newText);
  };

  const sendMessageHandler = async () => {
    if (message.length > 0) {
      try {
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/inbox/createMessage`,
          data: { cookie, inbox_id: convoId, message },
          headers: {
            "Content-Type": "application/json",
          },
        });
        setMessages((prevMessages: any) => [
          ...prevMessages,
          {
            message: message,
            creator: { id: userInfo.id },
          },
        ]);
        setMessage("");
        setLoading(false);
      } catch (error) {
        setMessage("");
        setLoading(false);
        console.error(error);
      }
    } else {
      return;
    }
  };
  return (
    <div className={styles.messages_cont}>
      <div className={styles.message_header}>
        <div className={styles.header_infos}>
          {screenSize.width < 600 ? (
            <ArrowBackIcon
              onClick={() => {
                setSelectedConversationInfos(null);
                setSelectedConversation(null);
              }}
            />
          ) : null}
          <div className={styles.conversation_image} hidden={!selectedConversationInfos}>
            <img
              src={DirectusImage(
                showpicture === sender
                  ? selectedConversationInfos?.inboxInfo[0]?.sender?.avatar
                  : selectedConversationInfos?.inboxInfo[0]?.receiver?.avatar
              )}
              alt="loop-artist-convo"
            />
          </div>
          <div className={styles.conversation_name}>
            <h1>
              {showpicture === sender
                ? selectedConversationInfos?.inboxInfo[0]?.sender?.display_name
                : selectedConversationInfos?.inboxInfo[0]?.receiver
                    ?.display_name}
            </h1>
            {/* <small>Started by: {selectedConversationInfos?.inboxInfo[0]?.sender?.display_name ?? selectedConversationInfos?.inboxInfo[0]?.sender?.first_name}</small> */}
          </div>
        </div>
      </div>
      {/*chat_messeges*/}
      <ConversationMessages messages={messages} />
      {/*chat_actions*/}
      <ConversationInput
        message={message}
        handleMessage={handleMessage}
        loading={loading}
        sendMessageHandler={sendMessageHandler}
        selectedConversationInfos={selectedConversationInfos}
      />
    </div>
  );
}
