import React, { useCallback, useEffect, useRef, useState } from "react";
import { DirectusImage } from "app/core/common";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import styles from "../ModalProfile.module.scss";
import getCroppedImg from "app/helpers/getCroppedImg";
import Cropper from "react-easy-crop";
import PulseLoader from "react-spinners/PulseLoader";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { red } from "@mui/material/colors";

export default function ModalCover({ profileData, setImageCover }) {
  const [showCoverMenu, setshowCoverMenu] = useState(false);
  const [coverPicture, setCoverPicture] = useState(null);
  const coverpopup = useRef(null);
  const refInput = useRef(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const cRef = useRef(null);
  // const [coverImage, setCoverImage] = useState(
  //   profile?.cover ? profile?.cover : ""
  // );
  const [height, setHeight] = useState();

  const handleImage = (e) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setCoverPicture(event.target.result);
    };
  };
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setcroppedAreaPixels] = useState(null);

  const coverRef = useRef(null);
  const [width, setWidth] = useState();

  useEffect(() => {
    setWidth(coverRef.current.clientWidth);
    setHeight(coverRef.current.clientHeight);
  }, [window.innerWidth]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setcroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImage = useCallback(
    async (show) => {
      try {
        const img = await getCroppedImg(coverPicture, croppedAreaPixels);
        if (show) {
          setZoom(1);
          setCrop({ x: 0, y: 0 });
          setCoverPicture(img);
        } else {
          return img;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [croppedAreaPixels, coverPicture]
  );

  const updateCoverPicture = async () => {
    try {
      setLoading(true);
      let img = await getCroppedImage( false);

      if (img) {
        const imageUrl = URL.createObjectURL(img);
        cRef.current.style.backgroundImage = `url(${imageUrl})`;
        setImageCover(img);
        setLoading(false);
        setCoverPicture("");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.error);
    }
  };

  return (
    <div className={styles.profile_cover} ref={coverRef}>
      {coverPicture && (
        <div className={styles.save_changes_cover}>
          <div className={styles.save_changes_left}>
            <div
              className={styles.open_cover_update}
              onClick={() => {
                setCoverPicture("");
                setImageCover("");
              }}
            >
              <ClearIcon fontSize="small" sx={{ color: red[500] }} />
            </div>
          </div>
          <div
            className={styles.open_cover_update}
            onClick={() => updateCoverPicture()}
          >
            <button className="blue_btn" style={{ padding: "4px 8px" }}>
              {loading ? (
                <PulseLoader color="white" size={5} />
              ) : (
                <CheckIcon color="success" fontSize="small" />
              )}
            </button>
          </div>
        </div>
      )}
      {coverPicture && (
        <div className="cover_crooper">
          <Cropper
            image={coverPicture}
            crop={crop}
            zoom={zoom}
            aspect={(width ?? 1) / (height ?? 1)}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={true}
            objectFit="horizontal-cover"
          />
        </div>
      )}

      <div
        className={styles.edit_profile_cover}
        style={{
          backgroundImage: `url(${DirectusImage(profileData?.background?.id)})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        ref={cRef}
      >
        {coverPicture ? (
          ""
        ) : (
          <div
            className={styles.camara_top}
            onClick={() => refInput.current.click()}
          >
            <input
              type="file"
              ref={refInput}
              hidden
              accept="image/jpeg, image/png, image/webp, image/jfif"
              onChange={handleImage}
            />

            <AddAPhotoOutlinedIcon />
          </div>
        )}
      </div>
    </div>
  );
}
