import { useEffect, useRef } from "react";
import { DirectusImage } from "app/core/common";
import styles from "./index.module.scss";
import { Trans } from "react-i18next";

const Project_Video = ({
  creator,
  videos,
  collection,
}: {
  creator?: string;
  videos?: any;
  collection?: boolean;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const videoElement = videoRef.current;
    let timeout: NodeJS.Timeout;

    const handlePlay = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (videoElement) {
          videoElement.pause();
          videoElement.currentTime = 0;
        }
      }, 5000); // 5 seconds
    };

    if (videoElement && collection) {
      videoElement.addEventListener("play", handlePlay);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("play", handlePlay);
        clearTimeout(timeout);
      }
    };
  }, [videos, collection]);

  useEffect(() => {
    // sort albums by order
    if (videos?.length > 1) {
      videos.sort((a: any, b: any) => a.order - b.order);
    }
  }, [videos]);

  return (
    videos && (
      <div className={styles.fan_media}>
        {/* <video ref={videoRef} src={DirectusImage(video_id)} controls /> */}
        {videos?.map((video: any, index: number) => (
          <div style={{margin: "50px 0"}}>
            <div
              className={styles.info_header}
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <h6>{video?.name}</h6>
              {collection ? (
                <span
                  style={{
                    background: "#22213d",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    fontWeight: "300",
                    fontSize: "14px",
                  }}
                >
                  Preview
                </span>
              ) : null}
            </div>

            <div className={styles.video_preview}>
              <video ref={videoRef} src={DirectusImage(video?.videos[0]?.video?.id)} controls />
            </div>
          </div>
        ))}
        <hr />
      </div>
    )
  );
};

export default Project_Video;
