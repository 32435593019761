import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Header.module.scss";
//import logo from "../assets/logo.svg";
import logo from "../assets/fansLogo.png";
import caret from "../assets/caret.svg";
import Button from "app/components/button/button";
import { useWallet } from "cloud-social-wallet";
import { creatorType, DefaultAvatar } from "app/core/common";
import { useHook } from "app/hooks/common";
import { Trans } from "react-i18next";
import { DirectusImage } from "../app/core/common";
import { truncate } from "app/helpers/helper";
import { useLogin } from "app/context/loginProvider";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from "@mui/icons-material/Email";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LogoutIcon from '@mui/icons-material/Logout';
import Banner from "app/components/banner";
import { includes } from "ramda";

type HeaderProps = {
  noheader: boolean;
};

export default function Header({ noheader }: HeaderProps) {
  const { logout } = useWallet();
  const { userInfo } = useHook();
  const location = useLocation();
  const [notiDrawer, setNotiDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const {
    showSignup,
    toggleLoginModal,
    toggleSignupModal
  } = useLogin();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const nobanner = location.pathname === "/";

  const origin = window.location.origin;
  const pathname = window.location.pathname;
  const inbox = {
    name: "Inbox",
    to: `/inbox/?tab=inbox`,
    icon: origin + "/assets/icons/inbox",
    auth: true,
    type: "page",
    tab: ["inbox"],
  };

  {
    /* add more actions here... */
  }
  const actions = [
    {
      name: "Profile",
      icon: <AccountCircleIcon fontSize="small" />,
      to: `/user/${
        userInfo?.username === "null"
          ? userInfo?.id
          : userInfo?.username !== null
          ? userInfo?.username
          : userInfo?.id
      }/`,
    },
    {
      name: "Wallet",
      icon: <WalletIcon fontSize="small" />,
      to: `/user/${
        userInfo?.username === "null"
          ? userInfo?.id
          : userInfo?.username !== null
          ? userInfo?.username
          : userInfo?.id
      }?tab=nfts`,
    },
    {
      name: "Inbox",
      icon: <EmailIcon fontSize="small" />,
      to: `/inbox/?tab=inbox`,
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <div className="container">
          {!nobanner && <Banner />}
          <div className={styles.items}>
            <div className={styles.left}>
              <div className={styles.logo_section}>
                <div className={styles.logo}>
                  <Link to="/">
                    <img src={logo} className={styles.logo} alt="logo" />
                    {/* <span>FANS</span> */}
                  </Link>
                </div>
              </div>
              {noheader ? null : (
                <div>
                  <ul className={styles.nav}>
                    <li>
                      <a
                        href="https://home.loop.fans/launch"
                        target="_blank"
                        className={styles.artistSignup}
                      >
                        Artist Launch
                      </a>
                    </li>
                    <li>
                      <a href="https://home.loop.fans/blog" target="_blank">
                        Blog
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://awards.loop.fans/" target="_blank">
                        Music Awards
                      </a>
                    </li>
                    <li>
                      <a href="https://home.loop.fans" target="_blank">
                        What is Loop?
                      </a>
                    </li>
                    <li>
                      <a href="https://home.loop.fans/web3" target="_blank">
                        What is Web3?
                      </a>
                    </li> */}
                    {/* <li>
                      <a href="https://create.loop.fans/create" target="_blank">
                        AI Music Tools
                      </a>
                    </li> */}
                    {/* <li><a href="https://www.loop.fans/clubs">VIP Clubs</a></li> */}
                  </ul>
                </div>
              )}
            </div>

            <div className={styles.right}>
              {/* {noheader ? null : (
                <div className={styles.actions}>
                  <LanguageSelector />
                </div>
              )} */}
              {noheader ? null : (
                <>
                  {userInfo ? (
                    <div
                      className={styles.profile}
                    >
                      <a
                        href="https://studio.loop.fans"
                        target="_blank"
                        style={{ color: "lightgray", textDecorationLine: "none"}}
                        hidden={creatorType(userInfo?.role) != "Artist" && creatorType(userInfo?.role) != "Developer"}
                      >
                        <div
                          className={styles.button}
                        >
                          Studio

                          {showTooltip && (
                            <div className={styles.tooltip}>
                              <span>Inbox</span>
                            </div>
                          )}
                        </div>
                      </a>

                      {/* <Link
                        to={`/user/${
                          userInfo?.username ?? userInfo?.id
                        }?tab=nfts`}
                        style={{ color: "lightgray" }}
                      >
                        <div
                          className={styles.icon}
                        >
                          <WalletIcon fontSize="small" />

                          {showTooltip && (
                            <div className={styles.tooltip}>
                              <span>Inbox</span>
                            </div>
                          )}
                        </div>
                      </Link> */}

                      <img
                        src={
                          userInfo?.avatar
                            ? DirectusImage(userInfo?.avatar)
                            : DirectusImage(DefaultAvatar)
                        }
                        alt="loop-user-picture"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                      />

                      {dropdownOpen && (
                        <div className={styles.dropdown_content}>
                          {actions.map((action) => {
                            return (
                              <>
                                <Link
                                  to={action.to}
                                  onClick={() => setDropdownOpen(false)}
                                >
                                  {action?.icon}
                                  {action.name}
                                </Link>
                              </>
                            );
                          })}
                          <Link
                            to="#"
                            onClick={() => {
                              logout();
                              //window.Bugpilot.logout();
                              window.location.reload();
                            }}
                          >
                            <LogoutIcon fontSize="small" />
                            Logout
                          </Link>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      {noheader ? null : (
                        <>
                          <Button
                            className={styles.loginButton}
                            onClick={() => {
                              toggleLoginModal();
                              toggleSignupModal(true);
                            }}
                          >
                            Login
                          </Button>
                          <Button
                            className={styles.signupButton}
                            onClick={() => {
                              toggleLoginModal();
                              toggleSignupModal(true);
                            }}
                          >
                            Join Now
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
