import { useEffect, useRef } from "react";
import styles from "./StepSix.module.scss";
import OnBoardNft from "app/components/onboard/onboard_nft_image/OnBoardNft";
import { toSvg } from "html-to-image";

type StepSixProps = {
  infos?: {
    display_name: string;
  };
  imageProfile?: string;
  loading: boolean;
  supportUploadProgress: number;
  setSupportNft: any;
};

export default function StepSix({
  infos,
  imageProfile,
  loading,
  supportUploadProgress,
  setSupportNft,
}: StepSixProps) {
  const elementRefs = useRef([]);
  const elementRef = useRef(null);
  // Array of images data
  const images = [
    {
      id: 1,
      src: "https://loop-markets.directus.app/assets/3c95f713-c1a3-408f-8d7e-37ab150d1d02",
      price: 5,
    },
    {
      id: 2,
      src: "https://loop-markets.directus.app/assets/7debe89e-bbc2-40ca-9926-655b3515f96f",
      price: 25,
    },
    {
      id: 3,
      src: "https://loop-markets.directus.app/assets/c09f16eb-9e7b-46d5-86de-93c635556782",
      price: 100,
    },
  ];
  // Function to convert data URL to file
  const convertDataUrlToFile = async (dataUrl, fileName) => {
    try {
      const response = await fetch(dataUrl);
      const blob = await response.blob();

      // Create a file from the Blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      console.error(error);
      throw new Error("Failed to convert data URL to file.");
    }
  };
  // useEffect hook to convert HTML elements to SVG images
  useEffect(() => {
    // Convert each image separately to SVG
    const htmlToImageConvert = async () => {
      const svgFiles = await Promise.all(
        elementRefs.current.map(async (elementRef, index) => {
          try {
            const dataUrl = await toSvg(elementRef, {
              cacheBust: false,
              style: styles,
            });

            const fileName = `nft_image_${index + 1}.svg`; // Provide a suitable file name
            const svgFile = await convertDataUrlToFile(dataUrl, fileName);

            return svgFile;
          } catch (error) {
            console.error(error);
            return null;
          }
        })
      );

      // Set the support NFT with the SVG files
      setSupportNft(svgFiles.filter((file) => file !== null));
    };

    htmlToImageConvert();
  }, []);
  return (
    <div className={styles.step_six}>
      <div className={styles.step_six_top}>
        <h1>Good Vibes NFTs</h1>
        <span>
          Good Vibes NFTs allow you to receive tips from fans.
          <br />
          We created 3 tiers below to help you get going!
        </span>
      </div>

      {loading ? (
        <div className={styles.progress_bar}>
          <progress
            className={styles.progresss}
            value={supportUploadProgress}
            max="100"
          />
          <span>Creating your Good Vibe NFTs</span>
        </div>
      ) : null}

      <div className={styles.step_six_nfts_support}>
        {images.map((image, index) => (
          <div className={styles.nft_card_complete} key={image.id}>
            <div className={styles.nftcard}>
              <OnBoardNft
                nftBackGround={image.src}
                price={image.price}
                key={image.id}
                infos={infos}
                imageProfile={imageProfile}
                elementRef={(el) => (elementRefs.current[index] = el)}
              />
            </div>
            <div className={styles.nft_price}>
              <span>${image.price}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
