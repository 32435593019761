import React from "react";
import styles from "./PurpleButton.module.scss";
import PulseLoader from "react-spinners/PulseLoader";

type PurpleButtonProps = {
  text: string;
  loading?: boolean;
  disabled?: boolean;
};

export default function PurpleButton({ text, disabled, loading } : PurpleButtonProps) {
  return (
    <button
      className={styles.purple_button}
      disabled={loading || disabled}
    >
      <span>{loading ? <PulseLoader color="#887dea" size={10} /> : text}</span>
    </button>
  );
}
