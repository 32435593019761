import styles from "./StepTwo.module.scss";
import OnboardInput from "app/components/onboard/onboard_input/OnboardInput";
import PreviewContainer from "app/components/preview_container/PreviewContainer";
import { useHook } from "app/hooks/common";
import { use } from "i18next";
import { useEffect, useState } from "react";

export default function StepTwo({
  imageProfile,
  imageCoverFinal,
  handleChange,
  infos,
  setReady,
  handleChangeDisplayUsername,
  notAvailable,
}) {
  const [error, setError] = useState(null);
  const { userInfo } = useHook();

  // if the display name and username length is more than five, update them
  useEffect(() => {
    if (infos?.display_name && infos?.username.length) {
      setReady(true);
    } else if (userInfo?.username && userInfo?.display_name) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [
    infos.username,
    infos.display_name,
    userInfo.username,
    userInfo.display_name,
  ]);

  return (
    <div className={styles.step_two}>
      <div className={styles.step_two_top}>
        <h1>Setup your Display Name and username</h1>
        <hr />
        <div className={styles.step_two_input}>
          <div className={styles.step_two_input_input}>
            <div className={styles.input_complete}>
              <span>Display Name</span>
              <OnboardInput
                infos={infos}
                name="display_name"
                placeholder={"Display Name"}
                handleChange={handleChange}
              />
              {infos.display_name.length < 5 ? (
                <span
                  style={{
                    fontSize: "12px",
                    color: "#5c5c5c",
                    position: "absolute",
                    bottom: "-40px",
                  }}
                >
                  Display Name: must have between 5 and 20 characters
                </span>
              ) : null}
            </div>

            <div className={styles.input_complete}>
              <span>Username</span>
              <OnboardInput
                placeholder={"Username"}
                infos={infos}
                name="username"
                handleChange={handleChange}
              />
              {notAvailable !== 200 ? (
                <span
                  style={{
                    fontSize: "12px",
                    color: "red",
                    position: "absolute",
                    bottom: "-40px",
                  }}
                >
                  Username not available
                </span>
              ) : null}
              {infos.username.length < 5 ? (
                <span
                  style={{
                    fontSize: "12px",
                    color: "#5c5c5c",
                    position: "absolute",
                    bottom: "-40px",
                  }}
                >
                  Username must have between 5 and 20 characters
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <PreviewContainer
        infos={infos}
        imageProfile={imageProfile}
        imageCoverFinal={imageCoverFinal}
        showDetails={true}
      />
    </div>
  );
}
