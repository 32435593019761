import React, { useEffect, useState } from "react";
import { useHook } from "app/hooks/common";
import {
  CurrencyRateType,
  LaunchPadNFT,
  NFTMinterQuery,
} from "app/core/common";
import styles from "./voteModal.module.scss";
import * as api from "app/api/collections.api";
import CloseIcon from "@mui/icons-material/Close";
import Gallery from "app/components/ImageGallery/gallery";
import VotingSoonForm from "app/pages/Collection/votingSoon.form";
import formStyle from "app/pages/Collection/index.module.scss";
import VoteNowForm from "app/pages/Collection/voteNowForm.form";

export default function VoteModal({
  queryResult,
  collection,
  index,
  setVoting,
}: {
  queryResult?: NFTMinterQuery;
  currencyRate?: CurrencyRateType;
  index?: Number;
  collection?: any;
  setVoting?: any;
}) {
  const [loaded, setLoaded] = useState(false);
  const launchpadName = collection[0]?.info?.id;
  const [type, setType] = useState<number>(1);
  const [imageIndex, setImageIndex] = useState(0);
  const [launchpadNFT, setLaunchpadNFT] = useState<LaunchPadNFT>();
  const [price, symbol] = launchpadNFT?.info?.launchpad_type[
    Number(type) - 1
  ]?.launchInfo.mintPrice?.split(" ") || ["", "USD"];
  const [currencyRate, setCurrencyRate] = useState<CurrencyRateType>(null);

  const launchpad_imageData =
    launchpadNFT?.info?.launchpad_type[Number(type) - 1]?.type_gallery?.[
      imageIndex
    ]?.directus_files_id;
  const collection_desc =
    launchpadNFT?.info?.launchpad_type[Number(type) - 1]?.collection
      ?.description;
  const mintableCount =
    launchpadNFT?.info?.launchpad_type[Number(type) - 1].launchInfo?.mint_limit;
  const contractAddr =
    launchpadNFT?.info?.launchpad_type[Number(type) - 1]?.fan_collection
      ?.address;

  useEffect(() => {
    if (!launchpadNFT && !loaded) {
      api
        .FetchLaunchPad(launchpadName)
        .then((response) => setLaunchpadNFT(response.info))
        .then(() => setLoaded(true));
    }
  }, [launchpadNFT, launchpadName, loaded]);

  // Get Currency Rate
  useEffect(() => {
    if (launchpadNFT) {
      api
        .FetchCurrencyRate({ code: symbol === "COP" ? "USD" : "COP" })
        .then((currency) => setCurrencyRate(currency));
    }
  }, [launchpadNFT]);

  const { address } = useHook();
  // const [price, symbol] = launchpadNFT?.launchpad_type[
  //   Number(index)
  // ]?.launchInfo.mintPrice?.split(" ") || ["", "USDC"];

  return (
    <>
      <div
        className={styles.voteModalBlur}
        onClick={() => setVoting((prev) => !prev)}
      />
      <div className={styles.voteModal}>
        <div className={styles.header}>
          <div
            className={styles.header_close}
            onClick={() => setVoting((prev) => !prev)}
          >
            <CloseIcon />
          </div>
        </div>

        {/* Show Loader */}
        {!loaded && <div className="loader" />}

        <div className={styles.launchpad}>
          <div className={styles.launchViewHeader}>
            <div className={styles.launchViewLeft}>
              <Gallery
                launchpadNFT={launchpadNFT}
                index={Number(type) - 1}
                imageIndex={imageIndex}
                setImageIndex={setImageIndex}
                voteModal
              />
            </div>
            <div
              className={`${formStyle.launchViewRight} ${styles.launchViewRight}`}
            >
              {/* Vote Soon */}
              {launchpadNFT?.collection_type == "vote" &&
                (launchpadNFT?.project_status == "live" ? (
                  <VoteNowForm
                    launchpadNFT={launchpadNFT}
                    currencyRate={currencyRate}
                    launchpad_imageData={launchpad_imageData}
                    index={Number(type) - 1}
                  />
                ) : (
                  <VotingSoonForm
                    launchpadNFT={launchpadNFT}
                    currencyRate={currencyRate}
                    launchpad_imageData={launchpad_imageData}
                    index={Number(type) - 1}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
