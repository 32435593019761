import Approval from './Approval';
import InProgress from './InProgress';
import Complete from './Complete';
import Fail from './Fail';
import { NotificationContext } from 'app/context/notification';

const NotificationModals = () => (
  <NotificationContext.Consumer>
    {({ theme, setTheme }) => {
      if (theme.type === 0 && theme.subType === 0)
        return <Approval setTheme={setTheme} />;
      else if (theme.type === 0 && theme.subType === 1)
        return <InProgress setTheme={setTheme} title="Processing" />;
      else if (theme.type === 0 && theme.subType === 2)
        return (
          <Complete
            setTheme={setTheme}
            transaction_type={theme.transaction_type}
            txHash={theme.txHash}
            redirectURL={theme.redirectURL}
            txLogs={theme.txLogs}
            transactionInfo={theme.transactionInfo}
            
          />
        );
      
        else if (theme.type === 0 && theme.subType === 3)
          return <Fail setTheme={setTheme} message={theme.txErrorMessage} />;
        else if (theme.type === 0 && theme.subType === 4)
          return <InProgress setTheme={setTheme} title="Casting your vote" />;
        else if (theme.type === 0 && theme.subType === 5)
          return <Fail setTheme={setTheme} message={theme.txErrorMessage} />;
      else return <></>;
    }}
  </NotificationContext.Consumer>
);

export default NotificationModals;
