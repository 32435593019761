import { Grid } from "semantic-ui-react";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./EarnPoints.module.scss";
import Tabs from "app/components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { getParam, setParam } from "app/helpers/helper";
import { set } from "numeral";
import { isCreator } from "app/core/common";
import { useHook } from "app/hooks/common";

export default function EarnPoints() {
  const { userInfo } = useHook();
  const [tab, setTab] = useState(getParam("tab"));
  useEffect(() => {
    if (!tab) {
      setParam("tab", "earnpoints");
      setTab("earnpoints");
    }
  }, [tab]);

  return (
    <div className={styles.clubs}>
      <div className={styles.container}>
        <Grid columns="one" divided>
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.column}>
              <h3>Earn Points</h3>
              <p className={styles.subHeading}>
                Track your actions and earn points to win exclusive prizes.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* <Tabs
          tabs={[
          {
            name: "Earn Points",
            slug: "earnpoints",
          },
          {
            name: "Leaderboard",
            slug: "leaderboard",
          },
        ]}
        setTab={setTab} /> */}
        {/* {tab === "earnpoints" && isCreator(userInfo?.role) ? (
          <iframe
            className={`${styles.gleamWidget}`}
            src="https://sweepwidget.com/c/80641-4xy6cbvw"
            frameBorder="0"
          ></iframe>
        ) : (
          <iframe
            className={`${styles.gleamWidget}`}
            src="https://gleam.io/rr5jk/win-fans-tokens" 
            frameBorder="0"
          ></iframe>
        )} */}
        {/* {tab === "leaderboard" && <iframe className={`${styles.gleamWidget} ${styles.gleamLeaderboard}`} src="https://gleam.io/wWht1/leaderboard" frameBorder="0"></iframe>} */}
        {/* <div data-gallery-embed="QEXM2" /> */}
        <iframe
          className={`${styles.gleamWidget}`}
          src="https://gleam.io/rr5jk/win-fans-tokens" 
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
}
