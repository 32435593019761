import GlobalsTranslation from "./globals";

// combine language objects
function combineObjects(dataArray) {
  const combinedObject = { en: { translations: {} }, es: { translations: {} } };
  dataArray.forEach((item) => {
    // Merge English (en) objects
    Object.keys(item.en.translations).forEach((key) => {
      combinedObject.en.translations[key] = item.en.translations[key];
    });

    // Merge Spanish (es) objects
    Object.keys(item.es.translations).forEach((key) => {
      combinedObject.es.translations[key] = item.es.translations[key];
    });
  });

  return combinedObject;
}

const transConfigs = combineObjects([
  ...GlobalsTranslation,
]);
//console.log(transConfigs)

export default transConfigs;
