import React, { useState } from "react";
import styles from "./ModalLinkSelector.module.scss";
import { Placeholder } from "semantic-ui-react";
import soundCloud from "../../../../../assets/icons/logo-soundcloud.svg";
import spotify from "../../../../../assets/icons/spotify.svg";
import apple from "../../../../../assets/icons/apple.svg";
import instagram from "../../../../../assets/icons/instant-camera-2.svg";
import youtube from "../../../../../assets/icons/logo-youtube.svg";
import tiktok from "../../../../../assets/icons/tiktok.svg";
import facebook from "../../../../../assets/icons/logo-fb-simple.svg";
import x from "../../../../../assets/icons/twitter.png";
import web from "../../../../../assets/icons/url.svg";

export default function ModalLinkSelector({
  socials,
  handleSocialChange,
}: {
  socials?: any;
  handleSocialChange?: any;
}) {
  const socials_links = [
    { name: "Spotify", icon: spotify },
    { name: "Soundcloud", icon: soundCloud },
    { name: "apple", icon: apple },
    { name: "Instagram", icon: instagram },
    { name: "YouTube", icon: youtube },
    { name: "TikTok", icon: tiktok },
    { name: "Facebook", icon: facebook },
    { name: "Twitter", icon: x },
    { name: "custom_link", icon: web },
  ];

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateUrl = (name: string, value: string) => {
    if (value.length > 0 && !value.startsWith("https")) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "URL must start with 'https'",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  return (
    <div className={styles.link_socials}>
      <span>Social Accounts</span>
      {socials_links.map((social, index) => (
        <div className={styles.link} key={index}>
          <div className={styles.link_firts_part}>
            <div className={styles.link_icon}>
              <img src={social.icon} alt="" />
            </div>
            <div className={styles.link_input}>
              <input
                type="url"
                pattern="https://.*"
                placeholder={
                  social.name === "Twitter"
                    ? "X link"
                    : social.name === "custom_link"
                    ? "Website link"
                    : social.name === "apple"
                    ? "Apple Music link"
                    : `${social.name} link`
                }
                onChange={(e) => {
                  handleSocialChange(e);
                  validateUrl(e.target.name, e.target.value);
                }}
                name={social.name}
                value={socials?.[social.name.toLowerCase()]}
              />
            </div>
          </div>
          {errors[social.name] && (
            <span className={styles.error}>{errors[social.name]}</span>
          )}
        </div>
      ))}
    </div>
  );
}
