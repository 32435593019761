import { useEffect, useState } from "react";
import { Card, Grid } from "semantic-ui-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./club.module.scss";
import axios from "axios";
import { truncate } from "app/helpers/helper";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { DirectusImage } from "app/core/common";
import { set } from "numeral";
import { on } from "process";
import Input from "app/components/input/input";

export default function ClubView() {
  const [fetching, setFetching] = useState(false);
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [endOfPage, setEndOfPage] = useState(false);

  /* ======================== */
  // Search Function
  /* ======================== */
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchPage, setSearchPage] = useState(1);
  const [searchEndOfPage, setSearchEndOfPage] = useState(false);

  const handleSearch = (text) => {
    setSearch(text);
    setSearchPage(1);
    setSearchResults([]);
  };

  useEffect(() => {
    // If a search is already in progress, exit early
    if (searching || search.length === 0) {
      setSearching(false);
      setLoading(false);
      return;
    } 
    
    // Start the search
    setSearching(true);
    setLoading(true);
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE}fans/creators`,
          {
            headers: {
              "Content-Type": "application/json",
              page: searchPage,
              limit: 15,
              searchterm: search,
            },
          }
        );
        const data = await response.data;

        setSearchResults([...data]);
        setLoading(false);

        // no more data
        if (data.length === 0) {
          setSearchEndOfPage(true);
        }
      } catch (err) {
        setLoading(false);
      } finally {
        setSearching(false); // Search has finished
      }
    }
    if (!loading) {
      fetchData();
    }
  }, [search, searchPage]);

  useEffect(() => {
    if (fetching) return; // If a fetch is already in progress, exit early
    setFetching(true);
    setLoading(true);
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE}fans/creators`,
          {
            headers: {
              "Content-Type": "application/json",
              page: page,
              limit: 15,
            },
          }
        );
        const data = await response.data;

        setCreators((prev) => [...prev, ...data]);
        setLoading(false);

        // no more data
        if (data.length === 0) {
          setEndOfPage(true);
        }
      } catch (err) {
        setLoading(false);
      } finally {
        setFetching(false); // Fetch has finished
      }
    }
    if (!loading) {
      fetchData();
    }
  }, [!creators, page]);

  /**********************/
  // scroll event callback function
  /**********************/
  const onScroll = () => {
    const { scrollTop, scrollHeight } = document.documentElement;
    if (
      !loading &&
      !fetching &&
      !endOfPage &&
      window.innerHeight + scrollTop >= scrollHeight - 100
    ) {
      search.length == 0 && setPage((prev) => prev + 1); // Increment page number
    }
  };

  /**********************/
  // Scroll Event
  /**********************/
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  const CreatorList = (creators: any) => {
    return creators?.map((creator, index) => {
      return (
        <Link
          to={`/user/${creator?.username ?? creator?.id}`}
          className={styles.item}
          key={index}
        >
          <LazyLoadImage
            alt={`${creator.id}`}
            effect="blur"
            src={DirectusImage(creator?.avatar?.id, 60)}
          />
          <div className={styles.overlay} />
          <Card.Content className={styles.content}>
            {creator?.creator_type != "None" && (
              <div className={styles.creator_type}>
                <span>{creator?.creator_type}</span>
              </div>
            )}
            <Card.Header className={creator.disabled && styles.disabled}>
              <div className={styles.club_details}>
                <p className={styles.details}>
                  {creator?.display_name ?? creator?.first_name}
                  <p className={styles.content}>
                    {creator?.description &&
                      truncate(creator?.description, 100)}
                  </p>
                  <br />
                </p>
              </div>
            </Card.Header>
          </Card.Content>
        </Link>
      );
    });
  };

  return (
    <div className={styles.clubs}>
      <div className={styles.container}>
        <Grid columns="one" divided>
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.column}>
              <h3>Artists</h3>
              <div className={styles.heading}>
                <p className={styles.headerContent}>
                  <Trans>fanClubHeading</Trans>
                </p>
                <Input
                  classes={styles.search}
                  placeholder="Search Artists"
                  setValue={(text: string) => handleSearch(text)}
                  value={search}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className={styles.club}>
          {search ? (
            searchResults.length > 0 && CreatorList(searchResults)
          ) : (
            creators.length > 0 && CreatorList(creators)
          )}
        </div>
        {loading && <div className="loader"></div>}
      </div>
    </div>
  );
}
