import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./OnBoardCover.module.scss";
import getCroppedImg from "app/helpers/getCroppedImg";
import Cropper from "react-easy-crop";
import PulseLoader from "react-spinners/PulseLoader";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { red } from "@mui/material/colors";

export default function OnBoardCover({
  imageCover,
  setImageCover,
  setImageCoverFinal,
  imageCoverFinal,
  setImageCoverUpdate,
}: {
  imageCover?: any;
  setImageCover?: any;
  setImageCoverFinal?: any;
  imageCoverFinal?: any;
  setImageCoverUpdate?: any;
}) {
  const [coverPicture, setCoverPicture] = useState(null);
  const coverpopup = useRef(null);
  const refInput = useRef(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const cRef = useRef(null);
  const coverRef = useRef(null);
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setcroppedAreaPixels] = useState(null);

  useEffect(() => {
    setWidth(coverRef.current.clientWidth);
    setHeight(coverRef.current.clientHeight);
  }, [window.innerWidth]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setcroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImage = useCallback(
    async (show) => {
      try {
        const img = await getCroppedImg(imageCover, croppedAreaPixels);
        if (show) {
          setZoom(1);
          setCrop({ x: 0, y: 0 });
          setImageCover(img);
        } else {
          return img;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [croppedAreaPixels, coverPicture]
  );

  const updateCoverPicture = async () => {
    try {
      setLoading(true);
      let img = await getCroppedImage(false);

      if (img) {
        const imageUrl = URL.createObjectURL(img);
        cRef.current.style.backgroundImage = `url(${imageUrl})`;
        setImageCoverFinal(imageUrl);
        setImageCoverUpdate(img);
        setLoading(false);
        setImageCover("");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.error);
    }
  };

  return (
    <div className={styles.profile_cover} ref={coverRef}>
      {imageCover && (
        <div className={styles.save_changes_cover}>
          <div className={styles.save_changes_left}>
            <div
              className={styles.open_cover_update}
              onClick={() => {
                setCoverPicture("");
                setImageCover("");
              }}
            >
              <button className={styles.clear_btn}>
                <ClearIcon fontSize="small" sx={{ color: red[500] }} />
              </button>
            </div>
          </div>
          <div
            className={styles.open_cover_update}
            onClick={() => updateCoverPicture()}
          >
            <button className={styles.blue_btn}>
              {loading ? (
                <PulseLoader color="white" size={5} />
              ) : (
                <CheckIcon style={{color: "#fff"}} fontSize="small" />
              )}
            </button>
          </div>
        </div>
      )}
      {imageCover && (
        <div className="cover_crooper">
          <Cropper
            image={imageCover}
            crop={crop}
            zoom={zoom}
            aspect={(width ?? 1) / (height ?? 1)}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={true}
            objectFit="horizontal-cover"
          />
        </div>
      )}

      <div
        className={styles.edit_profile_cover}
        style={{
          backgroundImage: `url(${imageCoverFinal})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        ref={cRef}
      ></div>
    </div>
  );
}
