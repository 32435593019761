import { useEffect, useState } from "react";
import styles from "./feed.module.scss";

import * as api from "app/api/profile.api";
import { FeedObject } from "app/core/common";
import Feed from "app/components/feed/feed";
import { useHook } from "app/hooks/common";
import { CreateContent } from "app/components/createContent/createContent";
import Modal from "app/components/Modal";

interface FeedItem {
  id: string;
  type: "video" | "image";
  image: {
    id: string;
  };
}

interface FeedData {
  feed: FeedItem[];
  meta: any;
}

interface Props {
  username: string;
  containerRef: any;
  wall?: boolean;
  profileData?: any;
}

export default function ProfileFeed({
  username,
  containerRef,
  wall,
  profileData,
}: Props) {
  const { userInfo, checkingUserInfo } = useHook();
  const [profileFeed, setProfileFeed] = useState<FeedObject[]>([]);
  const [feedMeta, setFeedMeta] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [moreExists, setMoreExists] = useState(true);
  const [loading, setLoading] = useState(true);
  const [wall_user, setWallUser] = useState(null);

  // First Post?
  const [firstPost, setFirstPost] = useState(true);
  const showfp = localStorage.getItem("showfp");

  const handleFirstPost = () => {
    setFirstPost(false);
    localStorage.setItem("showfp", "false");
  };

  // Check if user is on their own profile
  const isUserProfile =
    userInfo?.username === username || userInfo?.id === username;

  /* ===================== */
  /*  Fetch Feed */
  /* ===================== */
  useEffect(() => {
    const fetchProfileFeed = async () => {
      if (wall === true) {
        setWallUser(profileData?.id);
      } else setWallUser(null);

      try {
        await api
          .GetProfileFeed({
            id: username,
            page: page,
            userInfo: userInfo,
            wall: wall,
          })
          .then((data) => {
            setProfileFeed((prevFeed) => [...prevFeed, ...data.feed]); //
            setFeedMeta(data.meta);
          });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (username && page && loading && !checkingUserInfo) {
      fetchProfileFeed();
    }
  }, [username, userInfo, checkingUserInfo, page]);

  /* ===================== */
  /*  Fetch More Feed */
  /* ===================== */
  const fetchMoreFeed = () => {
    const totalPages =
      profileFeed.length > 0
        ? Math.ceil(feedMeta.filter_count / feedMeta.limit)
        : undefined;
    if (totalPages != undefined && page !== totalPages && moreExists) {
      setLoading(true);
      setPage(page + 1); // Increment page number
      setMoreExists(page + 1 <= totalPages); // Check if more pages exist
    }
  };

  return (
    <div className={styles.mainFeed}>
      {(isUserProfile || wall === true) && (
        <CreateContent
          feedRef={profileFeed}
          updateFeedRef={setProfileFeed}
          wall_user={wall_user}
        />
      )}
      <Feed
        feed={profileFeed}
        feedLoading={loading}
        lastUpdated={lastUpdated}
        moreExists={moreExists}
        fetchMoreFeed={fetchMoreFeed}
        setFeed={setProfileFeed}
        containerRef={containerRef}
        isProfile={true}
        isUserProfile={isUserProfile}
        wall={wall}
      />

      <Modal
        isOpen={
          isUserProfile &&
          profileFeed.length === 0 &&
          !loading &&
          showfp !== "false"
        }
        onClose={() => handleFirstPost()}
        title={"Create your first post!"}
        subTitle={
          "Introduce yourself to our community with a short introduction and a photo or video of yourself"
        }
        customSubClass="defaultModal md_w_modal"
        imageHeader={true}
        image={
          "https://loop-markets.directus.app/assets/f8774467-4288-4f7e-89ce-b19d8fb0e40d"
        }
      >
        <CreateContent
        open={true}
        close={() => handleFirstPost()}
        feedRef={profileFeed}
        updateFeedRef={setProfileFeed}
        />
      </Modal>
    </div>
  );
}
