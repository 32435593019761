import { FC, ReactNode, useRef } from "react";
import styles from "./Modal.module.scss";
import useOutsideAlerter from "app/hooks/useOutsideAlerter";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subTitle?: string;
  zIndex?: number;
  center?: boolean;
  webApp?: boolean;
  customClass?: string;
  customSubClass?: string;
  sm?: boolean;
  login?: boolean;
  children?: ReactNode;
  modalContentClass?: string;
  blur?: boolean;
  imageHeader?: boolean;
  image?: string;
}

const Modal: FC<Props> = ({
  isOpen,
  onClose,
  title = "Select a Token",
  subTitle,
  sm = false,
  login = false,
  children,
  zIndex,
  center,
  webApp,
  customClass,
  customSubClass,
  modalContentClass,
  blur,
  imageHeader,
  image
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClose);
  return !isOpen ? null : (
    <div
      className={`${customClass} ${styles.modal} ${
        isOpen ? styles.open_modal : styles.close_modal
      }`}
      style={{
        ...(blur ? { backdropFilter: "blur(20px)" } : {}),
        ...(zIndex ? { zIndex } : {}),
        ...(center ? { display: "flex", padding: "0px" } : {}),
      }}
    >
      <div
        className={`${
          modalContentClass ? modalContentClass : styles.modalContent
        } ${customSubClass} ${login ? styles.modal_login : {}}`}
        ref={wrapperRef}
        style={{
          ...(webApp
            ? { width: "100%", height: "100%", paddingTop: "25vh" }
            : {}),
        }}
      >
        {!sm && (
          <div
            className={imageHeader ? styles.modalImageHeader : styles.modalHeader}
            style={imageHeader ? {backgroundImage: `url(${image})`} : {}}
          >
            <h1>{title}</h1>
            <p>{subTitle}</p>
            <span hidden={webApp} className={styles.close} onClick={onClose}>
              &times;
            </span>
          </div>
        )}
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
