import React, { useEffect, useRef, useState } from "react";
import styles from "./AboutMe.module.scss";
import Text from "./AboutMeText.json";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useHook } from "app/hooks/common";

export default function AboutMeText({ isUserProfile, profileData, handleTab }) {
  const { cookie } = useHook();
  const [isEditing, setIsEditing] = useState(false);
  const [aboutText, setAboutText] = useState(profileData.about || "");
  const [max, setMax] = useState(500 - aboutText.length);
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      // Set initial height to fit content
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [isEditing]);

  // open & close text field
  const handleEditClick = async () => {
    setIsEditing((prev) => !prev);
  };

  const handleSave = async () => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}user/action/update_field/about`,
      data: {
        cookie: cookie,
        about: aboutText,
      },
    });
    setIsEditing((prev) => !prev);
  };

  // receibe the changes from the text field
  const handleChange = (e) => {
    // set aboutText with the text field
    const newText = e.target.value;
    setAboutText(newText);
    // Automatically adjust height of the text field as the user types
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
    setMax(500 - newText.length);
  };

  return (
    <div className={styles.about_me}>
      <span>
        About Me :
        {/*if aboutText have data, show the edit button*/}
        {/* {isUserProfile && aboutText.length > 0 ? (
          <div
            className={styles.music_button}
            style={{ fontWeight: 400, padding: "2px 10px" }}
            onClick={handleEditClick}
          >
            {isEditing ? (
              <CloseIcon fontSize="small" />
            ) : (
              <EditIcon fontSize="small" />
            )}
          </div>
        ) : null} */}
      </span>
      {/*if aboutText doesnt have data, and the edit textfield is not open, then show the button to edit the text field*/}
      {isUserProfile && !aboutText && !isEditing && (
        <button
          onClick={handleEditClick}
          className={styles.music_button}
          style={{ width: "130px" }}
        >
          Add About me
        </button>
      )}
      {/*if isediting true, open the option to edit the textfield if not set the text as it is*/}
      {isEditing ? (
        <div className={styles.text_area}>
          <textarea
            ref={textAreaRef}
            className={styles.text_area_modal}
            value={aboutText}
            onChange={handleChange}
            maxLength={500}
          />
          <div className={styles.text_area_options}>
            <div className={styles.characters_counter}>
              {max} characters remaining
            </div>
            <button className={styles.music_button} onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      ) : isUserProfile ? (
        <p onClick={handleEditClick}>{aboutText}</p>
      ) : (
        <p>{aboutText}</p>
      )}
    </div>
  );
}
