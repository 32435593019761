import { handleFollow } from "app/api/actions.api";
import { checkIsFollowing } from "app/api/common.api";
import { useHook } from "app/hooks/common";
import { useEffect, useState } from "react";

interface Props {
  follower_id: string;
}

interface FollowState {
  isFollowing: boolean;
  isFollowingLoading: boolean;
}

interface FollowActions {
  toggleFollow: () => void;
}

type FollowHook = FollowState & FollowActions;

export const useFollow = ({ follower_id }: Props): FollowHook => {
  const { cookie, userInfo, followingList, updateFollowingList } = useHook();
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [isFollowingLoading, setIsFollowingLoading] = useState<boolean>(true);

  // check if following
  useEffect(() => {
    const runFollowCheck = async () => {
      const res = await checkIsFollowing({ cookie, userInfo, follower_id });
      setIsFollowing(res.isFollowing);
      setIsFollowingLoading(false);
    };

    // wait for cookie and userInfo to be set
    if (cookie && userInfo && follower_id) {
      runFollowCheck();
    }
  }, [cookie, userInfo, follower_id]);

  const toggleFollow = async () => {
    try {
      // optimistic update
      setIsFollowing(!isFollowing);

      const res = await handleFollow({ cookie, userInfo, follower_id });

      // Check if successful
      if (res.success) {
        // update following list
        updateFollowingList([...followingList, { follower_id: { follower_id }}]);
      } else {
        // remove from following list
        const revertedList = followingList.filter((item) => item.follower_id.id !== follower_id);
        updateFollowingList([...revertedList]);
      }
    } catch (error) {
      // revert optimistic update
      console.error(error);
      setIsFollowing(!isFollowing);
      // remove from following list
      const revertedList = followingList.filter((item) => item.follower_id.id !== follower_id);
      updateFollowingList([...revertedList]);
    }
  };

  return {
    isFollowing,
    isFollowingLoading,
    toggleFollow,
  };
};
