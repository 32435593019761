import React from "react";
import styles from "./styles.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { truncate } from "app/helpers/helper";
import { useHistory } from "react-router-dom";
import { useMediaPlayer } from "app/context/mediaPlayerContext";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import QueueIcon from "@mui/icons-material/Queue";
import PersonIcon from "@mui/icons-material/Person";

export default function AlbumMenu({
  album,
  handleMenuToggle,
  handlePlayPauseAlbum,
  handleSetAlbumId,
  handleAddToQueueAlbum,
}: any) {
  const history = useHistory();
  const { actualTrack, addTrackToQueue } = useMediaPlayer();

  const handleOpenArtistProfile = () => {
    history.push(`/user/${album?.album?.artist.username}`);
  };

  return (
    <div className={styles.club_details}>
      <div className={styles.details}>
        <div className={styles.details__top}>
          <div>
            <p>{truncate(album?.album?.name, 30, "...")}</p>
            <span>
              {truncate(
                album?.album?.artist?.display_name ??
                  album?.album?.artist?.username,
                30,
                "..."
              )}
            </span>
          </div>
          <div
            className={styles.close}
            onClick={() => handleMenuToggle(album?.album?.id)}
          >
            <CloseIcon />
          </div>
        </div>

        <div
          className={styles.content}
          onClick={() => {
            handlePlayPauseAlbum(album);
            handleSetAlbumId(album?.album?.id);
          }}
        >
          <PlayArrowIcon /> Play Album
        </div>

        <div
          className={styles.content}
          onClick={() => {
            if (album && !actualTrack) {
              handleAddToQueueAlbum?.(album); // Add to queue and play
              handlePlayPauseAlbum?.(album);
            } else if (album && actualTrack) {
              handleSetAlbumId(album?.album?.id);
              handleAddToQueueAlbum?.(album); // Just add to queue if already playing a track
            }
          }}
        >
          <QueueIcon /> Add Album to Queue
        </div>

        <div className={styles.content} onClick={handleOpenArtistProfile}>
          <PersonIcon /> See Artist
        </div>
      </div>
    </div>
  );
}
