import React, { useEffect } from "react";
import styles from "./StepThree.module.scss";
import InputProfile from "app/components/Profile/input_profile/InputProfile";
import PreviewContainer from "app/components/preview_container/PreviewContainer";
import { useHook } from "app/hooks/common";

export default function StepThree({
  imageProfile,
  imageCoverFinal,
  handleChange,
  infos,
  profileData,
  max,
  setReady,
  handleChangeDescription,
}) {
  // if the descrition its set, update it and allow continue
  useEffect(() => {
    if (infos.description) {
      setReady(true);
    } else if (profileData.description) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [infos.description, profileData.description]);

  return (
    <div className={styles.step_four}>
      <div className={styles.step_four_top}>
        <h1>Add a short Bio</h1>
        <hr />
        <div className={styles.step_four_input}>
          {/* <span>Add a short Bio</span> */}
          <div className={styles.step_four_input_input}>
            <InputProfile
              placeholder={"Add a short Bio"}
              infos={infos}
              name="description"
              handleChange={handleChange}
              onboard
              max={max}
            />
          </div>
        </div>
      </div>
      <PreviewContainer
        infos={infos}
        imageProfile={imageProfile}
        imageCoverFinal={imageCoverFinal}
        showDetails={true}
      />
    </div>
  );
}
