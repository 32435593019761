import { EditorContent, useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import { useEffect, useState } from "react";

/* ======================== */
/* ==== Handle Editor ===== */
/* ======================== */

export default function TextArea({
  textEdited,
  setPostContent,
  postContent,
  setTextEdited,
  edit,
  disabled,
}: {
  edit?: boolean;
  textEdited?: string;
  setPostContent?: any;
  postContent?: string;
  setTextEdited?: any;
  disabled?: boolean;
}) {
  useEffect(() => {
    if (edit) {
      editor?.commands.setContent(textEdited);
    } else {
      editor?.commands.setContent(postContent);
    }

    return () => {
      editor?.commands.setContent("");
    };
  }, [edit]);

  const editor = useEditor({
    editable: !disabled,
    extensions: [
      StarterKit,
      Typography,
      Highlight,

      Placeholder.configure({
        // Use a placeholder:
        placeholder: "Write something …",
      }),
    ],
    content: edit ? textEdited : postContent, // Set the initial content of the editor

    onUpdate: ({ editor }) => {
      // Update the state with the editor's current content
      edit ? setTextEdited(editor.getHTML()) : setPostContent(editor.getHTML());
    },
  });

  return <EditorContent editor={editor} className="taptap_disabled" />;
}
