import React from "react";
import styles from "./styles.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper.scss";
import Collections from "./index";
import SwiperCore, { Navigation, Pagination } from "swiper";

export default function CollectionSlider() {
  SwiperCore.use([Navigation, Pagination]);
  const newCollections = [
    {
      image:
        "https://loop-markets.directus.app/assets/ab47fa0b-b775-41de-8fc0-0f2e10dcd702",
      name: "IA 2024",
      description: "A taste of Iceland Airwaves 2024",
      link: "/collection/3287",
    },
    {
      image:
        "https://loop-markets.directus.app/assets/7e9e5f28-6628-4ca8-8b38-f6c28c6cf025",
      name: "Alan Scarlato",
      description: "After the success of We Fight, Alan Scarlato returns with Kangaroo, a track that presents a fresh sound",
      link: "/collection/3261",
    },
    {
      image:
        "https://loop-markets.directus.app/assets/f5fa8819-a4e7-4d0d-88d7-deb267a9b595",
      name: "Devitchi",
      description: "III-Blending traditional African tribal rhythms and drums with modern electronic dance music",
      link: "/collection/3253",
    },
    {
      image:
        "https://loop-markets.directus.app/assets/e4538d9e-aa09-4d1d-a99a-8d724ba945bc",
      name: "Big Groove",
      description: "Funky House Volume 3 is an eclectic collection of Biggroove Music remixes and original tracks, many previously unreleased",
      link: "/collection/3297",
    },
  ];
  return (
    <div className={styles.wrapper} style={{ marginTop: "20px" }}>
      <Swiper
        slidesPerView={3}
        spaceBetween={10}
        navigation={true}
        loop={true}
        autoplay={true}
        className="video__swiper"
        breakpoints={{
          300: {
            slidesPerView: 1,
          },
          570: {
            slidesPerView: 2,
          },
          1100: {
            slidesPerView: 3.2,
          },
          1520: {
            slidesPerView: 3.2,
          },
        }}
      >
        {newCollections?.map((collection, index) => (
          <SwiperSlide key={index}>
            <Collections collection={collection} index={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
