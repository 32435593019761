import React, { useRef } from "react";
import styles from "./OnBoardNft.module.scss";

type OnBoardNftProps = {
  nftBackGround?: string;
  imageTop?: string;
  imageProfile?: string;
  infos?: {
    display_name: string;
  };
  imageBottomLeft?: string;
  imageBottomRigth?: string;
  normal?: boolean;
  elementRef?: any;
  price?: number;
};

export default function OnBoardNft({
  nftBackGround,
  imageTop,
  imageProfile,
  infos,
  imageBottomLeft,
  imageBottomRigth,
  normal,
  elementRef,
  price,
}: OnBoardNftProps) {
  return (
    <>
      {normal ? (
        <div
          ref={elementRef}
          className={styles.step_five_right_content}
          style={{
            backgroundImage: `url(${nftBackGround})`,
            backgroundSize: "cover",
            borderRadius: "0px",
          }}
        >
          <div className={styles.step_five_preview}>
            <div className={styles.step_five_loop_logo}>
              <img src={imageTop} alt="" />
            </div>
            <div className={styles.avatar_container}>
              <div className={styles.step_five_avatar}>
                <img src={imageProfile} alt="" />
              </div>
            </div>

            <div className={styles.step_five_display_name}>
              <span>
                {!infos?.display_name ? "display name" : infos?.display_name}
              </span>
            </div>
            <div className={styles.preview_bottom}>
              <div className={styles.preview_bottom_left}>
                <img src={imageBottomLeft} alt="" />
              </div>
              <div className={styles.preview_bottom_rigth}>
                <img src={imageBottomRigth} alt="" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          ref={elementRef}
          className={styles.step_five_right_content_support}
          style={{
            backgroundImage: `url(${nftBackGround})`,
            backgroundSize: "cover",
            borderRadius: "0px",
          }}
        >
          <div className={styles.step_five_preview_support}>
            <div className={styles.avatar_container_support}>
              <div className={styles.step_five_avatar_support}>
                <img src={imageProfile} alt="" />
              </div>
            </div>

            <div className={styles.step_five_display_name_support}>
              <span>
                {!infos?.display_name ? "display name" : infos?.display_name}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
