import React from "react";
import styles from "./ConversationItem.module.scss";
import { DirectusImage } from "app/core/common";
import { useHook } from "app/hooks/common";

type ConversationProp = {
  convo: {
    id: string;
    receiver: {
      id: string;
      avatar: string;
      display_name: string;
      first_name: string;
    };
    sender: {
      id: string;
      avatar: string;
      display_name: string;
      first_name: string;
    };
    title: string;
  };
  handleSelectConversation?: (convo: any) => void;
  selectedConversation: string;
};

export default function ConversationItem({
  convo,
  handleSelectConversation,
  selectedConversation,
}: ConversationProp) {
  const { userInfo } = useHook();
  const me = userInfo?.id;
  const receiver = convo?.receiver?.id;
  const sender = convo?.sender?.id;
  /* ======================== */
  /* show the picture of the other user */
  /* ======================= */
  const showpicture = me === receiver ? sender : receiver;
  /* ======================== */
  /* id of the conversation selected to show the active */
  /* ======================= */
  const id = selectedConversation;

  return (
    <div
      className={
        id === convo?.id
          ? styles.conversation_container_selected
          : styles.conversation_container
      }
      onClick={() => handleSelectConversation(convo.id)}
    >
      <div className={styles.conversation_infos}>
        <div className={styles.sender_image}>
        <img
            src={DirectusImage(convo?.sender?.avatar)}
            alt="loop_conversation"
          />
          <img
            src={DirectusImage(convo?.receiver?.avatar)}
            alt="loop_conversation"
          />
        </div>
        <div className={styles.sender_info}>
          <span>
            {showpicture === sender
              ? convo?.sender?.display_name ?? convo?.sender?.first_name
              : convo?.receiver?.display_name ?? convo?.receiver?.first_name}
          </span>
          <span>{convo?.title}</span>
        </div>
      </div>
    </div>
  );
}
