import React, { useEffect, useRef, useState } from "react";
import styles from "./StepFive.module.scss";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { blue } from "@mui/material/colors";
import images from "./images.json";
import { toSvg } from "html-to-image";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import axios from "axios";
import OnBoardNft from "app/components/onboard/onboard_nft_image/OnBoardNft";

export default function StepFive({
  infos,
  imageProfile,
  nftBackGround,
  setNftBackGround,
  setNftImage,
  setGenre,
  setDivision,
  division,
  genre,
  setReady,
  handleVoting,
  setSongFile,
  songFile,
}) {
  const refCover = useRef(null);
  const refSong = useRef(null);
  const elementRef = useRef(null); // Reference to the content div
  const [error, setError] = useState(null);
  const [converting, setConverting] = useState(false);
  const [genres, setGenres] = useState(null);
  const [divisions, setDivisions] = useState(null);
  const imageBottomRigth =
    "https://loop-markets.directus.app/assets/ce36c33e-2a96-4543-9a95-ff791dc70a21";
  const imageBottomLeft =
    "https://loop-markets.directus.app/assets/9fd7b350-b91a-48ff-b493-659351368603";
  const imageTop =
    "https://loop-markets.directus.app/assets/27e236b8-0989-42a1-90b8-774f33839f54";

  const handleNftBackGround = (nftImage) => {
    setNftBackGround(nftImage);
  };

  //
  const handleMusicFile = (e) => {
    let file = e.target.files[0];
    if (file.type !== "audio/mp3" && file.type !== "audio/mpeg") {
      console.log(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 25) {
      console.log("The size of the file is to large");
      return;
    }

    setSongFile(file);
  };

  // when selected the image from the input, set the background of the preview with it
  const handleNFtImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp" &&
      file.type !== "image/gif"
    ) {
      setError(`The format of the file is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError("The size of the file is to large");
      return;
    }
    e.target.value = null;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setNftBackGround(event.target.result);
    };
  };

  // convert base 64 to svg
  const convertDataUrlToFile = async (dataUrl, fileName) => {
    try {
      const response = await fetch(dataUrl);
      const blob = await response.blob();

      // Create a file from the Blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      console.error(error);
      throw new Error("Failed to convert data URL to file.");
    }
  };
  // convert the preview to base 64
  const htmlToImageConvert = async () => {
    try {
      const dataUrl = await toSvg(elementRef.current, {
        cacheBust: false,
        style: styles,
      });
      const fileName = "nft.svg"; // Provide a suitable file name
      const svgFile = await convertDataUrlToFile(dataUrl, fileName);
      setNftImage(svgFile);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    if (name === "genre") {
      setGenre(value);
    } else {
      setDivision(value);
    }
  };
  // when genre, division, and preview are ready set the nftImage
  useEffect(() => {
    console.log({ genre, division, nftBackGround, songFile });
    if (
      nftBackGround &&
      division &&
      genre &&
      songFile &&
      genre !== "0" &&
      division !== "0"
    ) {
      setReady(true);
      htmlToImageConvert();
    } else {
      setReady(false);
    }
  }, [nftBackGround, division, genre, songFile]);
  // set the genres and divisions to the selects
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/divisions`,
        });
        const responseGeners = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/genres`,
        });

        setDivisions(response?.data?.divisions);
        setGenres(responseGeners?.data?.genres);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.step_five_all}>
      <div className={styles.step_five_category}>
        <h1>Voting Category</h1>
        <div className={styles.categories_select}>
          <div className={styles.select1}>
            <div className={styles.upload_music_button}>
              {/* <h1>Upload a song</h1> */}
              <span>
                Which song would you like to enter into the awards?{" "}
                <small> (Max: 25MB MP3)</small>
              </span>
              <div
                className={styles.image_icon}
                onClick={() => {
                  refSong.current.click();
                }}
              >
                <LibraryMusicIcon sx={{ color: blue[700] }} />
                <input
                  type="file"
                  ref={refSong}
                  hidden
                  onChange={handleMusicFile}
                  accept="audio/mp3"
                />
                <span className={styles.input_song_title}>
                  {songFile ? songFile.name : "No file selected"}
                  <small> (Max: 25MB MP3)</small>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.select2}>
            <>
              <label htmlFor="genre">
                Which genre would you like to compete in?
              </label>
              <select
                name="genre"
                id="genre"
                required
                onChange={handleRegisterChange}
                defaultValue={genre ? genre : "Select Genre"}
                style={{ marginBottom: "10px" }}
              >
                {genres &&
                  genres?.map((gen) => (
                    <option key={gen.id} value={gen.id}>
                      {gen.name}
                    </option>
                  ))}
              </select>
            </>

            <>
              <label htmlFor="division">Which division are you in?</label>
              <select
                name="division"
                id="division"
                required
                onChange={handleRegisterChange}
                defaultValue={division ? division : "Select division"}
              >
                {divisions &&
                  divisions?.map((option) => (
                    <option key={option.id} value={option.id}>
                      <div>
                        {option.description
                          ? `${option.name} - (${option.description})`
                          : option.name}
                      </div>
                    </option>
                  ))}
              </select>
            </>
          </div>
        </div>
        <hr />
      </div>
      <div className={styles.step_five}>
        <div className={styles.step_five_left}>
          <h1>Create Vote NFT</h1>
          <div>When your fans vote for you they will receive a free NFT</div>

          {/* <div className={styles.upload_music_button}>
            <h1>Upload a song</h1>
            <span>Which song would you like to enter into the awards?</span>
            <div
              className={styles.image_icon}
              onClick={() => {
                refSong.current.click();
              }}
            >
              <LibraryMusicIcon sx={{ color: blue[700] }} />
              <input
                type="file"
                ref={refSong}
                hidden
                onChange={handleMusicFile}
                accept="audio/mp3"
              />
              <span className={styles.input_song_title}>
                {songFile ? songFile.name : "No file selected"}
              </span>
            </div>
          </div> */}
          <div className={styles.border}></div>
          <div className={styles.step_five_left_content}>
            <div className={styles.step_five_left_content_title}>
              <h1 className={styles.step_five_left_content_title_span}>
                NFT Artwork
              </h1>
              <span>Select from a template</span>
            </div>
            <div className={styles.step_five_image_sample}>
              <div
                className={styles.step_five_image_sample_square}
                onClick={() => handleNftBackGround(images[0]?.url)}
              >
                <img
                  src="https://loop-markets.directus.app/assets/315d3685-d60c-415f-841f-77b97fde6593"
                  alt=""
                  className={styles.step_five_image_sample_square_image}
                />
              </div>
              <div className={styles.step_five_image_sample_three}>
                {images.slice(1).map((image, index) => (
                  <div
                    className={styles.other_images}
                    key={image.id}
                    onClick={() => handleNftBackGround(image.url)}
                  >
                    <img src={image.url} alt="" />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.upload_option}>
              <span>Or upload your own</span>
              <div
                className={styles.image_icon}
                onClick={() => {
                  refCover.current.click();
                }}
              >
                <AddPhotoAlternateIcon sx={{ color: blue[700] }} />
                <input
                  type="file"
                  ref={refCover}
                  hidden
                  onChange={(e) => handleNFtImage(e)}
                  accept="image/jpeg,image/png,image/webp,image/jfif,image/gif"
                />
                <span>796 x 1058px</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.step_five_right}>
          <h1>Preview</h1>
          <OnBoardNft
            elementRef={elementRef}
            nftBackGround={nftBackGround}
            imageTop={imageTop}
            imageProfile={imageProfile}
            infos={infos}
            imageBottomLeft={imageBottomLeft}
            imageBottomRigth={imageBottomRigth}
            normal
          />
          <span>
            This NFT image will be locked in and can't be changed once you go to
            the next step.
          </span>
        </div>
      </div>
    </div>
  );
}
