import React, { useState } from "react";
import styles from "./NftDrops.module.scss";
import { CourseUrl, DirectusImage } from "app/core/common";
import { truncate } from "app/helpers/helper";
import { Link } from "react-router-dom";

export default function NftDrops({ collection }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Link
      to={`/collection/${CourseUrl(collection?.id)}`}
      className={styles.creators_container}
    >
      <div
        className={`${styles.drops_container} ${
          !imageLoaded && styles.loading
        } ${imageError && styles.error}`}
        style={{
          backgroundImage: `url(${DirectusImage(collection?.banner?.id)})`,
        }}
      ></div>
      <img
        className={imageLoaded ? styles.nft_image : styles.nft_image_loading}
        src={DirectusImage(collection?.banner?.id)}
        alt={`${collection?.id}`}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
      <div className={styles.overlay} />
      <div className={styles.club_details}>
        <div className={styles.details}>
          <p> {collection?.project_name}</p>
          <div className={styles.content}>
            {collection?.launchpad_type?.fan_collection?.description}
          </div>
        </div>
      </div>
    </Link>
  );
}
